import { AnioEntity, PeriodoEntity } from '../../types/ranking/anioTypes';

export const LOAD_RANKING_OPTIONS = "LOAD_RANKING_OPTIONS";
export const LOAD_OFFICES = "LOAD_OFFICES";
export const LOAD_RANKING_RESPONSE = "LOAD_RANKING_RESPONSE";
export const SET_CONSULTAR_BASES_MODAL = "SET_CONSULTAR_BASES_MODAL";

export type StateRankingSelect = {
    ValorAnio: number,
    SelectAnios: AnioEntity[],
    TipoCarga: number,
    IdGrupo: number,
    FechaInicio: string,
    FechaFin: string,
    SelectPeriodo: PeriodoEntity[]
};

export type IOfficeItem = {
    numPC: string;
    posicionRanking: number
};

export type StateConsultarBasesModal = {
   Visible: boolean,
   Titulo: string,
   ContenidoBases: string
};

export type StateRanking = {
    rankingselect: StateRankingSelect,
    officeItemsA: IOfficeItem[],
    officeItemsB: IOfficeItem[],
    officeItemsC: IOfficeItem[],
    isLoading: boolean,
    errormessage: string,
    consultabasesmodal: StateConsultarBasesModal
};

export type UrlParams = {
    anio: string,
    tipo: string,
    periodo: string
 };