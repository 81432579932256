import { LOAD_DATOCARGA_DETALLE, LOAD_DATOCARGA_TABLE, LOAD_DATOCARGA_ACTIONS, StateDatoCargaDetalle, StateDatoCargaTable } from "../../types/ranking/datoCargaTypes";

export const datoCargaTableInitialState: StateDatoCargaTable = {
    content: [],
};

export const datoCargaDetalleInitialState: StateDatoCargaDetalle = {
    idGrupoCargaSelected: 0,
    idFicheroCarga: 0,
    observaciones: "",
    evaluacion: false,
    valorAnio: 0,
    fechaInicio: "",
    fechaFin: "",
    isActionable: true,
    isLoading: false,
    displayModal: false,
    grupoCargaList: [],
    table: datoCargaTableInitialState
};

export function datoCargaReducer(state = datoCargaDetalleInitialState, action: any): StateDatoCargaDetalle {

    switch (action.type) {

        case LOAD_DATOCARGA_DETALLE: {

            return {
                ...state,
                idFicheroCarga: action.payload.idFicheroCarga,
                idGrupoCargaSelected: action.payload.idGrupoCargaSelected,
                observaciones: action.payload.observaciones,
                evaluacion: action.payload.evaluacion,
                valorAnio: action.payload.valorAnio,
                fechaInicio: action.payload.fechaInicio,
                fechaFin: action.payload.fechaFin,
                isActionable: action.payload.isActionable,
                isLoading: action.payload.isLoading,
                displayModal: action.payload.displayModal,
            } as StateDatoCargaDetalle;
        }

        case LOAD_DATOCARGA_TABLE:{

            return{
                ...state,
                table: {
                    content: action.payload
                }
            }
        }

        case LOAD_DATOCARGA_ACTIONS:{

            return{
                ...state,
                grupoCargaList: action.payload
            }
        }

        default:
            return state;
    }
}