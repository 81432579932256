import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ROL_RANK_CARGA, ROL_RANK_SUPERVISOR, ROL_RANK_VISUALIZADOR } from '../types/users/rolesTypes';
import { UserType } from '../types/users/userTypes';

const LoginRedirect = ({ user, mode } : { user: UserType, mode: string }) => {

    const history = useNavigate();

    const navigateRanking = (userData : any) =>{

        if (userData.roles.includes(ROL_RANK_VISUALIZADOR)) {

            history('/ranking/visualizacion/Ranking');

        } else if (userData.roles.includes(ROL_RANK_SUPERVISOR)) {

            history('/ranking/supervision/Listado');

        } else if (userData.roles.includes(ROL_RANK_CARGA)) {

            history('/ranking/carga/Listado');
            
        } else {

            history('/Error/1');
        }
    }

    const navigateObjetivos = (userData : any) =>{

        if (userData.roles.includes(ROL_RANK_VISUALIZADOR)) {

            history(`/objetivos/visualizacion/Detalle/${user.numPC}`);

        } else if (userData.roles.includes(ROL_RANK_SUPERVISOR)) {

            history('/objetivos/supervision/Listado');

        } else if (userData.roles.includes(ROL_RANK_CARGA)) {

            history('/objetivos/carga/Listado');
            
        } else {

            history('/Error/1');
        }
    }

    const navigateCheck = (userData : any) => {
       
        switch (mode) {
            case "rank": {
                return navigateRanking(userData);
            }
            case "obj": {
                return navigateObjetivos(userData);
            }
            default:
                return history('/');
        }
    };

    // useEffect

    useEffect(() => {

        navigateCheck(user);

    }, []);
    
    // Render

    return (<></>);
};

export default LoginRedirect;