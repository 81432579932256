import { AnioEntity } from "./anioTypes";

export const LOAD_LISTADO_OFICINAS = "LOAD_LISTADO_OFICINAS";
export const LOAD_MODAL_DETALLE_OFICINA = "LOAD_MODAL_DETALLE_OFICINA";
export const LOAD_DETALLE_OFICINA = "LOAD_DETALLE_OFICINA";

export type OficinaModel = {
    grupo: string,
    numPC: string,
    nombre: string,
    nombreAgrupado: string,
    objetivos: ObjetivoModel[][]
}

export type ObjetivoModel = {
    idDatoCarga: number,
    estructura: EstructuraModel,
    tipoObjetivo: string,
    valor: string
}

export type EstructuraModel = {
    id: number,
    ficheroCargaId: number,
    tipo: string,
    numero: number,
    descripcion: string
};

export type StateListadoOficina = {
    anioValues: AnioEntity[],
    anioIdSelected: number,
    anioObservacionesSelected: string,
    tableData: OficinaModel[],
    modal: DetalleOficinaModal,
    isLoading: boolean
};

export type DetalleOficinaModal = {
    display: boolean,
    title: string | undefined,
    oficina: OficinaModel | undefined,
    editable: boolean
    editedValues: DatoCargaDTO[]
};

export type DatoCargaDTO = {
    id: number,
    grupo: string,
    numPC: string,
    estructuraId: number,
    tipoObjetivo: string,
    valor: string
}

export type StateDetalleOficina = {
    anioValues: AnioEntity[],
    anioIdSelected: number,
    anioObservacionesSelected: string,
    oficina: OficinaModel | undefined,
    isLoading: boolean
};

export type ObjetivoOficinaJson = {
    grupo: string,
    oficina: string,
    tipo_estructura: string,
    numero_estructura: number,
    tipo_objetivo: string,
    valor: string
};

export const requiredHeadersObjetivoOficina: Array<string> = ['grupo', 'oficina', 'tipo_estructura', 'numero_estructura', 'tipo_objetivo', 'valor'];