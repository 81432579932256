import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store';
import { Modal, Button } from 'react-bootstrap';
import { loadFicheroCargaListado, loadFicheroCargaTable, setFicheroCargaModalContent } from '../../../../actions/ranking/cargaActions';
import { setActionResponse } from '../../../../actions/actionResponseActions';
import { updateEstadoFicheroCarga, getFicheroCargaByValorAnio } from '../../../../services/ranking/cargaService';
import { FicheroCargaDTO, FicheroCargaTableModel } from '../../../../types/ranking/ficheroCargaTypes';

export default function FicheroCargaActionProcesarModal() {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state);
    const { anioSelected, anualValues, displayProcesarModal, modalsContent } = state.ranking.ficheroCarga;

    // Methods

    const handleAceptarOnClick = async (event: any) => {

        dispatch(loadFicheroCargaListado({
            ...state.ranking.ficheroCarga,
            displayProcesarModal: false,
            isLoading: true
        }));

        let estadoId: number = 0;

        if (modalsContent.content.isPublicable) {

            estadoId = 2

        } else if (modalsContent.content.isRechazado) {

            estadoId = 3

        } else {

            estadoId = 1
        }

        let request: any = {
            id: modalsContent.content.id,
            estadoId: estadoId
        }

        let response = await updateEstadoFicheroCarga(request);

        if (response !== null) {

            if (response === true) {

                displayResponseAlert(true, `Ranking ${modalsContent.content.isRechazado ? "rechazado" : "publicado"} correctamente`, true);
                await fetchTableData(anioSelected, anualValues);

            } else {

                displayResponseAlert(false, "Ha ocurrido un error al actualizar el estado", true);
            }

        } else {

            displayResponseAlert(false, "Ha ocurrido un error al intentar realizar la acción", true);
        }

        dispatch(loadFicheroCargaListado({
            ...state.ranking.ficheroCarga,
            displayProcesarModal: false
        }));

    };

    const fetchTableData = async (anio: number, anualValue: boolean) => {

        var responseFicheroCarga = await getFicheroCargaByValorAnio(anio, anualValue);
        var ficheroCargaTableModelList: FicheroCargaTableModel[] = []

        responseFicheroCarga.forEach(element => {
            ficheroCargaTableModelList.push(convertFicheroCargaEntityToTableModel(element, anio));
        });

        dispatch(loadFicheroCargaTable(ficheroCargaTableModelList));
    }

    const convertFicheroCargaEntityToTableModel = (entity: FicheroCargaDTO, anio: number) => {

        var model: FicheroCargaTableModel = {

            id: entity.id,
            fechaSubida: formatDate(new Date(entity.fechaCreacion), true),
            anio: entity.periodo.fechaFin !== null ? new Date(entity.periodo.fechaFin).getFullYear() : new Date(entity.periodo.fechaInicio).getFullYear(),
            mes: entity.periodo.fechaFin !== null ? (new Date(entity.periodo.fechaFin).getMonth() + 1).toString().padStart(2, '0') : (new Date(entity.periodo.fechaInicio).getMonth() + 1).toString().padStart(2, '0'),
            estado: entity.estado.nombre,
            valorAnio: anio,
            fechaInicio: formatDate(new Date(entity.periodo.fechaInicio), false),
            fechaFin: entity.periodo.fechaFin !== null ? formatDate(new Date(entity.periodo.fechaFin), false) : '',
            isPublicable: entity.isPublicable,
            isRechazado: entity.isRechazado
        }

        return model;
    }

    function formatDate(inputDate: Date, spainFormat: boolean) {

        let date, month, year;

        date = inputDate.getDate();
        month = inputDate.getMonth() + 1;
        year = inputDate.getFullYear();

        date = date.toString().padStart(2, '0');
        month = month.toString().padStart(2, '0');

        if (spainFormat) {
            return `${date}/${month}/${year}`;
        } else {
            return `${year}/${month}/${date}`;
        }
    }

    const handleModalHide = (event: any) => {

        dispatch(setFicheroCargaModalContent({
            id: 0,
            fechaSubida: "",
            anio: 0,
            mes: "",
            estado: "",
            valorAnio: 0,
            fechaInicio: "",
            fechaFin: "",
            isPublicable: false,
            isRechazado: false
        }));

        dispatch(loadFicheroCargaListado({
            ...state.ranking.ficheroCarga,
            displayProcesarModal: false
        }));
    };

    const displayResponseAlert = (isOk: boolean, message: string, isVisible: boolean) => {

        dispatch(setActionResponse("IsOk", isOk));
        dispatch(setActionResponse("ResponseMessage", message));
        dispatch(setActionResponse("IsVisible", isVisible));
    }

    return (
        <>
            <Modal show={displayProcesarModal} onHide={() => handleModalHide} centered>
                <Modal.Header>
                    <Modal.Title>{modalsContent.content.isRechazado ? "Rechazar" : "Publicar"} Ranking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>¿Está seguro que quiere <strong>{modalsContent.content.isRechazado ? "rechazar" : "publicar"}</strong> este ranking?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-soliss' onClick={handleAceptarOnClick}>Aceptar</Button>
                    <Button className='btn btn-soliss' onClick={handleModalHide}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
