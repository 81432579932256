import { UserType } from '../../types/users/userTypes';
import { ROL_RANK_SUPERVISOR, ROL_RANK_CARGA, ROL_RANK_VISUALIZADOR } from '../../types/users/rolesTypes';
import { Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NavBarRanking = ({ user }: { user: UserType }) => {
    return (
        <>
            <Nav className="me-auto">
                <a className="navbar-brand" href="#">Ranking</a>
                {
                    user.roles.includes(ROL_RANK_CARGA) ?
                        <Link to="/ranking/carga/Listado" className="nav-link" reloadDocument>Cargas</Link> : ""
                }
                {
                    user.roles.includes(ROL_RANK_SUPERVISOR) ?
                        <NavDropdown title="Supervisión" id="basic-nav-dropdownrech2">
                            <Link to={`/ranking/supervision/${"Nuevo"}`} className="nav-link"> Nuevo Ranking</Link>
                            <Link to={`/ranking/supervision/${"Editar"}`} className="nav-link"> Editar Rankings</Link>
                            <Link to="/ranking/supervision/Listado" className="nav-link" reloadDocument>Listado</Link>
                        </NavDropdown> : ""
                }
                {
                    user.roles.includes(ROL_RANK_VISUALIZADOR) ?
                        <Link to="/ranking/visualizacion/Ranking" className="nav-link" reloadDocument>Visualización</Link> : ""
                }
            </Nav>
        </>
    );
};

export default NavBarRanking;