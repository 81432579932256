import { Spinner, Col, Row } from 'react-bootstrap';

export const LoadingSpinner = () => {

    return (
        <div className="overlay">
            <Row className="align-items-center" style={{ height: "100%" }}>
                <Col md={2} className="text-center loadingCont">
                    <Row className="align-items-center">
                        <Col md={12} className="text-center">
                            <Spinner animation="border" style={{ color: "#dc006b", marginRight: "10px" }} />
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <span style={{ color: "white" }}><strong>Cargando...</strong></span>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default LoadingSpinner;