import { Card, Row, Col, Container } from 'react-bootstrap';
import { OficinaModel } from '../../../types/objetivos/oficinaTypes';
import DetalleOficina from '../oficina/DetalleOficina';


export default function ObjetivosOficinas({ anioObservacionesSelected, oficina }: { anioObservacionesSelected: string, oficina: OficinaModel | undefined }) {

    // Render

    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <Card.Body>
                            {<DetalleOficina oficina={oficina} editable={false} handleValorInputOnChange={null} />}
                            <h3>Observaciones</h3>
                            <hr />
                            <Container>
                                <span dangerouslySetInnerHTML={{ __html: anioObservacionesSelected }} />
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br />
        </>
    );
};
