import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { Modal, Button } from 'react-bootstrap';
import { setConsultarBasesModal } from '../../../actions/ranking/rankingActions';

export default function ConsultarBasesModal() {

    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const { consultabasesmodal } = state.ranking.podium;

    const handleModalHide = () => {
        dispatch(setConsultarBasesModal("Visible", false));
    }

    const handleModalShow = () => {
        dispatch(setConsultarBasesModal("Visible", true));
    }
    

    return (
        consultabasesmodal.ContenidoBases !== "" ? <>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-4">
                        <button onClick={() => handleModalShow()} className="btn btn-link"
                        style={{paddingLeft: 0}}>
                            Consultar Bases
                        </button>
                    </div>
                </div>
            </div>

            <Modal show={consultabasesmodal.Visible} onHide={() => handleModalHide} size="lg" centered>
                <Modal.Header closeButton onClick={handleModalHide}>
                    <Modal.Title>{consultabasesmodal.Titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <div style={{maxHeight: 400, overflowY: 'auto'}} 
                        dangerouslySetInnerHTML={{ __html: consultabasesmodal.ContenidoBases }}></div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-soliss' onClick={handleModalHide}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </> : <></>
    );

};