import { useDispatch } from 'react-redux';
import { UserType } from '../types/users/userTypes';
import { setActiveLayout } from '../actions/layoutmodeActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRankingStar, faChartLine } from '@fortawesome/free-solid-svg-icons';

const InitialSwitch = ({ user }: { user: UserType }) => {

    const dispatch = useDispatch();

    const setLayout = (mode: string) => {
        dispatch(setActiveLayout("LayoutActive", mode));
    };

    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <h1 className="display-4 text-center">Seleccione a dónde quiere ir...</h1>
                        <hr />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-4">
                        <div className="card cardMode">
                            <div className="card-body text-center" style={{ cursor: 'pointer' }}
                                onClick={() => setLayout("rank")}>
                                <FontAwesomeIcon icon={faRankingStar} size="10x" style={{ color: "#dc006b" }}/><br /><br />
                                <h5 className="card-title">Ranking</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card cardMode">
                            <div className="card-body text-center" style={{ cursor: 'pointer' }}
                                onClick={() => setLayout("obj")}>
                                <FontAwesomeIcon icon={faChartLine} size="10x" style={{ color: "#dc006b" }}/><br /><br />
                                <h5 className="card-title">Objetivos</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>          
        </>
    );
}
export default InitialSwitch;