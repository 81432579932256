import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useSortBy, usePagination, useTable, Column } from 'react-table';
import { Col, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft, faCaretSquareRight, faCaretSquareUp, faCaretSquareDown } from '@fortawesome/free-solid-svg-icons';
import { OficinaModel } from '../../../types/objetivos/oficinaTypes';
import DetalleOficinaModal from './DetalleOficinaModal';
import OficinaActions from './OficinaActions';


export default function ListadoOficinasTable() {

    const state = useSelector((state: RootState) => state);
    const { tableData } = state.objetivos.listadoOficinas;

    // Table

    const columns: Column<OficinaModel>[] = React.useMemo(() =>
        [
            {
                Header: 'Grupo',
                accessor: 'grupo',
                sortType: 'basic',
                Cell: (row: any) => <div style={{ textAlign: "center" }}>{row.value}</div>
            },
            {
                Header: 'NumPC',
                accessor: 'numPC',
                sortType: 'basic',
                Cell: (row: any) => <div style={{ textAlign: "center" }}>{row.value}</div>
            },
            {
                Header: 'Oficina',
                accessor: 'nombre',
                sortType: 'basic',
                Cell: (row: any) => <div style={{ textAlign: "center" }}>{row.value}</div>
            },
            {
                Header: 'Acciones',
                Cell: (props: any) => {
                    let oficina: OficinaModel = props.row.original;
                    return (
                        <OficinaActions data={oficina}/>
                    )
                }
            }
        ], []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable({
        columns,
        data: tableData,
        autoResetPage: false,
        autoResetSortBy: false,
        initialState: { pageIndex: 0 },
    }, useSortBy, usePagination);

    return (<>
        <DetalleOficinaModal />
        <table {...getTableProps()} className="table table-striped">
            <thead className="thead-light">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        {column.render('Header')}
                                        {
                                            <span style={{ float: "right" }}>
                                                {
                                                    column.isSorted ? (column.isSortedDesc ?
                                                        <FontAwesomeIcon icon={faCaretSquareDown} /> :
                                                        <FontAwesomeIcon icon={faCaretSquareUp} />)
                                                        : ''
                                                }
                                            </span>
                                        }
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
        <Row className="justify-content-md-between">
            <Col md={6}>
                <Button title="Primero" onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="btn-soliss" size="sm" style={{ marginRight: "3px" }}>
                    <FontAwesomeIcon icon={faCaretSquareLeft} /> <FontAwesomeIcon icon={faCaretSquareLeft} />
                </Button>
                <Button title="Anterior" onClick={() => previousPage()} disabled={!canPreviousPage} className="btn-soliss" size="sm" style={{ marginRight: "3px" }}>
                    <FontAwesomeIcon icon={faCaretSquareLeft} /> Anterior
                </Button>
                <Button title="Siguiente" onClick={() => nextPage()} disabled={!canNextPage} className="btn-soliss" size="sm" style={{ marginRight: "3px" }}>
                    Siguiente <FontAwesomeIcon icon={faCaretSquareRight} />
                </Button>
                <Button title="Último" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="btn-soliss" size="sm">
                    <FontAwesomeIcon icon={faCaretSquareRight} /> <FontAwesomeIcon icon={faCaretSquareRight} />
                </Button>
            </Col>
            <Col md={3} className="text-end">
                Página <strong>{pageOptions.length === 0 ? 0 : pageIndex + 1} de {pageOptions.length}</strong>
            </Col>
            <Col md={3} className="text-end">
                <select className="form-select form-select-sm" value={pageSize} onChange={e => { setPageSize(Number(e.target.value)) }}>
                    {[10, 20, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}> Mostrar {pageSize}</option>
                    ))}
                </select>
            </Col>
        </Row>
    </>);
};
