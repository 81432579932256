import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { ToastContainer, Toast } from 'react-bootstrap';
import { setActionResponse } from '../../actions/actionResponseActions';

export const ActionResponseToast = () => {

    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const { IsOk, ResponseMessage, IsVisible } = state.response;

    const hideToast = () => {

        dispatch(setActionResponse("IsVisible", false));
    }

    return (
        <ToastContainer className="p-3" position='middle-center'>
            <Toast onClose={() => hideToast()} show={IsVisible} delay={2000} autohide bg={IsOk ? "success" : "danger"}>
                <Toast.Header>
                    <strong className="me-auto">{IsOk ? "¡Éxito!" : "¡Error!"}</strong>
                </Toast.Header>
                <Toast.Body className='text-white'>{ResponseMessage}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
}

export default ActionResponseToast;