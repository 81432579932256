import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './../../../store';
import { Container, Col, Row, Form } from 'react-bootstrap';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import FicheroCargaTable from '../../../components/ranking/supervision/listado/FicheroCargaTable';
import { loadFicheroCargaListado, loadFicheroCargaTable } from '../../../actions/ranking/cargaActions';
import { FicheroCargaDTO, FicheroCargaTableModel } from '../../../types/ranking/ficheroCargaTypes';
import { getFicheroCargaByValorAnio } from '../../../services/ranking/cargaService';
import { getAllAnio } from '../../../services/ranking/anioService';
import FicheroCargaActionProcesarModal from '../../../components/ranking/supervision/listado/FicheroCargaActionProcesarModal';
import FicheroCargaActionEnlaceModal from '../../../components/ranking/supervision/listado/FicheroCargaActionEnlaceModal';

const Listado = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state);
    const { isLoading, anioSelected, anioValues, anualValues, tipoCargaSelected, tipoCargaValues } = state.ranking.ficheroCarga;

    // Methods

    const fetchTableData = async (anio: number, anualValue: boolean) => {

        var responseFicheroCarga = await getFicheroCargaByValorAnio(anio, anualValue);
        var ficheroCargaTableModelList: FicheroCargaTableModel[] = [];

        responseFicheroCarga.forEach(element => {
            ficheroCargaTableModelList.push(convertFicheroCargaEntityToTableModel(element, anio));
        });

        dispatch(loadFicheroCargaTable(ficheroCargaTableModelList));
    }

    const fetchAnios = async () => {

        dispatch(loadFicheroCargaListado({
            ...state.ranking.ficheroCarga,
            isLoading: true
        }));

        let response = await getAllAnio();

        let aniosList: number[] = [];

        response.forEach(element => {
            aniosList.push(element.valorAnio)
        });

        aniosList.sort((a, b) => (b - a));

        dispatch(loadFicheroCargaListado({
            ...state.ranking.ficheroCarga,
            isLoading: false,
            anioValues: aniosList,
            anioSelected: getYear(aniosList)
        }));
    }

    const getYear = (aniosList: number[]) => {

        let anio: number = 0;

        if (aniosList.length > 0) {

            if (anioSelected === 0) {

                let currentYear = new Date().getFullYear();

                if (!aniosList.find(year => year === currentYear)) {

                    anio = aniosList[aniosList.length - 1];

                } else {

                    anio = currentYear;
                }

            } else {

                anio = anioSelected;
            }
        }

        return anio;
    };

    const handleAnioCargaSelectOnChange = (event: any) => {

        fetchTableData(parseInt(event.target.value), anualValues);

        dispatch(loadFicheroCargaListado({
            ...state.ranking.ficheroCarga,
            anioSelected: parseInt(event.target.value)
        }));
    };

    const handleTipoCargaSelectOnChange = (event: any) => {

        let isAnualValue: boolean = event.target.value === "Anual";
        fetchTableData(anioSelected, isAnualValue);

        dispatch(loadFicheroCargaListado({
            ...state.ranking.ficheroCarga,
            tipoCargaSelected: event.target.value,
            anualValues: isAnualValue
        }));
    };

    const convertFicheroCargaEntityToTableModel = (entity: FicheroCargaDTO, anio: number) => {

        var model: FicheroCargaTableModel = {

            id: entity.id,
            fechaSubida: formatDate(new Date(entity.fechaCreacion), true),
            anio: entity.periodo.fechaFin !== null ? new Date(entity.periodo.fechaFin).getFullYear() : new Date(entity.periodo.fechaInicio).getFullYear(),
            mes: entity.periodo.fechaFin !== null ? (new Date(entity.periodo.fechaFin).getMonth() + 1).toString().padStart(2, '0') : (new Date(entity.periodo.fechaInicio).getMonth() + 1).toString().padStart(2, '0'),
            estado: entity.estado.nombre,
            valorAnio: anio,
            fechaInicio: formatDate(new Date(entity.periodo.fechaInicio), false),
            fechaFin: entity.periodo.fechaFin !== null ? formatDate(new Date(entity.periodo.fechaFin), false) : '',
            isPublicable: entity.isPublicable,
            isRechazado: entity.isRechazado,
        }

        return model;
    }

    function formatDate(inputDate: Date, spainFormat: boolean) {

        let date, month, year;

        date = inputDate.getDate();
        month = inputDate.getMonth() + 1;
        year = inputDate.getFullYear();

        date = date.toString().padStart(2, '0');
        month = month.toString().padStart(2, '0');

        if (spainFormat) {
            return `${date}/${month}/${year}`;
        } else {
            return `${year}/${month}/${date}`;
        }
    }

    // useEffect

    useEffect(() => {

        fetchAnios();

        if (anioSelected > 0) {

            fetchTableData(anioSelected, anualValues);
        }

    }, [anioSelected]);

    // Render

    return (
        <>
            {
                isLoading ? <LoadingSpinner /> : ''
            }
            <Container>
                <br />
                <h3>Listado de Rankings</h3>
                <hr />
                <Row className="align-items-center mb-3">
                    <Col md={1} className="text-center">
                        <label>Año:</label>
                    </Col>
                    <Col md={2}>
                        <Form.Group>
                            <Form.Select id="anioCargaSelect" onChange={handleAnioCargaSelectOnChange} value={anioSelected} disabled={anioValues.length === 0}>
                                {
                                    anioValues.map((elem, i) => {
                                        return <option key={"anioCarga_" + elem + i}>{elem}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={1} className="text-center">
                        <label>Tipo:</label>
                    </Col>
                    <Col md={2}>
                        <Form.Group>
                            <Form.Select id="tipoCargaSelect" onChange={handleTipoCargaSelectOnChange} value={tipoCargaSelected} disabled={anioValues.length === 0}>
                                {
                                    tipoCargaValues.map((elem, i) => {
                                        return <option key={"tipoCarga_" + elem + i}>{elem}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={12}>
                        <FicheroCargaActionEnlaceModal />
                        <FicheroCargaActionProcesarModal />
                        <FicheroCargaTable />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Listado;