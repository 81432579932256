import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './../../../store';
import { Container, Col, Row, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import { getOficinasByAnioId, getOficinasExcel } from '../../../services/objetivos/oficinaService';
import { getAllAnio } from '../../../services/objetivos/anioService';
import { loadListadoOficinas } from '../../../actions/objetivos/listadoOficinasActions';
import { AnioEntity } from '../../../types/objetivos/anioTypes';
import ListadoOficinasTable from '../../../components/objetivos/supervision/ListadoOficinasTable';
import { setActionResponse } from '../../../actions/actionResponseActions';


const ListadoOficinasPage = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state);
    const { isLoading, anioValues, anioIdSelected, tableData } = state.objetivos.listadoOficinas;

    // Methods

    const fetchTableData = async (anioId: number) => {

        var response = await getOficinasByAnioId(anioId);

        dispatch(loadListadoOficinas({
            ...state.objetivos.listadoOficinas,
            tableData: response,
            isLoading: false
        }));
    }

    const fetchAnios = async () => {

        let response = await getAllAnio();

        if (response.length > 0) {

            let actualDateAnioId = getAnioIdByActualDate(response);
            let actualDateObservaciones = getAnioObservacionesById(actualDateAnioId, response);

            dispatch(loadListadoOficinas({
                ...state.objetivos.listadoOficinas,
                isLoading: true,
                anioValues: response,
                anioIdSelected: actualDateAnioId,
                anioObservacionesSelected: actualDateObservaciones
            }));

        } else {

            dispatch(loadListadoOficinas({
                ...state.objetivos.listadoOficinas,
                isLoading: false
            }));
        }
    }

    const getAnioIdByActualDate = (aniosList: AnioEntity[]) => {

        let anioId: number = 0;

        if (aniosList.length > 0) {

            if (anioIdSelected === 0) {

                let currentYear = new Date().getFullYear();
                let actualyear = aniosList.find(year => year.valorAnio === currentYear);

                if (actualyear !== undefined) {

                    anioId = actualyear?.id;

                } else {

                    anioId = aniosList[aniosList.length - 1].id;
                }

            } else {

                anioId = anioIdSelected;
            }
        }

        return anioId;
    };

    const getAnioObservacionesById = (anioId: number, aniosList: AnioEntity[]) => {

        let observaciones: string = '';

        if (anioId > 0) {

            let anio = aniosList.find(anio => anio.id === anioId);

            if (anio !== undefined) {

                observaciones = anio?.observaciones;
            }
        }

        return observaciones;
    };

    const handleAnioCargaSelectOnChange = (event: any) => {

        dispatch(loadListadoOficinas({
            ...state.objetivos.listadoOficinas,
            anioIdSelected: parseInt(event.target.value),
            anioObservacionesSelected: getAnioObservacionesById(parseInt(event.target.value), anioValues),
            isLoading: true
        }));
    };

    const handleExtraerButtonOnClick = async (event: any) => {

        dispatch(loadListadoOficinas({
            ...state.objetivos.listadoOficinas,
            isLoading: true
        }));

        var result = await getOficinasExcel(anioIdSelected);

        if (result !== undefined) {

            const a = document.createElement('a');
            a.download = result.fileName;
            a.href = `data:application/xlsx;base64,${result.filecontent}`;
            a.click();

        } else {

            displayResponseAlert(false, "Ha ocurrido un error al generar el archivo Excel", true);
        }

        dispatch(loadListadoOficinas({
            ...state.objetivos.listadoOficinas,
            isLoading: false
        }));
    };

    const displayResponseAlert = (isOk: boolean, message: string, isVisible: boolean) => {

        dispatch(setActionResponse("IsOk", isOk));
        dispatch(setActionResponse("ResponseMessage", message));
        dispatch(setActionResponse("IsVisible", isVisible));
    }

    // useEffect

    useEffect(() => {

        fetchAnios();

        if (anioIdSelected > 0) {

            fetchTableData(anioIdSelected);
        }

    }, [anioIdSelected]);

    // Render

    return (
        <>
            {
                isLoading ? <LoadingSpinner /> : ''
            }
            <Container>
                <br />
                <h3>Listado de Oficinas</h3>
                <hr />
                <Row className="align-items-center mb-3">
                    <Col md={1} className="text-center">
                        <label>Año:</label>
                    </Col>
                    <Col md={2}>
                        <Form.Group>
                            <Form.Select id="anioCargaSelect" onChange={handleAnioCargaSelectOnChange} value={anioIdSelected} disabled={anioValues.length === 0}>
                                {
                                    anioValues.map((elem, i) => {
                                        return <option key={`anioCarga_${elem.id}_${elem.valorAnio}`} value={elem.id}>{elem.valorAnio}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        {
                            tableData.length > 0 ?
                                <Button title='Generar Excel' name="extraerButton" id="extraerButton" className="btn-soliss" onClick={handleExtraerButtonOnClick}><FontAwesomeIcon icon={faFileExcel} /></Button> : ''
                        }
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={12}>
                        {
                            tableData.length > 0 ?
                                <ListadoOficinasTable />
                                :
                                <Row>
                                    <Col md={9}>
                                        <br />
                                        <div className="alert alert-info" role="alert">
                                            <h5 className="alert-heading">Información: </h5>
                                            <p>No hay datos disponibles para el Año seleccionados.</p>
                                        </div>
                                    </Col>
                                </Row>
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ListadoOficinasPage;