import { Row, Col, Form, Container } from 'react-bootstrap';
import { ObjetivoModel, OficinaModel } from '../../../types/objetivos/oficinaTypes';


export default function DetalleOficina({ oficina, editable, handleValorInputOnChange }: { oficina: OficinaModel | undefined, editable: boolean, handleValorInputOnChange: any }) {

    return (
        <>
            {
                oficina?.objetivos.map((objCond: ObjetivoModel[], i: number) => {

                    let objetivosCounter: number = 0;
                    let objetivoJSX = objCond.map((obj: ObjetivoModel, j: number) => {

                        let repeatedDescription: boolean = false;

                        if (j > 0) {

                            repeatedDescription = obj.estructura.id === objCond[j - 1].estructura.id;
                        }

                        if (!repeatedDescription) {
                            
                            objetivosCounter++;
                        }

                        return (
                            <div key={`obj_${obj.idDatoCarga}`}>

                                {
                                    !repeatedDescription ?
                                        <Row className="mb-1 mt-3">
                                            <span><strong>{objetivosCounter}</strong>: {obj.estructura.descripcion}</span>
                                        </Row> : ''
                                }

                                <Form.Group as={Row} className="justify-content-sm-center">
                                    <Form.Label htmlFor={`valorinputid_${obj.idDatoCarga}`} column sm="3">{obj.tipoObjetivo}:</Form.Label>
                                    <Col sm="9" className='align-self-center'>
                                        {
                                            editable ?
                                                <Form.Control type="text" id={`valorinputid_${obj.idDatoCarga}`} name={`valorinputid_${obj.idDatoCarga}`} onBlur={handleValorInputOnChange} defaultValue={obj.valor} />
                                                :
                                                <span>{obj.valor}</span>
                                        }
                                    </Col>
                                </Form.Group>
                            </div>)
                    })

                    return (
                        <div key={`objCond_${i}`} className='mb-3'>
                            <h3>{objCond[i].estructura.tipo}</h3>
                            <hr />
                            <Container>
                                {objetivoJSX}
                            </Container>
                        </div>)
                })
            }
        </>
    );
};
