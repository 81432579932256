import { getCookie, setCookie, removeCookie } from 'typescript-cookie'
import { getSolissB2CTokenServiceUrl } from '../../configurations/environmentVariables';
import { HttpRequestHandler } from './httpRequestHandler';
import authentication from 'react-azure-b2c';
import decode from 'jwt-decode';

export class AuthHandler {

    public isTokenExpired = (token: string) => {

        const decodedToken: any = decode(token);
        return (Math.floor((new Date()).getTime() / 1000)) >= decodedToken.exp;
    }

    getAPIToken = async () => {

        if (window !== undefined) {

            let apiToken = getCookie("SolissNet_Token");

            if (apiToken !== undefined) {

                if (!this.isTokenExpired(apiToken)) {

                    return apiToken;
                }
            }

            let response = await this.getSolissApiUserData();

            if (response !== null) {

                this.setAPITokenCookie({ token: response.token, refreshToken: response.refreshToken });
                return response.token;
            }
        }

        return undefined;
    }

    setAPITokenCookie = ({ token, refreshToken }: any) => {

        if (typeof window !== "undefined") {

            setCookie("SolissNet_Token", token);
            setCookie("SolissNet_RefreshToken", refreshToken);
        }
    }

    getB2CToken = () => {

        return authentication.getAccessToken().accessToken;
    }

    getB2CTokenClaims() {

        return decode(this.getB2CToken());
    }

    getSolissApiUserData = async () => {

        const b2cToken = this.getB2CToken();
        const httpRequestHandler = new HttpRequestHandler();

        if(this.isTokenExpired(b2cToken)){

            this.logOut();
        }

        let response = await httpRequestHandler.get(`${getSolissB2CTokenServiceUrl()}`, b2cToken);

        if (response !== undefined) {

            return response;
        }

        return null;
    }

    logOut = () => {

        removeCookie("SolissNet_Token");
        removeCookie("SolissNet_RefreshToken");
        authentication.signOut();
    }
}