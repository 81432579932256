import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { setBasesModal, setInitBases } from '../../../actions/ranking/supervisionActions';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { createUpdateAnio } from '../../../services/ranking/anioService';
import { setActionResponse } from '../../../actions/actionResponseActions';

export default function AltaBasesModal({ typeacc, initForm }: { typeacc: string, initForm: Function }) {

    const router = useNavigate();

    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const { guardabases, guardabasesmodal, basesinitial } = state.ranking.supervision;

    const displayResponseAlert = (isOk: boolean, message: string, isVisible: boolean) => {
        dispatch(setActionResponse("IsOk", isOk));
        dispatch(setActionResponse("ResponseMessage", message));
        dispatch(setActionResponse("IsVisible", isVisible));
    }
    
    const handleClose = () => {
        if (guardabasesmodal.BasesAnualesChanged || guardabasesmodal.BasesMensualesChanged) {
            initForm(guardabases.ValorAnio);
        }
        dispatch(setBasesModal("Visible", false));
    }

    const handleSaveNewAnio = () => {
        createUpdateAnio(guardabases, basesinitial.DefaultAction).then((response) => {
            if (response !== undefined) 
            {
                dispatch(setBasesModal("Visible", false));
                displayResponseAlert(true, basesinitial.ActionMessage + "realizado con éxito.", true);

                if (typeacc === "Nuevo") {
                    router('/ranking/supervision/Listado');
                } else {
                    dispatch(setInitBases());
                    initForm(guardabases.ValorAnio);
                }
            } else {
                dispatch(setBasesModal("Visible", false));
                displayResponseAlert(false, basesinitial.ActionMessage + "se ha producido un error.", true);
            }
        });
    }

    return (
        <div>
            <Modal show={guardabasesmodal.Visible} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{guardabasesmodal.Titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{guardabasesmodal.Mensaje}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button className='btn btn-soliss' onClick={handleSaveNewAnio}>
                        Sí
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};