import { LOAD_CARGA, StateNewCarga } from "../../types/ranking/cargaTypes";

export const newCargaInitialState: StateNewCarga = {
    tipoCargaSelected: "",
    tipoCargaValues: ["Mensual", "Anual"],
    anioSelected: 0,
    anioValues: [],
    fechaInicio: "",
    fechaFin: "",
    periodoSelected: "",
    periodoValues: [],
    fichero: {
        ficheroNombre: "",
        ficheroContenido : ""
    },
    displayModal: false,
    isLoading: false,
    sended: false
};

export function cargaReducer(state = newCargaInitialState, action: any): StateNewCarga {

    switch (action.type) {

        case LOAD_CARGA: {
            return {
                ...state,
                tipoCargaSelected: action.payload.tipoCargaSelected,
                anioSelected: action.payload.anioSelected,
                anioValues: action.payload.anioValues,
                fechaInicio: action.payload.fechaInicio,
                fechaFin: action.payload.fechaFin,
                periodoSelected: action.payload.periodoSelected,
                periodoValues: action.payload.periodoValues,
                fichero: action.payload.fichero,
                displayModal: action.payload.displayModal,
                isLoading: action.payload.isLoading,
                sended: action.payload.sended
            } as StateNewCarga;
        }

        default:
            return state;
    }
}