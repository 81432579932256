import { EstadoEntity } from "./estadoTypes";
import { PeriodoEntity } from "./periodoTypes";

export const LOAD_FICHEROCARGA_LISTADO = "LOAD_FICHEROCARGA_LISTADO";
export const LOAD_FICHEROCARGA_TABLE = "LOAD_FICHEROCARGA_TABLE";
export const SET_FICHEROCARGA_MODAL_CONTENT = "SET_FICHEROCARGA_MODAL_CONTENT";

export type FicheroCargaDTO = {
    id: number,
    periodo: PeriodoEntity,
    estado: EstadoEntity,
    nombreFichero: string,
    contenidoFichero: string,
    usuarioCreacion: string,
    fechaCreacion: Date,
    usuarioUltimaAccion: string,
    fechaUltimaAccion: Date,
    isPublicable: boolean,
    isRechazado: boolean
};

export type FicheroCargaTableModel = {
    id: number,
    fechaSubida: string,
    anio: number,
    mes: string,
    estado: string,
    valorAnio: number,
    fechaInicio: string,
    fechaFin: string,
    isPublicable: boolean,
    isRechazado: boolean
};

export type StateFicheroCargaModalContent = {
    content: FicheroCargaTableModel,
};

export type StateFicheroCargaTable = {
    content: FicheroCargaTableModel[],
};

export type StateFicheroCargaListado = {
    anioSelected: number,
    anioValues: number[], 
    anualValues: boolean,
    tipoCargaSelected: string,
    tipoCargaValues: string[],
    displayProcesarModal: boolean,
    displayEnlaceModal: boolean,
    isLoading: boolean,
    table: StateFicheroCargaTable,
    modalsContent: StateFicheroCargaModalContent,
};