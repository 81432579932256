import { useEffect, useRef, useState, ChangeEvent } from 'react';
import * as XLSX from 'xlsx';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Form, Button, Modal } from 'react-bootstrap';
import { RootState } from './../../../store';
import { setInitCargaObjOfi, setCargaObjOfi } from '../../../actions/objetivos/cargaActions';
import { createUpdateFichero } from '../../../services/objetivos/ficheroCargaService';
import LoadingSpinner from '../../common/LoadingSpinner';
import { validateFileExtension, formatEuroChar, formatOffice, normalizeCsvHeader } from './../../../services/communications/commonFuncs';
import { setActionResponse } from '../../../actions/actionResponseActions';
import { ObjetivoOficinaJson, requiredHeadersObjetivoOficina } from '../../../types/objetivos/oficinaTypes';

export default function NewCargaForm() {

    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const { anioSelected, anioValues, displayModal, ficheroObjetivosOficina, isLoading, sended } = state.objetivos.cargaObjOfi;


    const displayResponseAlert = (isOk: boolean, message: string, isVisible: boolean) => {
        dispatch(setActionResponse("IsOk", isOk));
        dispatch(setActionResponse("ResponseMessage", message));
        dispatch(setActionResponse("IsVisible", isVisible));
    };

    const yearCargaSelectChange = (value: number) => {
        let currYearState = anioValues.find(u => u.valorAnio == value);
        if (currYearState !== undefined) {
            dispatch(setCargaObjOfi("anioIdGuardar", currYearState.id));
            dispatch(setCargaObjOfi("anioSelected", currYearState));
        }
    };
    
    const setGuardadoObjetivosOficina = async (e: ChangeEvent<HTMLInputElement>) => {
        let file = e.target.files !== null ? e.target.files[0] : undefined;
        if (file !== undefined) {
            if (validateFileExtension(file)) {
                processFile(file);
            }else{
                clearFileInput();
            } 
        }else{
            clearFileInput();
        }
    };

    const clearFileInput = () => {
        (document.getElementById('frmobjOfi') as HTMLFormElement).reset();
        dispatch(setCargaObjOfi("nombreFicheroGuardar", ""));
    };

    const processFile = (uploadedFile: any) => {

        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(uploadedFile);
        fileReader.onload = (readerData: any) => {

            let workbook = XLSX.read(readerData.target.result, { type: 'buffer' });
            let worksheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[worksheetName];
            let csvData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
            let jsonToSave : ObjetivoOficinaJson[] = [];

            try {

                if(csvData == null || csvData == undefined || csvData.length == 0){
                    let err : any = { message: "Error - el archivo no contiene datos" };
                    throw(err);
                }

                let checkHeaders : any = csvData[0];
                let formattedHeaders : Array<string> = [];
                let requiredHeaders: Array<string> = requiredHeadersObjetivoOficina;

                Object.entries(checkHeaders).forEach(([key, value]) => {
                    formattedHeaders.push(normalizeCsvHeader(key))
                });      
                formattedHeaders.forEach((element: string) => {               
                    if(!requiredHeaders.some(item => element == item)){
                        let err : any = { message: "Error en el fichero - le falta la columna " + element };
                        throw(err);
                    }
                });
       
                csvData.map((obj: any, index: number) => {

                    let item: ObjetivoOficinaJson = {
                        grupo: "",
                        oficina: "",
                        tipo_estructura: "",
                        numero_estructura: 0,
                        tipo_objetivo: "",
                        valor: ""
                    };

                    Object.entries(obj).forEach(([key, value]) =>  {

                      if (obj[key] === undefined || obj[key] === null || obj[key] === ""){
                        let err : any = { message: "Error en el fichero - se han encontrado datos vacíos en la línea " + index };
                        throw(err);
                      }
                      else {
                        if (normalizeCsvHeader(key).includes(requiredHeaders[0])){
                           item.grupo = formatOffice(obj[key].toString());
                        }
                        else if (normalizeCsvHeader(key).includes(requiredHeaders[1])){
                            item.oficina =  formatOffice(obj[key].toString());
                        }
                        else if (normalizeCsvHeader(key).includes(requiredHeaders[2])){
                            item.tipo_estructura = obj[key].toString();
                        }
                        else if (normalizeCsvHeader(key).includes(requiredHeaders[3])){
                            item.numero_estructura = obj[key].toString();
                        }
                        else if(normalizeCsvHeader(key).includes(requiredHeaders[4])){
                            item.tipo_objetivo = obj[key].toString();
                        }
                        else if (normalizeCsvHeader(key).includes(requiredHeaders[5])){
                            item.valor = formatEuroChar(obj[key].toString());
                        }              
                      }
                    });
                    jsonToSave.push(item);
                });      
                
                let processedCsvData = JSON.stringify(jsonToSave);
                dispatch(setCargaObjOfi("nombreFicheroGuardar", uploadedFile.name));
                dispatch(setCargaObjOfi("contenidoFicheroGuardar", processedCsvData));

            } catch (error: any) {
                let errorMessage = error.message;
                displayResponseAlert(false, errorMessage, true);
                clearFileInput();
            }
        }
    }

    const handleGuardarObjetivosOficinaOnClick = () => {
        handleModalHide();
        createUpdateFichero(ficheroObjetivosOficina).then((response) => {
          let baseMess = ficheroObjetivosOficina.id > 0 ? "Modificar" : "Guardar";
          if (response !== undefined) {
            displayResponseAlert(true, baseMess + " Objetivos Oficina " + anioSelected.valorAnio + " realizado con éxito.", true);
          } else {
            displayResponseAlert(false, baseMess + " Objetivos Oficina " + anioSelected.valorAnio + " se ha producido un error.", true);
          }
        });
    };

    const handleModalHide = () => {
        dispatch(setCargaObjOfi("displayModal", false));
        clearFileInput();
    };



    return (
        <>
            <Modal className="justify-content-md-center" show={displayModal} onHide={() => handleModalHide()} centered>
                <Modal.Header>
                    <Modal.Title>Nueva Carga</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmobjOfi">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Año</Form.Label>
                                    <Form.Select id="anioCargaSelect" onChange={(event) => yearCargaSelectChange(Number(event.target.value))}
                                        value={anioSelected.valorAnio}>
                                        {
                                            anioValues !== undefined && anioValues.map((elem, i) => (
                                                <option key={"anioCarga_" + elem.valorAnio + i} value={elem.valorAnio}>{elem.valorAnio}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
     
                                <Form.Group className="mb-3">
                                    <Form.Label>Fichero CSV Objetivos Oficinas</Form.Label>
                                    <Form.Control type="file" accept=".csv" id="objOfiFormFile" onChange={setGuardadoObjetivosOficina} />
                                </Form.Group>

                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-soliss' onClick={handleModalHide}>Cancelar</Button>
                    <Button className='btn btn-soliss' disabled={ficheroObjetivosOficina.nombreFichero === ""} onClick={handleGuardarObjetivosOficinaOnClick}>Cargar Datos</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};