import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './../../../../store';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { loadDatoCargaDetalle, loadDatoCargaActions } from '../../../../actions/ranking/cargaActions';
import { evaluateGrupoCarga, getGrupoCargaByIdFicheroCarga } from '../../../../services/ranking/cargaService';
import { setActionResponse } from '../../../../actions/actionResponseActions';
import { GrupoCargaEntity } from '../../../../types/ranking/grupoCargaTypes';

export default function DatoCargaActionModal() {

    const dispatch = useDispatch();
    const router = useNavigate();
    const state = useSelector((state: RootState) => state);
    const { idGrupoCargaSelected: idGrupoCarga, idFicheroCarga, evaluacion, observaciones, displayModal: displayModal } = state.ranking.datoCarga;

    // Methods

    const handleAceptarOnClick = async (event: any) => {

        dispatch(loadDatoCargaDetalle({
            ...state.ranking.datoCarga,
            displayModal: false,
            isLoading: true
        }));

        let request: any = {
            id: idGrupoCarga,
            correcto: evaluacion,
            observaciones: observaciones
        }

        let response = await evaluateGrupoCarga(request);

        if (response !== null) {

            displayResponseAlert(true, "Grupo evaluado", true);
            await fetchActionsData();

        } else {

            displayResponseAlert(false, "Ha ocurrido un error al intentar realizar la acción", true);
        }

        dispatch(loadDatoCargaDetalle({
            ...state.ranking.datoCarga,
            displayModal: false,
            observaciones: "",
            isLoading: false
        }));

    };

    const handleObservacionesInputOnChange = async (event: any) => {

        dispatch(loadDatoCargaDetalle({
            ...state.ranking.datoCarga,
            observaciones: event.target.value
        }));
    };

    const handleModalHide = (event: any) => {

        dispatch(loadDatoCargaDetalle({
            ...state.ranking.datoCarga,
            observaciones: "",
            displayModal: false
        }));
    };

    const displayResponseAlert = (isOk: boolean, message: string, isVisible: boolean) => {

        dispatch(setActionResponse("IsOk", isOk));
        dispatch(setActionResponse("ResponseMessage", message));
        dispatch(setActionResponse("IsVisible", isVisible));
    }

    const fetchActionsData = async () => {

        var responseGrupoCarga: GrupoCargaEntity[] = []
        responseGrupoCarga = await getGrupoCargaByIdFicheroCarga(idFicheroCarga);

        dispatch(loadDatoCargaActions(responseGrupoCarga));

        if (!responseGrupoCarga.find(grupoCarga => grupoCarga.correcto === null)) {
            router('/ranking/supervision/Listado');
        }
    }

    return (
        <>
            <Modal show={displayModal} onHide={() => handleModalHide} centered>
                <Modal.Header>
                    <Modal.Title>Nueva Carga</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        evaluacion === true ?
                            <p>¿Está seguro que quiere marcar como correcto los datos cargados?</p>
                            :
                            <Form>
                                <Row>
                                    <Col>
                                        <p>¿Está seguro que quiere marcar como incorrecto los datos cargados?</p>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Observaciones</Form.Label>
                                            <Form.Control as="textarea" id="observacionesInput" onChange={handleObservacionesInputOnChange} value={observaciones} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-soliss' disabled={observaciones === "" && evaluacion === false} onClick={handleAceptarOnClick}>Aceptar</Button>
                    <Button className='btn btn-soliss' onClick={handleModalHide}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
