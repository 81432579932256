import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './../../../store';
import { Row, Col, Form, Tabs, Tab, Container } from 'react-bootstrap';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import { getOficinasByNumPC } from '../../../services/objetivos/oficinaService';
import { getAllAnio } from '../../../services/objetivos/anioService';
import { loadDetalleOficina } from '../../../actions/objetivos/detalleOficinasActions';
import { AnioEntity } from '../../../types/objetivos/anioTypes';
import ObjetivosOficinas from '../../../components/objetivos/visualizacion/ObjetivosOficinas';

const DetalleOficinasPage = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state);
    const { isLoading, anioValues, anioIdSelected, anioObservacionesSelected, oficina } = state.objetivos.detalleOficinas;
    const [selectedTab, setSelectedTab] = useState("objOfi");
    const { numPC } = useParams<string>();

    // Methods

    const fetchOficinaData = async (numPC: string | undefined, anioId: number) => {

        return await getOficinasByNumPC(numPC, anioId, 3);
    }

    const fetchAnios = async () => {

        let response = await getAllAnio();
        let actualDateAnioId = getAnioIdByActualDate(response);
        let actualDateObservaciones = getAnioObservacionesById(actualDateAnioId, response);

        dispatch(loadDetalleOficina({
            ...state.objetivos.detalleOficinas,
            anioValues: response,
            anioIdSelected: actualDateAnioId,
            anioObservacionesSelected: actualDateObservaciones
        }));
    }

    const getAnioIdByActualDate = (aniosList: AnioEntity[]) => {

        let anioId: number = 0;

        if (aniosList.length > 0) {

            if (anioIdSelected === 0) {

                let currentYear = new Date().getFullYear();
                let actualyear = aniosList.find(year => year.valorAnio === currentYear);

                if (actualyear !== undefined) {

                    anioId = actualyear?.id;

                } else {

                    anioId = aniosList[aniosList.length - 1].id;
                }

            } else {

                anioId = anioIdSelected;
            }
        }

        return anioId;
    };

    const getAnioObservacionesById = (anioId: number, aniosList: AnioEntity[]) => {

        let observaciones: string = '';

        if (anioId > 0) {

            let anio = aniosList.find(anio => anio.id === anioId);

            if (anio !== undefined) {

                observaciones = anio?.observaciones;
            }
        }

        return observaciones;
    };

    const getParams = async () => {

        if (numPC !== undefined) {

            let response = await fetchOficinaData(numPC, anioIdSelected);

            dispatch(loadDetalleOficina({
                ...state.objetivos.detalleOficinas,
                oficina: response
            }));
        }
    };

    const handleAnioCargaSelectOnChange = (event: any) => {

        dispatch(loadDetalleOficina({
            ...state.objetivos.detalleOficinas,
            anioIdSelected: parseInt(event.target.value),
            anioObservacionesSelected: getAnioObservacionesById(parseInt(event.target.value), anioValues)
        }));
    };

    const handleTabOnSelect = (tab: string) => {

        setSelectedTab(tab);
    };

    // useEffect

    useEffect(() => {

        fetchAnios();

        if (anioIdSelected > 0) {

            getParams();
        }

    }, [anioIdSelected]);

    // Render

    return (
        <>
            {
                isLoading ? <LoadingSpinner /> : ''
            }
            <Container>
                <br />
                {
                    oficina !== undefined ?
                        <div>
                            <h3>Objetivos y Condicionantes asociados a: {oficina?.nombreAgrupado}</h3>
                            <hr />
                        </div> : ''
                }
                <Row className="align-items-center mb-3">
                    <Col md={1} className="text-center">
                        <label>Año:</label>
                    </Col>
                    <Col md={2}>
                        <Form.Group>
                            <Form.Select id="anioCargaSelect" onChange={handleAnioCargaSelectOnChange} value={anioIdSelected} disabled={anioValues.length === 0}>
                                {
                                    anioValues.map((elem, i) => {
                                        return <option key={`anioCarga_${elem.id}_${elem.valorAnio}`} value={elem.id}>{elem.valorAnio}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <br />
                {
                    oficina !== undefined ?

                        <Tabs activeKey={selectedTab} id="detalles_tabs" className="mb-3" onSelect={(e: any) => handleTabOnSelect(e)}>
                            <Tab eventKey="objOfi" title="Objetivos y Condicionantes">
                                <ObjetivosOficinas anioObservacionesSelected={anioObservacionesSelected} oficina={oficina} />
                            </Tab>
                            {/* <Tab eventKey="nivCump" title="Nivel de Cumplimiento">

                            </Tab> */}
                        </Tabs> 
                        :
                        <Row>
                            <Col md={9}>
                                <br />
                                <div className="alert alert-info" role="alert">
                                    <h5 className="alert-heading">Información: </h5>
                                    <p>No hay datos disponibles para el Año y Oficina seleccionados.</p>
                                </div>
                            </Col>
                        </Row>
                }
            </Container>
        </>
    );
};

export default DetalleOficinasPage;