import { HttpRequestHandler } from '../communications/httpRequestHandler';
import { getApiUrl } from '../../configurations/environmentVariables';
import { AuthHandler } from '../communications/authHandler';
import { FicheroCargaEntity } from '../../types/objetivos/ficheroCargaTypes';

const httpRequestHandler = new HttpRequestHandler();
const authHandler = new AuthHandler();

export const createUpdateFichero = async (dataSave: FicheroCargaEntity) : Promise<FicheroCargaEntity | undefined> => {

	var token = await authHandler.getAPIToken();
	const response = await httpRequestHandler.post(`${getApiUrl()}/Objetivo/FicheroCarga/CreateUpdate`, token, dataSave);

	if (response !== undefined && response.totalCount > 0) {
		return response.items[0];
	}
	else{
		return undefined;
	}
};