import { useEffect, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';

export const Error = (data: { errorType: number }) => {

    const [message, setMessage] = useState(" ");

    const setErrorMessage = () => {

        let errorMessage: string = "";

        switch (data.errorType) {

            case 1: // Usuario sin Rol
                errorMessage = "El usuario no tiene ningún rol asignado.";
                break;

            case 2: // Usuario sin permisos por Rol
                errorMessage = "El usuario no tiene permisos para acceder a esta página.";
                break;
            
            case 3: // Error en Servidor
                errorMessage = "No se puede acceder al servidor, vuelva a intentarlo más tarde o contacte con su supervisor.";
                break;

            case 404: // Página no encontrada
                errorMessage = "La página solicitada no existe.";
                break;

            case 500: // Error en Servidor
                errorMessage = "Ha ocurrido un error en el servidor, vuelva a intentarlo más tarde o contacte con su supervisor.";
                break;

            default:
                errorMessage = "Vuelva a intentarlo más tarde o contacte con su supervisor.";
                break;
        }

        setMessage(errorMessage);
    }

    useEffect(() => {

        setErrorMessage();

    }, [data]);

    return (
        <Row className="justify-content-md-center" style={{ height: "100%" }}>
            <Col md={6}>
                <br />
                <Card>
                    <Card.Body>
                        <Card.Title>¡Error!</Card.Title>
                        <Card.Subtitle className="mb-4 text-muted">Ha ocurrido un error</Card.Subtitle>
                        <hr/>
                        <Card.Text className="text-center">
                            {message}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default Error;