import { b2cConfigType } from "../types/azureB2c/azureB2cTypes";
import { PRE, PRO } from "./environmentTypes";

export function getBaseUrl(): string {

    switch (process.env.REACT_APP_ENVIRONMENT) {

        case PRE:
            return process.env.REACT_APP_BASE_URL_PRE!;

        case PRO:
            return process.env.REACT_APP_BASE_URL_PRO!;

        default:
            return process.env.REACT_APP_BASE_URL_DEV!;
    }
};

export function getApiUrl(): string {

    switch (process.env.REACT_APP_ENVIRONMENT) {

        case PRE:
            return process.env.REACT_APP_API_URL_PRE!;

        case PRO:
            return process.env.REACT_APP_API_URL_PRO!;

        default:
            return process.env.REACT_APP_API_URL_DEV!;
    }
};

export function getUnauthorizedUserUrl(): string {

    switch (process.env.REACT_APP_ENVIRONMENT) {

        case PRE:
            return process.env.REACT_APP_SOLISS_B2C_USER_NOT_AUTHORIZED_URL_PRE!;

        case PRO:
            return process.env.REACT_APP_SOLISS_B2C_USER_NOT_AUTHORIZED_URL_PRO!;

        default:
            return process.env.REACT_APP_SOLISS_B2C_USER_NOT_AUTHORIZED_URL_DEV!;
    }
};


export function getSolissB2CTokenServiceUrl(): string {

    switch (process.env.REACT_APP_ENVIRONMENT) {

        case PRE:
            return process.env.REACT_APP_SOLISS_B2C_TOKEN_URL_PRE!;

        case PRO:
            return  process.env.REACT_APP_SOLISS_B2C_TOKEN_URL_PRO!;

        default:
            return  process.env.REACT_APP_SOLISS_B2C_TOKEN_URL_DEV!;
    }
};

export function getB2CConfiguration(): b2cConfigType {

    let b2cConfiguration: b2cConfigType = {
        instance: '',
        tenant: '',
        signInPolicy: '',
        clientId: '',
        cacheLocation: 'sessionStorage',
        scopes: '',
        redirectUri: ''
    };

    switch (process.env.REACT_APP_ENVIRONMENT) {

        case PRE:

            b2cConfiguration.instance = process.env.REACT_APP_B2C_APP_INSTANCE_PRE!;
            b2cConfiguration.tenant = process.env.REACT_APP_B2C_TENANT_PRE!;
            b2cConfiguration.signInPolicy = process.env.REACT_APP_B2C_SIGN_IN_POLICY_PRE!;
            b2cConfiguration.clientId = process.env.REACT_APP_B2C_CLIENT_ID_PRE!;
            b2cConfiguration.scopes = process.env.REACT_APP_B2C_SCOPES_PRE!;
            b2cConfiguration.redirectUri = process.env.REACT_APP_B2C_REDIRECT_URI_PRE!;
            break;

        case PRO:

            b2cConfiguration.instance = process.env.REACT_APP_B2C_APP_INSTANCE_PRO!;
            b2cConfiguration.tenant = process.env.REACT_APP_B2C_TENANT_PRO!;
            b2cConfiguration.signInPolicy = process.env.REACT_APP_B2C_SIGN_IN_POLICY_PRO!;
            b2cConfiguration.clientId = process.env.REACT_APP_B2C_CLIENT_ID_PRO!;
            b2cConfiguration.scopes = process.env.REACT_APP_B2C_SCOPES_PRO!;
            b2cConfiguration.redirectUri = process.env.REACT_APP_B2C_REDIRECT_URI_PRO!;
            break;

        default:

            b2cConfiguration.instance = process.env.REACT_APP_B2C_APP_INSTANCE_DEV!;
            b2cConfiguration.tenant = process.env.REACT_APP_B2C_TENANT_DEV!;
            b2cConfiguration.signInPolicy = process.env.REACT_APP_B2C_SIGN_IN_POLICY_DEV!;
            b2cConfiguration.clientId = process.env.REACT_APP_B2C_CLIENT_ID_DEV!;
            b2cConfiguration.scopes = process.env.REACT_APP_B2C_SCOPES_DEV!;
            b2cConfiguration.redirectUri = process.env.REACT_APP_B2C_REDIRECT_URI_DEV!;
            break;
    }

    return b2cConfiguration;
};