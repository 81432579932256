import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Error as ErrorComponent } from '../components/common/Error';
import { useParams } from "react-router-dom";

const Error = () => {

    const errorType = useParams();
    const [errorTypeId, setErrorTypeId] = useState(0);

    // useEffect

    useEffect(() => {

        if (errorType !== undefined) {

            if ('type' in errorType) {

                setErrorTypeId(parseInt(errorType.type!.toString()))
                
            }
        }

    }, [errorType]);

    // Render

    return (
        <Container>
            <ErrorComponent errorType={errorTypeId} />
        </Container>
    );

};

export default Error;