import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './../../../store';
import { Container, Col, Row, Tab, Tabs, Form } from 'react-bootstrap';
import DatoCargaTable from '../../../components/ranking/supervision/detalle/DatoCargaTable';
import DatoCargaActions from '../../../components/ranking/supervision/detalle/DatoCargaActions';
import DatoCargaActionModal from '../../../components/ranking/supervision/detalle/DatoCargaActionModal';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import { getDatoCargaMensual, getDatoCargaAnual, getGrupoCargaByIdFicheroCarga } from '../../../services/ranking/cargaService';
import { loadDatoCargaDetalle, loadDatoCargaTable, loadDatoCargaActions } from '../../../actions/ranking/cargaActions';
import { DatoCargaEntity } from '../../../types/ranking/datoCargaTypes';
import { GrupoCargaEntity } from '../../../types/ranking/grupoCargaTypes';

const Detalle = () => {

    const dispatch = useDispatch();
    const [tabSelected, setTableSelectedKey] = useState('grupo-a');
    const state = useSelector((state: RootState) => state);
    const { isLoading, idGrupoCargaSelected: idGrupoCarga, idFicheroCarga, valorAnio, fechaInicio, fechaFin } = state.ranking.datoCarga;

    // Methods

    const changeTabs = async (tabKey: string) => {

        await fetchActionsData();
        await fetchTableData(tabKey);
        setTableSelectedKey(tabKey);
    }

    const fetchTableData = async (tabKey: string) => {

        let idGrupoSelected: number = 0;

        switch (tabKey) {
            case "grupo-a":
                idGrupoSelected = 1;
                break;
            case "grupo-b":
                idGrupoSelected = 2;
                break;
            case "grupo-c":
                idGrupoSelected = 3;
                break;
        }

        var responseDatoCarga: DatoCargaEntity[] = []

        if (fechaFin === "") {

            responseDatoCarga = await getDatoCargaMensual(idGrupoSelected, valorAnio, fechaInicio);

        } else {

            responseDatoCarga = await getDatoCargaAnual(idGrupoSelected, valorAnio, fechaInicio, fechaFin);
        }

        if (responseDatoCarga.length > 0) {

            dispatch(loadDatoCargaDetalle({
                ...state.ranking.datoCarga,
                idGrupoCargaSelected: responseDatoCarga[0].idGrupoCarga
            }));
        }

        dispatch(loadDatoCargaTable(responseDatoCarga));
    }

    const fetchActionsData = async () => {

        var responseGrupoCarga: GrupoCargaEntity[] = []
        responseGrupoCarga = await getGrupoCargaByIdFicheroCarga(idFicheroCarga);

        dispatch(loadDatoCargaActions(responseGrupoCarga));
    }

    const getMonth = () => {

        if (fechaFin === "") {

            let date = new Date(fechaInicio);
            return date.toLocaleString('default', { month: 'long' }).toLocaleUpperCase();

        } else {
            return "";
        }
    }

    // useEffect

    useEffect(() => {

        fetchTableData("grupo-a");
        fetchActionsData();

    }, []);

    // Render

    return (
        <>
            {
                isLoading ? <LoadingSpinner /> : ''
            }
            <Container>
                <br />
                <h3>Detalle</h3>
                <hr />
                <Row className="align-items-center mb-3">
                    <Col md={1} className="text-center">
                        <label>Año:</label>
                    </Col>
                    <Col md={2}>
                        <Form.Control type="text" id="observacionesInput" disabled={true} value={valorAnio} />
                    </Col>
                    <Col md={1} className="text-center">
                        <label>Tipo:</label>
                    </Col>
                    <Col md={2}>
                        <Form.Control type="text" id="observacionesInput" disabled={true} value={fechaFin === "" ? "Mensual" : "Anual"} />
                    </Col>
                    {
                        fechaFin === "" ?
                            <>
                                <Col md={1} className="text-center">
                                    <label>Mes:</label>
                                </Col>
                                <Col md={2}>
                                    <Form.Control type="text" id="observacionesInput" disabled={true} value={getMonth()} />
                                </Col>
                            </> : ""
                    }
                </Row>
                <Row>
                    <Col md={8}>
                        <Tabs id="grupos-tab" activeKey={tabSelected} onSelect={(tabKey: any) => changeTabs(tabKey)} className="mb-3">
                            <Tab eventKey="grupo-a" title="Grupo A" />
                            <Tab eventKey="grupo-b" title="Grupo B" />
                            <Tab eventKey="grupo-c" title="Grupo C" />
                        </Tabs>
                        <DatoCargaTable />
                    </Col>
                    <Col md={4}>
                        {
                            idGrupoCarga > 0 ?
                                <>
                                    <DatoCargaActions />
                                    <DatoCargaActionModal />
                                </> : ''
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Detalle;