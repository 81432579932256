import { Navigate } from 'react-router-dom';
import { ProtectedRouteProps } from '../../types/securization/protectedRouteProps';

export const PrivateRoute: React.FC<ProtectedRouteProps> = ({ isSignedIn, hasrequiredRole, component: RouteComponent }) => {

    if (isSignedIn && hasrequiredRole) {

        return <RouteComponent />

    } else {

        if (!isSignedIn) {

            return <Navigate to="/error/1" />
        }

        if (!hasrequiredRole) {

            return <Navigate to="/error/2" />
        }

        return <Navigate to="/error" />
    }
}