import { SET_RESPONSE, HIDE_RESPONSE, actionResponseType } from   "../types/actionResponseTypes";

const stateAction: actionResponseType = {
    IsOk: true,
    ResponseMessage: "",
    IsVisible: false
};


export function actionresponseReducer(state = stateAction, action: any): actionResponseType {
    switch (action.type) {
        case SET_RESPONSE: {
            var currentStateResponse = { ...state } as actionResponseType;
            currentStateResponse["IsOk"] = action.payload.prop === "IsOk" ? action.payload.datares : currentStateResponse.IsOk;
            currentStateResponse["ResponseMessage"] = action.payload.prop === "ResponseMessage" ? action.payload.datares : currentStateResponse.ResponseMessage;
            currentStateResponse["IsVisible"] = action.payload.prop === "IsVisible" ? action.payload.datares : currentStateResponse.IsVisible;
            return {
                ...state,
                IsOk: currentStateResponse["IsOk"],
                ResponseMessage: currentStateResponse["ResponseMessage"],
                IsVisible: currentStateResponse["IsVisible"]
            } as actionResponseType;
        }
        case HIDE_RESPONSE: {
            return {
                ...state,
                IsOk: stateAction.IsOk,
                ResponseMessage: stateAction.ResponseMessage,
                IsVisible: action.payload.IsVisible
            } as actionResponseType;
        }
        default:
            return state;
    }
};