import { Tabs, Tab, Container, Col, Row, Form, Button } from 'react-bootstrap';
import { useEffect } from 'react';
import { getListadoCarga } from '../../../services/ranking/cargaService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './../../../store';
import { loadCargaListado } from '../../../actions/ranking/listadoCargaAction';
import { getAllAnio } from '../../../services/ranking/anioService';
import { loadNewCarga } from '../../../actions/ranking/cargaActions';
import NewCargaForm from '../../../components/ranking/carga/NewCargaForm';

const Listado = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state);
    const { anioSelected, anioValues, isAnual, values } = state.ranking.listadoCarga;
    const { sended } = state.ranking.carga;

    const nuevaCarga = loadNewCarga;

    let monthList = [
        "12", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"
    ];

    // Methods

    const refreshData = async () => {
        let response = await getAllAnio();
        let aniosList: number[] = [];
        response.forEach(element => {
            aniosList.push(element.valorAnio)
        });
        aniosList.sort((a, b) => (b - a));

        getListadoCarga(anioSelected, isAnual).then((response) => {
            dispatch(loadCargaListado({
                ...state.ranking.datoCarga,
                values: response,
                anioValues: aniosList,
                isAnual: isAnual,
                anioSelected: anioSelected
            }));
        });
    }

    const changeTabs = async (tabKey: any) => {

        let isAnual: boolean = tabKey === "anual";
        let aniosList: number[] = [];

        let response = await getAllAnio();

        response.forEach(element => {
            aniosList.push(element.valorAnio)
        });
        aniosList.sort((a, b) => (b - a));

        getListadoCarga(anioSelected, isAnual).then((response) => {
            dispatch(loadCargaListado({
                ...state.ranking.datoCarga,
                values: response,
                anioValues: aniosList,
                isAnual: isAnual,
                anioSelected: anioSelected
            }));
        });
    }

    const handleAnioCargaSelectOnChange = (event: any) => {
        var newAnio = event.target.value;
        getListadoCarga(newAnio, isAnual).then((response) => {
            dispatch(loadCargaListado({
                ...state.ranking.datoCarga,
                values: response,
                anioValues: anioValues,
                isAnual: isAnual,
                anioSelected: newAnio
            }));
        });
    }

    const fetchListadoCarga = async () => {
        let response = await getAllAnio();
        let aniosList: number[] = [];
        response.forEach(element => {
            aniosList.push(element.valorAnio)
        });
        aniosList.sort((a, b) => (b - a));

        getListadoCarga(aniosList[0], true).then((response) => {
            dispatch(loadCargaListado({
                ...state.ranking.datoCarga,
                values: response,
                anioValues: aniosList,
                isAnual: true,
                anioSelected: aniosList[0]
            }));
        });
    }

    const nuevaCargaLoad = () => {
        dispatch(nuevaCarga({
            ...state.ranking.carga,
            anioSelected: anioSelected,
            periodoSelected: "",
            tipoCargaSelected: "",
            displayModal: true
        }));
    }

    // useEffect

    useEffect(() => {

        fetchListadoCarga();

    }, []);

    useEffect(() => {

        if (sended) {
            
            dispatch(nuevaCarga({ ...state.ranking.carga, sended: false }))
            refreshData();
        }

    }, [sended])

    // Render

    return (
        <Container>
            <br />
            <h3>Listado de Cargas</h3>
            <hr />
            <Row className="align-items-center mb-3">
                <Col md={1} className="text-center">
                    <label>Año:</label>
                </Col>
                <Col md={2}>
                    <Form.Group>
                        <Form.Select id="anioCargaSelect" onChange={handleAnioCargaSelectOnChange} value={anioSelected}>
                            {
                                anioValues !== undefined && anioValues.map((elem, i) => (
                                    <option key={"anioCarga_" + elem + i}>{elem}</option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={2}>
                    <Button className='btn btn-soliss' title="Nueva Carga" onClick={() => nuevaCargaLoad()}>Nueva carga</Button>
                </Col>
            </Row>
            <br />
            <Tabs defaultActiveKey="anual" id="listado-tab" className="mb-3" onSelect={(tabKey: any) => changeTabs(tabKey)}>
                <Tab eventKey="anual" title="Datos Anuales" />
                <Tab eventKey="mensual" title="Datos Mensuales" />
            </Tabs>
            <table className="table table-striped">
                <thead className="thead-light">
                    <tr className="text-center">
                        <th>Mes</th>
                        <th>Año</th>
                        <th>Estado</th>
                        <th>Observaciones</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        monthList.map((month, index) => {
                            let carga = values.find(i => month === ("0" + (new Date(isAnual ? i.fechaFin : i.fechaInicio).getMonth() + 1)).slice(-2));
                            if (carga !== undefined) {
                                return (
                                    <tr key={`carga_${carga.id}_${index}`}>
                                        <td className="text-center" style={{ width: "150px" }}>{month}</td>
                                        <td className="text-center" style={{ width: "150px" }}>{new Date(isAnual ? carga.fechaFin : carga.fechaInicio).getFullYear()}</td>
                                        <td>{carga.estadoNombre}</td>
                                        <td dangerouslySetInnerHTML={{ __html: carga.observaciones_Unificadas }}></td>
                                    </tr>)
                            }

                            return (
                                <tr key={`carga_${index}`}>
                                    <td className="text-center" style={{ width: "150px" }}>{month}</td>
                                    <td className="text-center" style={{ width: "150px" }}>{month === "12" ? anioSelected > 0 ? anioSelected - 1 : '' : anioSelected}</td>
                                    <td></td>
                                    <td></td>
                                </tr>)
                        })
                    }
                </tbody>
            </table>
            <NewCargaForm loadNewCarga={nuevaCarga} />
        </Container>
    );
};

export default Listado;