import { HttpRequestHandler } from '../communications/httpRequestHandler';
import { IListadoCargaItem } from '../../types/ranking/cargaTypes';
import { DatoCargaEntity, DatoCargaGruposEntity } from '../../types/ranking/datoCargaTypes';
import { GrupoCargaEntity } from '../../types/ranking/grupoCargaTypes';
import { PeriodoEntity } from '../../types/ranking/anioTypes';
import { FicheroCargaDTO } from '../../types/ranking/ficheroCargaTypes';
import { getApiUrl } from '../../configurations/environmentVariables';
import { AuthHandler } from '../communications/authHandler';
import { FileType } from '../../types/fileTypes';

const httpRequestHandler = new HttpRequestHandler();
const authHandler = new AuthHandler();

export const createCarga = async (newCarga: any): Promise<boolean> => {

	var token = await authHandler.getAPIToken();
	var response = await httpRequestHandler.post(`${getApiUrl()}/Ranking/FicheroCarga/GenerateRanking`, token, newCarga);

	if (response !== undefined) {

		return response;
	}

	return false;
};

export const getListadoCarga = async (anio:number, isAnual: boolean): Promise<IListadoCargaItem[]> => {

	var token = await authHandler.getAPIToken();
	var response = await httpRequestHandler.get(`${getApiUrl()}/Ranking/ListadoCarga/Get?anio=${anio}&isAnual=${isAnual}`, token);

	if (response !== undefined) {
		if (response.totalCount > 0) {

			return response.items;
		}
	}
	return [];
};

export const getDatoCargaMensual = async (idGrupo: number, valorAnio: number, fechaInicio: string): Promise<DatoCargaEntity[]> => {

	var token = await authHandler.getAPIToken();
	var response = await httpRequestHandler.get(`${getApiUrl()}/Ranking/DatoCarga/GetMensual?valorAnio=${valorAnio}&idGrupo=${idGrupo}&fechainicio=${fechaInicio}`, token);

	if (response !== undefined) {

		return response.items;
	}

	return [];
};

export const getDatoCargaAnual = async (idGrupo: number, valorAnio: number, fechaInicio: string, fechaFin: string): Promise<DatoCargaEntity[]> => {

	var token = await authHandler.getAPIToken();
	var response = await httpRequestHandler.get(`${getApiUrl()}/Ranking/DatoCarga/GetAnual?valorAnio=${valorAnio}&idGrupo=${idGrupo}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`, token);

	if (response !== undefined) {

		return response.items;
	}

	return [];
};

export const getTipoCargaGrupos = async (valorAnio: number, tipo: number, fechaInicio: string, fechaFin: string) : Promise<DatoCargaGruposEntity | undefined> => {

	var token = await authHandler.getAPIToken();
	var response = await httpRequestHandler.get(`${getApiUrl()}/Ranking/DatoCarga/GetTipoCargaGrupos?valorAnio=${valorAnio}&tipo=${tipo}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`, token);
	if (response !== undefined) {

		return response;
	}

	return undefined;
};

export const evaluateGrupoCarga = async (evaluation: any): Promise<boolean> => {

	var token = await authHandler.getAPIToken();
	var response = await httpRequestHandler.post(`${getApiUrl()}/Ranking/GrupoCarga/Evaluate`, token, evaluation);

	if (response !== undefined) {

		return response;
	}

	return false;
};

export const getGrupoCargaByIdFicheroCarga = async (idFicheroCarga: number): Promise<GrupoCargaEntity[]> => {

	var token = await authHandler.getAPIToken();
	var response = await httpRequestHandler.get(`${getApiUrl()}/Ranking/GrupoCarga/GetAllByIdFicheroCarga?idFicheroCarga=${idFicheroCarga}`, token);

	if (response !== undefined) {

		return response.items;
	}

	return [];
};

export const getPeriodosFicheroCargaEstado = async (valorAnio: number, tipo: number): Promise<PeriodoEntity[]> => {

	var token = await authHandler.getAPIToken();
	var response = await httpRequestHandler.get(`${getApiUrl()}/Ranking/FicheroCarga/GetPeriodosFicheroCargaEstado?valorAnio=${valorAnio}&tipo=${tipo}` , token);

	if (response !== undefined && response.totalCount > 0) {

		return response.items;
	}

	return [];
};


export const getFicheroCargaByValorAnio = async (valorAnio: number, anualValues: boolean): Promise<FicheroCargaDTO[]> => {

	var token = await authHandler.getAPIToken();
	var response = await httpRequestHandler.get(`${getApiUrl()}/Ranking/FicheroCarga/GetFicheroCargaByValorAnio?valorAnio=${valorAnio}&anualValues=${anualValues}`, token);

	if (response !== undefined) {

		return response.items;
	}

	return [];
};

export const updateEstadoFicheroCarga = async (estadoFicheroCarga: any): Promise<boolean> => {

	var token = await authHandler.getAPIToken();
	var response = await httpRequestHandler.post(`${getApiUrl()}/Ranking/FicheroCarga/UpdateEstado`, token, estadoFicheroCarga);

	if (response !== undefined) {

		return response.items.length > 0;
	}

	return false;
};

export const getFicheroCargaExcel = async (idFicheroCarga: number): Promise<FileType | undefined> => {

	var token = await authHandler.getAPIToken();
	var response = await httpRequestHandler.get(`${getApiUrl()}/Ranking/DatoCarga/DescargaDatoCargaExcel?idFicheroCarga=${idFicheroCarga}`, token);

	if (response !== undefined) {

		return response;
	}

	return undefined;
};
