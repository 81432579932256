import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store';
import { Modal, Button } from 'react-bootstrap';
import { loadFicheroCargaListado, setFicheroCargaModalContent } from '../../../../actions/ranking/cargaActions';
import { setActionResponse } from '../../../../actions/actionResponseActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { getBaseUrl } from '../../../../configurations/environmentVariables';

export default function FicheroCargaActionEnlaceModal() {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state);
    const { displayEnlaceModal, modalsContent } = state.ranking.ficheroCarga;

    // Methods

    const handleCopiarOnClick = async (event: any) => {

        navigator.clipboard.writeText(generateLink());
        displayResponseAlert(true, "Enlace Copiado", true);
    };

    const handleModalHide = (event: any) => {

        dispatch(setFicheroCargaModalContent({
            id: 0,
            fechaSubida: "",
            anio: 0,
            mes: "",
            estado: "",
            valorAnio: 0,
            fechaInicio: "",
            fechaFin: "",
            isPublicable: false,
            isRechazado: false
        }));

        dispatch(loadFicheroCargaListado({
            ...state.ranking.ficheroCarga,
            displayEnlaceModal: false
        }));
    };

    const generateLink = () => {

        let tipoCarga : number = modalsContent.content.fechaFin === "" || modalsContent.content.fechaFin === undefined ? 1 : 2;
        let periodo : string = tipoCarga === 1 ? `${modalsContent.content.mes}_${modalsContent.content.fechaInicio.split('/')[0]}` : `${modalsContent.content.mes}_${modalsContent.content.fechaFin.split('/')[0]}`;

        let link :string = `${getBaseUrl()}/ranking/visualizacion/Ranking/${modalsContent.content.valorAnio}/${tipoCarga}/${periodo}`;

        return link;
    }

    const displayResponseAlert = (isOk: boolean, message: string, isVisible: boolean) => {

        dispatch(setActionResponse("IsOk", isOk));
        dispatch(setActionResponse("ResponseMessage", message));
        dispatch(setActionResponse("IsVisible", isVisible));
    }

    return (
        <>
            <Modal show={displayEnlaceModal} size="lg" onHide={() => handleModalHide} centered>
                <Modal.Header>
                    <Modal.Title>Enlace del Ranking</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <a href={generateLink()} target="_blank">{generateLink()}</a>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-soliss col-3' onClick={handleCopiarOnClick}>Copiar <FontAwesomeIcon icon={faCopy} /></Button>
                    <Button className='btn btn-soliss col-3' onClick={handleModalHide}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
