import { Tabs, Tab, Container, Col, Row, Form, Button } from 'react-bootstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './../../../store';
import { setInitCargaObjOfi, setCargaObjOfi } from '../../../actions/objetivos/cargaActions';
import { getAllAnio, getMaxAnio, getObjetivoAnual } from '../../../services/objetivos/anioService';
import { maxElemFromList } from '../../../services/communications/commonFuncs';
import { AnioEntity } from '../../../types/objetivos/anioTypes';
import NewCargaForm  from '../../../components/objetivos/carga/NewCargaForm';

const Listado = () => {

    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const { anioSelected, anioValues, displayModal, ficheroObjetivosOficina, isLoading, sended } = state.objetivos.cargaObjOfi;


    useEffect(() => {
        
        getAllAnio().then((response) => {
            let yearSelect = response.map(u => u.valorAnio);
            let yearSelected = maxElemFromList(yearSelect);
            let idSelected = response.find(u => u.valorAnio == yearSelected);
            dispatch(setCargaObjOfi("anioValues", response));
            if (idSelected !== undefined) {
                yearSelectChange(yearSelected, response);
            }
            dispatch(setCargaObjOfi("Loading", false));
        });

    }, []);


    const yearSelectChange = (value: number, years: AnioEntity[] | undefined) => {
        if (years !== undefined) {
            let currYear = years.find(u => u.valorAnio == value);
            if (currYear !== undefined) {
                dispatch(setCargaObjOfi("anioIdGuardar", currYear.id));
                dispatch(setCargaObjOfi("anioSelected", currYear));
            }
        } else {
            let currYearState = anioValues.find(u => u.valorAnio == value);
            if (currYearState !== undefined) {
                dispatch(setCargaObjOfi("anioIdGuardar", currYearState.id));
                dispatch(setCargaObjOfi("anioSelected", currYearState));
            }
        }
    };

    const nuevaCargaLoad = () => {
        dispatch(setCargaObjOfi("displayModal", true));
    };

    return (
        <Container>
            <br />
            <h3>Listado de Cargas</h3>
            <hr />
            <Row className="align-items-center mb-3">
                <Col md={1} className="text-center">
                    <label>Año:</label>
                </Col>
                <Col md={2}>
                    <Form.Group>
                        <Form.Select id="anioCargaSelect" onChange={(event) => yearSelectChange(Number(event.target.value), undefined)}
                            value={anioSelected.valorAnio}>
                            {
                                anioValues !== undefined && anioValues.map((elem, i) => (
                                    <option key={"anioCarga_" + elem.valorAnio + i} value={elem.valorAnio}>{elem.valorAnio}</option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={2}>
                    <Button className='btn btn-soliss' title="Nueva Carga" onClick={() => nuevaCargaLoad()}>Nueva carga</Button>
                </Col>
            </Row>

            <NewCargaForm />
        </Container>
        
    );

};

export default Listado;