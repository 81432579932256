import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { RootState } from '../../../store';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import { setAnioGuardado, setEstructuraGuardado, setInitAnioGuardado, setTipoGuardado } from '../../../actions/objetivos/anioGuardadoActions';
import ObservacionesFormTab from '../../../components/objetivos/supervision/ObservacionesFormTab';
import EstructuraFormTab from '../../../components/objetivos/supervision/EstructuraFormTab';
import ObjetivosOficinaFormTab from '../../../components/objetivos/supervision/ObjetivosOficinaFormTab';
import { getAllAnio, getMaxAnio, getObjetivoAnual } from '../../../services/objetivos/anioService';
import { maxElemFromList } from '../../../services/communications/commonFuncs';
import { AnioEntity } from '../../../types/objetivos/anioTypes';

const Anio = () => {

    const { typeaccAnio } = useParams();
    const navigate = useNavigate();

    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const { anio, stateAnioSelect, loading, defaultActiveTab } = state.objetivos.anioguardado;

    useEffect(() => {
        if (typeaccAnio !== undefined && typeaccAnio !== "Nuevo" && typeaccAnio !== "Editar") {
            navigate("/error/404");
        } else {
            initForm();
        }
    }, [typeaccAnio]);

    const initForm = () => {

        dispatch(setAnioGuardado("Loading", true));

        if (typeaccAnio === "Nuevo") {

            getMaxAnio().then((response) => {

                dispatch(setInitAnioGuardado());

                if (response !== undefined) {
                    response.valorAnio += 1;
                    yearData(response.valorAnio);
                }

                dispatch(setAnioGuardado("Loading", false));
            });
        } 

        if (typeaccAnio === "Editar") {

            getAllAnio().then((response) => {

                if(response.length > 0){

                    let yearSelect = response.map(u => u.valorAnio);
                    let yearSelected = maxElemFromList(yearSelect);
                    let idSelected = response.find(u => u.valorAnio == yearSelected);
    
                    dispatch(setAnioGuardado("stateAnioSelect", response));
    
                    if (idSelected !== undefined) {
                        yearSelectChange(yearSelected);
                    }
                }
                
                dispatch(setAnioGuardado("Loading", false));
            });
        }
    };

    const tabChanged = (e: string) => {

        dispatch(setAnioGuardado("defaultActiveTab", e));
    };

    const yearData = async (value: number) => {

        if (value > 0) {

            let currentYearData = await getObjetivoAnual(value);

            if (currentYearData !== undefined) {
                
                dispatch(setAnioGuardado("idAnio", currentYearData.anio.id));
                dispatch(setEstructuraGuardado("anioIdGuardar", currentYearData.anio.id));
                dispatch(setTipoGuardado("anioIdGuardar", currentYearData.anio.id));

                if (currentYearData.ficheroEstructura !== null) {
                    dispatch(setEstructuraGuardado("idFicheroGuardar", currentYearData.ficheroEstructura.id));
                    dispatch(setEstructuraGuardado("nombreFichero", currentYearData.ficheroEstructura.nombreFichero));
                } else {
                    dispatch(setEstructuraGuardado("idFicheroGuardar", 0));
                    dispatch(setEstructuraGuardado("nombreFichero", ""));
                }

                if (currentYearData.ficheroObjetivosOficina !== null) {
                    dispatch(setTipoGuardado("idFicheroGuardar", currentYearData.ficheroObjetivosOficina.id));
                    dispatch(setTipoGuardado("nombreFichero", currentYearData.ficheroObjetivosOficina.nombreFichero));
                } else {
                    dispatch(setTipoGuardado("idFicheroGuardar", 0));
                    dispatch(setTipoGuardado("nombreFichero", ""));
                }
                dispatch(setAnioGuardado("valorAnio", value));
                dispatch(setAnioGuardado("datosCargaLoaded", currentYearData.datosCargaLoaded));
                dispatch(setAnioGuardado("observaciones", currentYearData.anio.observaciones));

            } else {
                dispatch(setAnioGuardado("valorAnio", value));
                dispatch(setEstructuraGuardado("idFicheroGuardar", 0));
                dispatch(setEstructuraGuardado("nombreFichero", ""));
                dispatch(setTipoGuardado("idFicheroGuardar", 0));
                dispatch(setTipoGuardado("nombreFichero", ""));
            }
        }
    };

    const yearSelectChange = async (value: number) => {
        yearData(value);
    };

    // Render
    return (
        <>
            {
                loading ?
                    <LoadingSpinner />
                    :
                    <div className="container"><br />
                        <h3>{typeaccAnio} Año</h3><br />
                        <div className="form-group row">
                            <label className="col-1 col-form-label">Año</label>
                            <div className="col-2">
                                {typeaccAnio === "Nuevo" ? <input type="number" name="ValorAnio" className="form-control"
                                    value={anio.valorAnio} disabled /> :
                                    <select className='form-control' value={anio.valorAnio} onChange={(event) => yearSelectChange(Number(event.target.value))}>
                                        {
                                            stateAnioSelect.map((element: AnioEntity) =>
                                                <option key={element.id} value={element.valorAnio}>{element.valorAnio}</option>
                                            )
                                        }
                                    </select>
                                }
                            </div>
                        </div>
                        <br />
                        <Tabs activeKey={defaultActiveTab} id="anio-tab" className="mb-3"
                            onSelect={(e: any) => tabChanged(e)}>
                            <Tab eventKey="obstab" title="Observaciones">
                                <ObservacionesFormTab />
                            </Tab>
                            <Tab eventKey="estrtab" title="Estructura">
                                <EstructuraFormTab yearData={yearData} />
                            </Tab>
                            <Tab eventKey="tipotab" title="Objetivos Oficinas">
                                <ObjetivosOficinaFormTab yearData={yearData} />
                            </Tab>
                        </Tabs>
                    </div>
            }
        </>
    );

};

export default Anio;