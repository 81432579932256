import { StateFicheroCargaListado, StateFicheroCargaTable, StateFicheroCargaModalContent, LOAD_FICHEROCARGA_TABLE, LOAD_FICHEROCARGA_LISTADO, SET_FICHEROCARGA_MODAL_CONTENT } from "../../types/ranking/ficheroCargaTypes";

export const ficheroCargaTableInitialState: StateFicheroCargaTable = {
    content: [],
};

export const ficheroCargaEnlaceModalInitialState: StateFicheroCargaModalContent = {
    content: {
        id: 0,
        fechaSubida: "",
        anio: 0,
        mes: "",
        estado: "",
        valorAnio: 0,
        fechaInicio: "",
        fechaFin: "",
        isPublicable: false,
        isRechazado: false
    },
};

export const datoCargaDetalleInitialState: StateFicheroCargaListado = {
    anioSelected: 0,
    anioValues: [],
    anualValues: false,
    tipoCargaSelected: "Mensual",
    tipoCargaValues: ["Mensual", "Anual"],
    displayProcesarModal: false,
    displayEnlaceModal: false,
    isLoading: false,
    table: ficheroCargaTableInitialState,
    modalsContent: ficheroCargaEnlaceModalInitialState
};

export function ficheroCargaReducer(state = datoCargaDetalleInitialState, action: any): StateFicheroCargaListado {

    switch (action.type) {

        case LOAD_FICHEROCARGA_LISTADO: {

            return {
                ...state,
                anioSelected: action.payload.anioSelected,
                anioValues : action.payload.anioValues,
                anualValues: action.payload.anualValues,
                tipoCargaSelected: action.payload.tipoCargaSelected,
                displayProcesarModal: action.payload.displayProcesarModal,
                displayEnlaceModal: action.payload.displayEnlaceModal,
                isLoading: action.payload.isLoading,
            } as StateFicheroCargaListado;
        }

        case LOAD_FICHEROCARGA_TABLE:{

            return{
                ...state,
                table: {
                    content: action.payload
                }
            }
        }

        case SET_FICHEROCARGA_MODAL_CONTENT:{

            return{
                ...state,
                modalsContent: {
                    content: action.payload
                }
            }
        }

        default:
            return state;
    }
}