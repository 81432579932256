import { ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import { RootState } from '../../../store';
import { setAnioGuardado, setEstructuraGuardado, setInitAnioGuardado, setTipoGuardado } from '../../../actions/objetivos/anioGuardadoActions';
import { Modal, Button } from 'react-bootstrap';
import { createUpdateFichero } from '../../../services/objetivos/ficheroCargaService';
import { validateFileExtension, formatEuroChar } from './../../../services/communications/commonFuncs';
import { setActionResponse } from '../../../actions/actionResponseActions';
import { requiredHeadersEstructura } from '../../../types/objetivos/estructuraTypes';

export default function EstructuraFormTab({yearData}: {yearData:Function}) {

    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const { anio, datosCargaLoaded, ficheroEstructura, ficheroEstructuraGuardar, loading } = state.objetivos.anioguardado;

    const displayResponseAlert = (isOk: boolean, message: string, isVisible: boolean) => {
        dispatch(setActionResponse("IsOk", isOk));
        dispatch(setActionResponse("ResponseMessage", message));
        dispatch(setActionResponse("IsVisible", isVisible));
    }

    const handleModalHide = () => {
        dispatch(setEstructuraGuardado("displayModal", false));
    };

    const setModalGuardadoEstructura = () => {
        if (ficheroEstructuraGuardar.nombreFichero !== undefined && ficheroEstructuraGuardar.nombreFichero.length > 0) {
            dispatch(setEstructuraGuardado("messageValidation", undefined));
            dispatch(setEstructuraGuardado("displayModal", true));
        } else {
            dispatch(setEstructuraGuardado("messageValidation", "Fichero estructura requerido"));
        }
    };

    const handleGuardarEstructuraOnClick = async () => {
        try {
            handleModalHide();
            dispatch(setAnioGuardado("loading", true));
            let response = await createUpdateFichero(ficheroEstructuraGuardar);
    
              let baseMess = ficheroEstructuraGuardar.id > 0 ? "Modificar" : "Guardar";
              if (response !== undefined) {
                dispatch(setAnioGuardado("loading", false));
                displayResponseAlert(true, baseMess + " estructura " + anio.valorAnio + " realizado con éxito.", true);
                yearData(anio.valorAnio);
              } else {
                dispatch(setAnioGuardado("loading", false));
                displayResponseAlert(false, baseMess + " estructura " + anio.valorAnio + " se ha producido un error.", true);
              }
           } catch (error: any) {
            dispatch(setAnioGuardado("loading", false));
            let errorMessage = error.message;
            displayResponseAlert(false, errorMessage, true);
        }       
    };


    const setGuardadoEstructura = async (e: ChangeEvent<HTMLInputElement>) => {
        let file = e.target.files !== null ? e.target.files[0] : undefined;
        if (file !== undefined) {
            if (validateFileExtension(file)) {
                processFile(file);
            }else{
                clearFileInput();
            } 
        }
    };

    const clearFileInput = () => {
        (document.getElementById('frmEstrucFile') as HTMLFormElement).reset();
        dispatch(setEstructuraGuardado("nombreFicheroGuardar", ""));
    };


    const processFile = (uploadedFile: any) => {

        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(uploadedFile);
        fileReader.onload = (readerData: any) => {

            let workbook = XLSX.read(readerData.target.result, { type: 'buffer' });
            let worksheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[worksheetName];
            let csvData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
            
            try {

                if(csvData == null || csvData == undefined || csvData.length == 0){
                    let err : any = { message: "Error - el archivo no contiene datos" };
                    throw(err);
                }

                let checkHeaders : any = csvData[0];
                let requiredHeaders: Array<string> = requiredHeadersEstructura;

                requiredHeaders.forEach(element => {
                    if(!checkHeaders.hasOwnProperty(element)){
                        let err : any = { message: "Error en el fichero - le falta la columna " + element };
                        throw(err);
                    }
                });

                csvData.map((obj : any, index: number) => {
                    if(obj.Tipo_Estructura === undefined || obj.Tipo_Estructura === null || obj.Tipo_Estructura === "" 
                    || obj.Numero_Estructura === undefined || obj.Numero_Estructura === null || obj.Numero_Estructura === "" 
                    || obj.Descripción_Estructura === undefined || obj.Descripción_Estructura === null || obj.Descripción_Estructura === ""){
                        let err : any = { message: "Error en el fichero - se han encontrado datos vacíos en la línea " + index };
                        throw(err);
                    }
                    
                    obj.Descripción_Estructura = formatEuroChar(obj.Descripción_Estructura.toString());
                });
                
                let processedCsvData = JSON.stringify(csvData);
                dispatch(setEstructuraGuardado("nombreFicheroGuardar", uploadedFile.name));
                dispatch(setEstructuraGuardado("contenidoFicheroGuardar", processedCsvData));
                dispatch(setEstructuraGuardado("tipoFicheroGuardar", "Estructura"));

            } catch (error: any) {
                let errorMessage = error.message;
                displayResponseAlert(false, errorMessage, true);
                clearFileInput();
            }
        }
    }


    return (
        <>
            <Modal show={ficheroEstructuraGuardar.displayModal} onHide={() => handleModalHide} centered>
                <Modal.Header>
                    <Modal.Title>Guardar estructura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>¿Está seguro que quiere guardar el fichero estructura?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-soliss' onClick={handleGuardarEstructuraOnClick}>Aceptar</Button>
                    <Button className='btn btn-soliss' onClick={handleModalHide}>Cancelar</Button>
                </Modal.Footer>
            </Modal>

            <div className="form-group row">
                <div className="col-6">
                    <form id="frmEstrucFile">
                        <label className="form-label">Fichero CSV Estructura</label>
                        <input className="form-control" type="file" accept=".csv" id="estructuraFormFile"
                            onChange={setGuardadoEstructura} />
                    </form>
                </div>
                {ficheroEstructura.nombreFichero !== "" ?
                    <div className="col-12">
                        <p>Fichero actual: <b>{ficheroEstructura.nombreFichero}</b></p>
                    </div> : <></>
                }  
                <div className="col-12">
                    {ficheroEstructuraGuardar.messageValidation !== undefined ? <p className='text-danger'>{ficheroEstructuraGuardar.messageValidation}</p> : <></>}
                </div>
            </div>
            <br />
            <div className="form-group row">
                <div className="col-3">
                    <button className='btn btn-soliss' onClick={() => setModalGuardadoEstructura()} disabled={ficheroEstructuraGuardar.anioId == 0 || (datosCargaLoaded !== undefined && datosCargaLoaded === true)
                    || ficheroEstructuraGuardar.nombreFichero == ""}>
                        Guardar Estructura
                    </button>
                </div>
                {datosCargaLoaded === undefined || datosCargaLoaded === false ? 
                  <div className="col-12">            
                       { ficheroEstructuraGuardar.anioId == 0 ? <p className='text-danger'>Debes dar de alta el año con las Observaciones antes de subir el Fichero CSV Estructura</p> : <></> }
                   </div> : 
                    <div className="col-12">
                        <p className='text-danger'>No puedes subir otro Fichero Estructura ya que existen datos de oficinas asociados al actual</p>
                    </div>
                 }
             
            </div>
        </>

    );

};