

export const validateFileExtension = (uploadedFile: any) => {

    if (uploadedFile.type !== "text/csv") {

        return false;
    }
    return true;
};

export const maxElemFromList = (source: number[]): number => {
	var maxelem = Math.max(...source.map(o => o));

	return maxelem;
};


export const formatEuroChar = (source: string) : any => {
    if (source.includes("\x80")){
        source = source.replace("\x80", "€");
    }
    if (source.includes("\u20AC")){
        source = source.replace("\u20AC", "€");
    }

    return source;
};

export const formatOffice = (source: string) : any => {
  
    if(source.length === 1){
        source = "0" + source;
    }

    return source;
};

export const normalizeCsvHeader = (source: string) : any => {

  source = source.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return source;

};