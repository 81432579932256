import { LOAD_CARGA } from "../../types/ranking/cargaTypes";
import { StateNewCarga } from "../../types/ranking/cargaTypes";
import { LOAD_DATOCARGA_DETALLE, LOAD_DATOCARGA_TABLE, LOAD_DATOCARGA_ACTIONS } from "../../types/ranking/datoCargaTypes";
import { StateDatoCargaDetalle } from "../../types/ranking/datoCargaTypes";
import { DatoCargaEntity } from "../../types/ranking/datoCargaTypes";
import { GrupoCargaEntity } from "../../types/ranking/grupoCargaTypes";
import { StateFicheroCargaListado, LOAD_FICHEROCARGA_LISTADO, LOAD_FICHEROCARGA_TABLE, SET_FICHEROCARGA_MODAL_CONTENT } from "../../types/ranking/ficheroCargaTypes";
import { FicheroCargaTableModel } from "../../types/ranking/ficheroCargaTypes";

export const loadNewCarga = (data: StateNewCarga) => ({ type: LOAD_CARGA, payload: data });

export const loadDatoCargaDetalle = (data: StateDatoCargaDetalle) => ({ type: LOAD_DATOCARGA_DETALLE, payload: data });

export const loadDatoCargaTable = (data: DatoCargaEntity[]) => ({ type: LOAD_DATOCARGA_TABLE, payload: data });

export const loadDatoCargaActions = (data: GrupoCargaEntity[]) => ({ type: LOAD_DATOCARGA_ACTIONS, payload: data });

export const loadFicheroCargaListado = (data: StateFicheroCargaListado) => ({ type: LOAD_FICHEROCARGA_LISTADO, payload: data });

export const setFicheroCargaModalContent = (data: FicheroCargaTableModel) => ({ type: SET_FICHEROCARGA_MODAL_CONTENT, payload: data });

export const loadFicheroCargaTable = (data: FicheroCargaTableModel[]) => ({ type: LOAD_FICHEROCARGA_TABLE, payload: data });