import { UserType } from '../../types/users/userTypes';
import { ROL_RANK_SUPERVISOR, ROL_RANK_CARGA, ROL_RANK_VISUALIZADOR } from '../../types/users/rolesTypes';
import { Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NavBarObjetivos = ({ user }: { user: UserType }) => {
    return (
        <>
            <Nav className="me-auto">
                <a className="navbar-brand" href="#">Objetivos</a>
                {
                    user.roles.includes(ROL_RANK_CARGA) ?
                        <Link to="/objetivos/carga/Listado" className="nav-link" reloadDocument>Cargas</Link> : ""
                }
                {
                    user.roles.includes(ROL_RANK_SUPERVISOR) ?
                        <NavDropdown title="Supervisión" id="basic-nav-dropdownrech2">
                            <Link to={`/objetivos/supervision/${"Nuevo"}`} className="nav-link"> Nuevo Año</Link>
                            <Link to={`/objetivos/supervision/${"Editar"}`} className="nav-link"> Editar Años</Link>
                            <Link to={`/objetivos/supervision/Listado`} className="nav-link"> Listado</Link>
                        </NavDropdown> : ""
                }
                {
                    user.roles.includes(ROL_RANK_VISUALIZADOR) ?
                        <Link to={`/objetivos/visualizacion/Detalle/${user.numPC}`} className="nav-link" reloadDocument>Visualización</Link> : ""
                }
            </Nav>
        </>
    );
};

export default NavBarObjetivos;