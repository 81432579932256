import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faPencil } from '@fortawesome/free-solid-svg-icons';
import { loadDetalleOficina, loadListadoOficinas } from '../../../actions/objetivos/listadoOficinasActions';
import { getOficinasByNumPC } from '../../../services/objetivos/oficinaService';
import { OficinaModel } from '../../../types/objetivos/oficinaTypes';

export default function OficinaActions(oficina: any) {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state);
    const { tableData, modal, anioIdSelected } = state.objetivos.listadoOficinas

    // Methods

    const handleButtonOnClick = async (editable: boolean) => {

        dispatch(loadListadoOficinas({
            ...state.objetivos.listadoOficinas,
            isLoading: true
        }));

        let oficinaData = await fetchOficinaData(oficina.data.numPC, anioIdSelected);

        dispatch(loadDetalleOficina({
            ...state.objetivos.listadoOficinas,
            modal: {
                ...modal,
                title: `Objetivos y condicionantes asociados a: ${oficinaData?.nombreAgrupado}`,
                oficina: oficinaData,
                editable: editable,
                display: true
            },
        }));

        dispatch(loadListadoOficinas({
            ...state.objetivos.listadoOficinas,
            isLoading: false
        }));
    };

    const fetchOficinaData = async (numPC: string | undefined, anioId: number) => {

        return await getOficinasByNumPC(numPC, anioId, 2);
    }

    // Render

    return (
        <div style={{ textAlign: "center" }}>
            <Button title="Detalle" name="detalleButton" id={`detalleButtonId_${oficina.data.numPC}`} className="btn-soliss" size="sm" onClick={() => handleButtonOnClick(false)} style={{ marginRight: '3px' }}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </Button>
            <Button title="Editar" name="editarButton" id={`editarButton_${oficina.data.numPC}`} className="btn-soliss" size="sm" onClick={() => handleButtonOnClick(true)}>
                <FontAwesomeIcon icon={faPencil} />
            </Button>
        </div>
    );
};
