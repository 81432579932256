import { LOAD_LISTADO_OFICINAS, LOAD_MODAL_DETALLE_OFICINA, StateListadoOficina } from "../../types/objetivos/oficinaTypes";

export const listadoOficinasInitialState: StateListadoOficina = {
    anioValues: [],
    anioIdSelected: 0,
    anioObservacionesSelected: '',
    tableData: [],
    modal: {
        title: '',
        oficina : undefined,
        display: false,
        editable: false,
        editedValues: []
    },
    isLoading: true
};

export function listadoOficinasReducer(state = listadoOficinasInitialState, action: any): StateListadoOficina {
    switch (action.type) {

        case LOAD_LISTADO_OFICINAS: {

            return {
                ...state,
                anioValues: action.payload.anioValues,
                anioIdSelected: action.payload.anioIdSelected,
                anioObservacionesSelected: action.payload.anioObservacionesSelected,
                tableData: action.payload.tableData,
                isLoading: action.payload.isLoading
            } as StateListadoOficina;
        }

        case LOAD_MODAL_DETALLE_OFICINA: {

            return {
                ...state,
                modal: action.payload.modal
            } as StateListadoOficina;
        }

        default:
            return state;
    }
}