import { ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import { RootState } from '../../../store';
import { setAnioGuardado, setTipoGuardado } from '../../../actions/objetivos/anioGuardadoActions';
import { Modal, Button } from 'react-bootstrap';
import { createUpdateFichero } from '../../../services/objetivos/ficheroCargaService';
import { validateFileExtension, formatEuroChar, formatOffice, normalizeCsvHeader } from '../../../services/communications/commonFuncs';
import { setActionResponse } from '../../../actions/actionResponseActions';
import { ObjetivoOficinaJson, requiredHeadersObjetivoOficina } from '../../../types/objetivos/oficinaTypes';

export default function ObjetivosOficinaFormTab({yearData}: {yearData:Function}) {

    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const { anio, datosCargaLoaded, ficheroObjetivosOficina, ficheroObjetivosOficinaGuardar, loading } = state.objetivos.anioguardado;

    const displayResponseAlert = (isOk: boolean, message: string, isVisible: boolean) => {
        dispatch(setActionResponse("IsOk", isOk));
        dispatch(setActionResponse("ResponseMessage", message));
        dispatch(setActionResponse("IsVisible", isVisible));
    };

    const handleModalHide = () => {
        dispatch(setTipoGuardado("displayModal", false));
    };

    const setModalGuardadoTipo = () => {
        if (ficheroObjetivosOficinaGuardar.nombreFichero !== undefined && ficheroObjetivosOficinaGuardar.nombreFichero.length > 0) {
            dispatch(setTipoGuardado("messageValidation", undefined));
            dispatch(setTipoGuardado("displayModal", true));
        } else {
            dispatch(setTipoGuardado("messageValidation", "Fichero tipo requerido"));
        }
    };

    const handleGuardarTipoOnClick = async () => {
        try {
            handleModalHide();
            dispatch(setAnioGuardado("loading", true));
                
            let responseObj = await createUpdateFichero(ficheroObjetivosOficinaGuardar);
    
              let baseMess = ficheroObjetivosOficinaGuardar.id > 0 ? "Modificar" : "Guardar";
              if (responseObj !== undefined) {
                dispatch(setAnioGuardado("loading", false));
                displayResponseAlert(true, baseMess + " Objetivos Oficina " + anio.valorAnio + " realizado con éxito.", true);
                yearData(anio.valorAnio);
              } else {
                dispatch(setAnioGuardado("loading", false));
                displayResponseAlert(false, baseMess + " Objetivos Oficina " + anio.valorAnio + " se ha producido un error.", true);
              }
        }catch (error: any) {
            dispatch(setAnioGuardado("loading", false));
            let errorMessage = error.message;
            displayResponseAlert(false, errorMessage, true);
        }           
    };

    
    const setGuardadoTipo = async (e: ChangeEvent<HTMLInputElement>) => {
        let file = e.target.files !== null ? e.target.files[0] : undefined;
        if (file !== undefined) {
            if (validateFileExtension(file)) {
                processFile(file);
            } else {
                clearFileInput();
            }
        }
    };

    const clearFileInput = () => {
        (document.getElementById('frmTipoFile') as HTMLFormElement).reset();
        dispatch(setTipoGuardado("nombreFicheroGuardar", ""));
    };

    
    const processFile = (uploadedFile: any) => {

        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(uploadedFile);
        fileReader.onload = (readerData: any) => {

            let workbook = XLSX.read(readerData.target.result, { type: 'buffer' });
            let worksheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[worksheetName];
            let csvData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
            let jsonToSave : ObjetivoOficinaJson[] = [];
            
            try {

                if(csvData == null || csvData == undefined || csvData.length == 0){
                    let err : any = { message: "Error - el archivo no contiene datos" };
                    throw(err);
                }

                let checkHeaders : any = csvData[0];
                let formattedHeaders : Array<string> = [];
                let requiredHeaders: Array<string> = requiredHeadersObjetivoOficina;

                Object.entries(checkHeaders).forEach(([key, value]) => {
                    formattedHeaders.push(normalizeCsvHeader(key))
                });      
                formattedHeaders.forEach((element: string) => {               
                    if(!requiredHeaders.some(item => element == item)){
                        let err : any = { message: "Error en el fichero - le falta la columna " + element };
                        throw(err);
                    }
                });
                
                csvData.map((obj: any, index: number) => {

                    let item: ObjetivoOficinaJson = {
                        grupo: "",
                        oficina: "",
                        tipo_estructura: "",
                        numero_estructura: 0,
                        tipo_objetivo: "",
                        valor: ""
                    };

                    Object.entries(obj).forEach(([key, value]) =>  {

                      if (obj[key] === undefined || obj[key] === null || obj[key] === ""){
                        let err : any = { message: "Error en el fichero - se han encontrado datos vacíos en la línea " + index };
                        throw(err);
                      }
                      else {
                        if (normalizeCsvHeader(key).includes(requiredHeaders[0])){
                           item.grupo = formatOffice(obj[key].toString());
                        }
                        else if (normalizeCsvHeader(key).includes(requiredHeaders[1])){
                            item.oficina =  formatOffice(obj[key].toString());
                        }
                        else if (normalizeCsvHeader(key).includes(requiredHeaders[2])){
                            item.tipo_estructura = obj[key].toString();
                        }
                        else if (normalizeCsvHeader(key).includes(requiredHeaders[3])){
                            item.numero_estructura = obj[key].toString();
                        }
                        else if(normalizeCsvHeader(key).includes(requiredHeaders[4])){
                            item.tipo_objetivo = obj[key].toString();
                        }
                        else if (normalizeCsvHeader(key).includes(requiredHeaders[5])){
                            item.valor = formatEuroChar(obj[key].toString());
                        }              
                      }
                    });
                    jsonToSave.push(item);
                });       
                
                let processedCsvData = JSON.stringify(jsonToSave);
                dispatch(setTipoGuardado("nombreFicheroGuardar", uploadedFile.name));
                dispatch(setTipoGuardado("contenidoFicheroGuardar", processedCsvData));
                dispatch(setTipoGuardado("tipoFicheroGuardar", "ObjetivosOficina"));

            } catch (error: any) {
                let errorMessage = error.message;
                displayResponseAlert(false, errorMessage, true);
                clearFileInput();
            }
        }
    }


    return (
        <>
            <Modal show={ficheroObjetivosOficinaGuardar.displayModal} onHide={() => handleModalHide} centered>
                <Modal.Header>
                    <Modal.Title>Guardar estructura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>¿Está seguro que quiere guardar el fichero objetivos oficinas?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-soliss' onClick={handleGuardarTipoOnClick}>Aceptar</Button>
                    <Button className='btn btn-soliss' onClick={handleModalHide}>Cancelar</Button>
                </Modal.Footer>
            </Modal>

            <div className="form-group row">
                <div className="col-6">
                    <form id="frmTipoFile">
                        <label className="form-label">Fichero CSV Objetivos Oficinas</label>
                        <input className="form-control" type="file" accept=".csv" id="tipoFormFile" onChange={setGuardadoTipo} />
                    </form>
                </div>
                {ficheroObjetivosOficina.nombreFichero !== "" ?
                    <div className="col-12">
                        <p>Fichero actual: <b>{ficheroObjetivosOficina.nombreFichero}</b></p>
                    </div> : <></>
                }       
                <div className="col-12">
                    {ficheroObjetivosOficinaGuardar.messageValidation !== undefined ? <p className='text-danger'>{ficheroObjetivosOficinaGuardar.messageValidation}</p> : <></>}
                </div>
            </div>
            <br />
            <div className="form-group row">
                <div className="col-3">
                    <button className='btn btn-soliss' onClick={() => setModalGuardadoTipo()} disabled={ficheroObjetivosOficinaGuardar.anioId == 0 // || (datosCargaLoaded !== undefined && datosCargaLoaded === true)
                    || ficheroObjetivosOficinaGuardar.nombreFichero == ""}>
                        Guardar Objetivos
                    </button>
                </div>
                {datosCargaLoaded === undefined || datosCargaLoaded === false ? 
                  <div className="col-12">            
                       { ficheroObjetivosOficinaGuardar.anioId == 0 ? <p className='text-danger'>Debes dar de alta el año con las Observaciones antes de subir el Fichero CSV Objetivos Oficinas</p> : <></> }
                   </div> : 
                    <></>
                 }
            </div>
        </>

    );

};