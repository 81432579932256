export const LOAD_CARGA = "LOAD_CARGA";
export const SAVE_CARGA = "SAVE_CARGA";
export const LOAD_LISTADO = "LOAD_LISTADO";

export type IFicheroItem = {
    ficheroNombre: string;
    ficheroContenido: string;
};

export type StateNewCarga = {
    tipoCargaSelected: string,
    tipoCargaValues: string[];
    anioSelected: number,
    anioValues: number[];
    fechaInicio: string;
    fechaFin: string;
    periodoSelected: string;
    periodoValues: string[];
    fichero: IFicheroItem;
    displayModal: boolean;
    sended: boolean;
    isLoading: boolean;
};
export type IListadoCargaItem = {
    id: number,
    fechaInicio: string,
    fechaFin: string,
    estadoNombre: string,
    observaciones_Unificadas: string
}
export type StateListadoCarga = {
    anioValues: number[],
    isAnual: boolean,
    values: IListadoCargaItem[],
    isLoading: boolean,
    anioSelected: number
};