import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { Tabs, Tab, Container, Col, Row, Form } from 'react-bootstrap';
import { format } from 'date-fns';
import { getAllAnio } from '../../../services/ranking/anioService';
import { getPeriodosFicheroCargaEstado, getTipoCargaGrupos } from '../../../services/ranking/cargaService';
import { AnioEntity, PeriodoEntity } from '../../../types/ranking/anioTypes';
import { IOfficeItem, UrlParams } from '../../../types/ranking/rankingTypes';
import { loadRankingOptions, loadOffices, loadRankingResponse } from '../../../actions/ranking/rankingActions';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import ConsultarBasesModal from '../../../components/ranking/visualizacion/ConsultarBasesModal';

const Ranking = () => {

    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const { rankingselect, officeItemsA, officeItemsB, officeItemsC, isLoading } = state.ranking.podium;
    const { anio, tipo, periodo } = useParams<UrlParams>();

    let anioDefault = 0;
    let tipoDefault = 0;
    let periodoDefault = '';

    // Methods

    const fetchAnios = async () => {

        dispatch(loadRankingResponse("isLoading", true));

        let response = await getAllAnio();

        let aniosList: number[] = [];

        response.sort((a: AnioEntity, b: AnioEntity) => (b.valorAnio - a.valorAnio));
        response.forEach(element => {
            aniosList.push(element.valorAnio)
        });

        dispatch(loadRankingOptions("SelectAnios", response));
        dispatch(loadRankingOptions("ValorAnio", getCurrentYear(aniosList)))
        dispatch(loadRankingResponse("isLoading", false));
    }

    const fetchPeriodos = async (anio: number, tipoCarga: number) => {

        dispatch(loadRankingResponse("isLoading", true));

        let response = await getPeriodosFicheroCargaEstado(anio, tipoCarga);

        if (tipoCarga === 1) {
           
            dispatch(loadRankingOptions("SelectPeriodo", response));

            let [mes, anio] = periodoDefault.split("/").map(Number);
            let fecha = new Date(anio, mes, 1);

            if(anio === undefined) {

                if(response.length > 0){
                    fecha = new Date(response[response.length-1].fechaInicio)
                }
            }

            let selectFecha = formatDate(fecha, false);
            const index = response.findIndex(x => formatDate(new Date(x.fechaInicio), false) === selectFecha);

            if (index >= 0) {

                dispatch(loadRankingOptions("FechaInicio", selectFecha));
                if (periodoDefault !== "") {
                    fetchTipoCargaGrupos(rankingselect.ValorAnio, tipoCarga, periodoDefault, "");
                } else {
                    fetchTipoCargaGrupos(rankingselect.ValorAnio, tipoCarga, selectFecha, "");
                }

            }

            dispatch(loadRankingOptions("FechaFin", ""));
        }

        if (tipoCarga === 2 && response.length > 0) {

            dispatch(loadRankingOptions("FechaInicio", formatDate(new Date(response[0].fechaInicio), false)));
            dispatch(loadRankingOptions("FechaFin", formatDate(new Date(response[0].fechaFin), false)));
        }

        dispatch(loadRankingResponse("isLoading", false));
    }

    const fetchTipoCargaGrupos = async (anio: number, tipoCarga: number, fechaInicio: string, fechaFin: string) => {

        if(fechaInicio !== "") {

            dispatch(loadRankingResponse("isLoading", true));

            let response = await getTipoCargaGrupos(anio, tipoCarga, fechaInicio, fechaFin);
    
            if (response !== undefined) {
    
                dispatch(loadOffices("A", response.grupoAResp.items));
                dispatch(loadOffices("B", response.grupoBResp.items));
                dispatch(loadOffices("C", response.grupoCResp.items));
    
            } else {
    
                clearOffices();
            }
    
            dispatch(loadRankingResponse("isLoading", false));
        }
    }

    const getCurrentYear = (aniosList: number[]) => {

        let anio: number = 0;

        if (aniosList.length > 0) {

            if (rankingselect.ValorAnio === 0) {

                let currentYear = new Date().getFullYear();

                if (!aniosList.find(year => year === currentYear)) {

                    anio = aniosList[aniosList.length - 1];

                } else {

                    anio = currentYear;
                }

            } else {

                anio = rankingselect.ValorAnio;
            }
        }

        return anio;
    };

    const formatDate = (inputDate: Date, spainFormat: boolean) => {

        let date, month, year;

        date = inputDate.getDate();
        month = inputDate.getMonth() + 1;
        year = inputDate.getFullYear();

        date = date.toString().padStart(2, '0');
        month = month.toString().padStart(2, '0');

        if (spainFormat) {
            return `${date}/${month}/${year}`;
        } else {
            return `${year}/${month}/${date}`;
        }
    }

    const clearOffices = () => {
        dispatch(loadOffices("A", []));
        dispatch(loadOffices("B", []));
        dispatch(loadOffices("C", []));
    };

    const handleAnioSelectOnChange = (event: any) => {

        dispatch(loadRankingOptions("ValorAnio", Number(event.target.value)))
    };

    const handleTipoCargaSelectOnChange = async (event: any) => {

        let tipoCarga: number = Number(event.target.value);

        dispatch(loadRankingOptions("TipoCarga", tipoCarga))
        await fetchPeriodos(rankingselect.ValorAnio, tipoCarga);

        clearOffices();
    };

    const periodoSelectChange = (value: string) => {

        if (value !== "") {

            if (rankingselect.TipoCarga === 1) {

                dispatch(loadRankingOptions("FechaInicio", value));
                fetchTipoCargaGrupos(rankingselect.ValorAnio, rankingselect.TipoCarga, value, "");
            }
        }
    };

    const getParams = () => {

        if (anio !== undefined && tipo !== undefined && periodo !== undefined) {
            anioDefault = parseInt(anio);
            tipoDefault = parseInt(tipo);
            let periodoSplitted = periodo.split("_");
            if (periodoSplitted.length === 2) {
                periodoDefault = periodoSplitted[1] + "/" + periodoSplitted[0] + "/01";

                if (anioDefault > 0 && tipoDefault > 0 && periodoDefault !== '') {
                    if (tipoDefault === 1) {
                        fetchTipoCargaGrupos(anioDefault, tipoDefault, periodoDefault, "");
                    } else {
                        let anioInicio = periodoSplitted[0] === "12" ? anioDefault.toString() : (anioDefault - 1).toString();
                        let inicioPeriodo = anioInicio + "/12/01";
                        fetchTipoCargaGrupos(anioDefault, tipoDefault, inicioPeriodo, periodoDefault);
                    }
                }
            }
        }
    };

    // useEffect

    useEffect(() => {
        
        getParams();

    }, []);

    useEffect(() => {

        fetchAnios().then(res => {

            fetchPeriodos(rankingselect.ValorAnio, rankingselect.TipoCarga);
        });

        if (rankingselect.TipoCarga === 2) {

            dispatch(loadRankingOptions("FechaFin", ""));
        }

        clearOffices();

    }, [rankingselect.ValorAnio]);

    useEffect(() => {

        clearOffices();
        if (rankingselect.TipoCarga === 2) {

            dispatch(loadRankingOptions("FechaInicio", ""));

            if (rankingselect.FechaInicio !== "") {

                fetchTipoCargaGrupos(rankingselect.ValorAnio, rankingselect.TipoCarga, rankingselect.FechaInicio, rankingselect.FechaFin);
            }
        } else {

            if (rankingselect.FechaInicio !== "") {

                fetchTipoCargaGrupos(rankingselect.ValorAnio, rankingselect.TipoCarga, rankingselect.FechaInicio, "");
            }
        }

    }, [rankingselect.FechaInicio, rankingselect.FechaFin]);

    // Render

    return (
        !isLoading ? <>
            <Container>
                <br />
                {anio === undefined && tipo === undefined && periodo === undefined ?
                    <>
                        <h3>Ranking</h3>
                        <hr />
                        <Row className="align-items-center mb-3">
                            <Col md={1} className="text-center">
                                <label>Año:</label>
                            </Col>
                            <Col md={2}>
                                <Form.Group>
                                    <Form.Select id="slcAnios" onChange={handleAnioSelectOnChange} value={rankingselect.ValorAnio}>
                                        {
                                            rankingselect.SelectAnios.map((element: AnioEntity) =>
                                                <option key={"opyear_" + element.id} value={element.valorAnio}>{element.valorAnio}</option>
                                            )
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={1} className="text-center">
                                <label>Tipo:</label>
                            </Col>
                            <Col md={2}>
                                <Form.Group>
                                    <Form.Select id="slcTipo" onChange={handleTipoCargaSelectOnChange} value={rankingselect.TipoCarga}>
                                        <option key="opyear_1" value="1">Mensual</option>
                                        <option key="opyear_2" value="2">Anual</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={1} className="text-center">
                                <label>Período:</label>
                            </Col>
                            <Col md={2}>
                                {
                                    rankingselect.TipoCarga === 1 ?
                                        <Form.Group>
                                            <Form.Select id="slcPeriodo" onChange={(event) => periodoSelectChange(event.target.value)} value={rankingselect.FechaInicio}>
                                                
                                                {
                                                    rankingselect.SelectPeriodo.map((elem: PeriodoEntity, i) => {
                                                        return <option key={"op_" + i} value={format(new Date(elem.fechaInicio), "yyyy/MM/dd")}>
                                                            {format(new Date(elem.fechaInicio), "MM/yyyy")}
                                                        </option>
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                        :
                                        <input type="text" className="form-control" value={rankingselect.FechaFin !== "" ? format(new Date(rankingselect.FechaFin), "MM/yyyy") : ""} disabled />
                                }
                            </Col>
                        </Row>
                        <ConsultarBasesModal />
                    </>
                    :
                    <Row className="align-items-center mb-3">
                        <Col md={6}>
                            <h3>
                                {tipo === "1" ? "Ranking mensual período " + periodo?.replace("_", "/") : "Ranking anual período " + periodo?.replace("_", "/")}
                            </h3>
                        </Col>
                        <hr />
                    </Row>
                }

                <br />
                {(officeItemsA.length > 0 && officeItemsB.length > 0 && officeItemsC.length > 0) ?
                    <Tabs defaultActiveKey="rA" id="bases-tab" className="mb-3">
                        <Tab eventKey="rA" title="Grupo A">
                            <br />
                            <div className='row align-items-end text-center'>
                                <div className='col-4'>
                                    {
                                        officeItemsA.filter(u => u.posicionRanking === 2).map((elem: IOfficeItem, index) => {
                                            return <h5 key={"officeA2" + index} style={{ marginLeft: "8px" }}><strong>{elem.numPC}</strong></h5>
                                        })
                                    }
                                </div>
                                <div className='col-4'>
                                    {
                                        officeItemsA.filter(u => u.posicionRanking === 1).map((elem: IOfficeItem, index) => {
                                            return <h4 key={"officeA1" + index}><strong>{elem.numPC}</strong></h4>
                                        })
                                    }
                                </div>
                                <div className='col-4'>
                                    {
                                        officeItemsA.filter(u => u.posicionRanking === 3).map((elem: IOfficeItem, index) => {
                                            return <h5 key={"officeA3" + index} style={{ marginRight: "5px" }}><strong>{elem.numPC}</strong></h5>
                                        })
                                    }
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="rB" title="Grupo B">
                            <br />
                            <div className='row align-items-end text-center'>
                                <div className='col-4'>
                                    {
                                        officeItemsB.filter(u => u.posicionRanking === 2).map((elem: IOfficeItem, index) => {
                                            return <h5 key={"officeB2" + index} style={{ marginLeft: "8px" }}><strong>{elem.numPC}</strong></h5>
                                        })
                                    }
                                </div>
                                <div className='col-4'>
                                    {
                                        officeItemsB.filter(u => u.posicionRanking === 1).map((elem: IOfficeItem, index) => {
                                            return <h4 key={"officeB1" + index}><strong>{elem.numPC}</strong></h4>
                                        })
                                    }
                                </div>
                                <div className='col-4'>
                                    {
                                        officeItemsB.filter(u => u.posicionRanking === 3).map((elem: IOfficeItem, index) => {
                                            return <h5 key={"officeB3" + index} style={{ marginRight: "5px" }}><strong>{elem.numPC}</strong></h5>
                                        })
                                    }
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="rC" title="Grupo C">
                            <br />
                            <div className='row align-items-center text-center'>
                                <div className='col-4'>
                                    {
                                        officeItemsC.filter(u => u.posicionRanking === 2).map((elem: IOfficeItem, index) => {
                                            return <h5 key={"officeC2" + index} style={{ marginLeft: "8px" }}><strong>{elem.numPC}</strong></h5>
                                        })
                                    }
                                </div>
                                <div className='col-4'>
                                    {
                                        officeItemsC.filter(u => u.posicionRanking === 1).map((elem: IOfficeItem, index) => {
                                            return <h4 key={"officeC1" + index}><strong>{elem.numPC}</strong></h4>
                                        })
                                    }
                                </div>
                                <div className='col-4'>
                                    {
                                        officeItemsC.filter(u => u.posicionRanking === 3).map((elem: IOfficeItem, index) => {
                                            return <h5 key={"officeC3" + index} style={{ marginRight: "5px" }}><strong>{elem.numPC}</strong></h5>
                                        })
                                    }
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                    : !isLoading ? <div className='row'>
                    <div className='col-9'>
                        <br />
                        <div className="alert alert-info" role="alert">
                            <h5 className="alert-heading">Información: </h5>
                            <p>No hay datos de Ranking disponibles para el Año y Tipo seleccionados.</p>
                        </div>
                    </div>
                </div> : ''
                    
                }
                {
                    officeItemsA.length > 0 && officeItemsB.length > 0 && officeItemsC.length > 0 ?

                        <div className='row' style={{ zIndex: "1000" }}>
                            <div className='col-12 align-self-end'>
                                <img src={process.env.PUBLIC_URL + '/soliss/soliss_img_podio.png'} alt="Podio" className="podImg" />
                                <br /><br />
                            </div>
                        </div>
                        : ''
                }
            </Container>
        </> : <LoadingSpinner />
    );

};

export default Ranking;