import { AnioEntity } from '../../types/objetivos/anioTypes';
import { FicheroCargaEntity } from '../../types/objetivos/ficheroCargaTypes';

export const INIT_NEW_CARGA = "INIT_NEW_CARGA";
export const SET_NEW_CARGA = "SET_NEW_CARGA";

export type StateNewCarga = {
    anioSelected: AnioEntity,
    anioValues: AnioEntity[];
    ficheroObjetivosOficina: FicheroCargaEntity,
    displayModal: boolean;
    sended: boolean;
    isLoading: boolean;
};