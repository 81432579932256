import { Route, Routes } from 'react-router-dom';
import Error from '../../pages/Error';
import InitialSwitch from '../../pages/InitialSwitch';
import ErrorComponent from '../common/Error';
import { UserType } from '../../types/users/userTypes';

export default function MenuRoutesBase({ user }: { user: UserType }) {

    return (
        <Routes>
            <Route path="/" element={<InitialSwitch user={user} />} />
            <Route path="/error/:type" element={<Error />} />
            <Route path="*" element={<ErrorComponent errorType={404} />} />
        </Routes>
    );
};