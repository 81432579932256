import { GrupoCargaEntity } from "./grupoCargaTypes";

export const LOAD_DATOCARGA_DETALLE = "LOAD_DATOCARGA_DETALLE";
export const LOAD_DATOCARGA_TABLE = "LOAD_DATOCARGA_TABLE";
export const LOAD_DATOCARGA_ACTIONS = "LOAD_DATOCARGA_ACTIONS";

export type DatoCargaEntity = {
    id: number,
    idGrupoCarga: number,
    numPC: string,
    puntosParticulares: number,
    puntosEmpresa: number,
    puntosTotales: number,
    posicionRanking: number
};

export type StateDatoCargaTable = {
    content: DatoCargaEntity[],
};

export type BaseDatoCarga = {
    items: DatoCargaEntity[],
    totalCount: number
};

export type DatoCargaGruposEntity = {
    grupoAResp: BaseDatoCarga,
    grupoBResp: BaseDatoCarga,
    grupoCResp: BaseDatoCarga
};

export type StateDatoCargaDetalle = {
    idGrupoCargaSelected: number,
    idFicheroCarga: number,
    observaciones: string,
    evaluacion: boolean,
    valorAnio: number,
    fechaInicio: string;
    fechaFin: string;
    isActionable: boolean;
    isLoading: boolean;
    displayModal: boolean;
    grupoCargaList: GrupoCargaEntity[],
    table: StateDatoCargaTable
};