import { LOAD_LISTADO, StateListadoCarga } from "../../types/ranking/cargaTypes";

export const newCargaInitialState: StateListadoCarga = {
    anioValues: [],
    anioSelected: 0 ,
    isAnual: true,
    values: [],
    isLoading: false,
};
export function listadoCargaReducer(state = newCargaInitialState, action: any): StateListadoCarga {
    switch (action.type) {

        case LOAD_LISTADO: {
            return {
                ...state,
                anioSelected: action.payload.anioSelected,
                anioValues: action.payload.anioValues,
                isAnual: action.payload.isAnual,
                values: action.payload.values,
                isLoading: action.payload.isLoading
            } as StateListadoCarga;
        }

        default:
            return state;
    }
}