import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Container } from 'react-bootstrap';
import { RootState } from '../../../store';
import { loadDetalleOficina, loadListadoOficinas } from '../../../actions/objetivos/listadoOficinasActions';
import { setActionResponse } from '../../../actions/actionResponseActions';
import { DatoCargaDTO } from '../../../types/objetivos/oficinaTypes';
import { updateValor } from '../../../services/objetivos/datoCargaService';
import DetalleOficina from '../oficina/DetalleOficina';


export default function DetalleOficinaModal() {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state);
    const { modal, anioObservacionesSelected, isLoading } = state.objetivos.listadoOficinas;
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    // Methods

    const handleModalHide = () => {

        if (!isLoading) {

            dispatch(loadDetalleOficina({
                ...state.objetivos.listadoOficinas,
                modal: {
                    ...modal,
                    display: false
                },
            }));
        }
    };

    const handleValorInputOnChange = async (event: any) => {

        let edited: DatoCargaDTO[] = [];

        modal.editedValues.forEach(datoCarga => {
            edited.push(datoCarga);
        });

        let datoCargaId: number = parseInt(event.target.id.toString().split("valorinputid_")[1]);
        let valor: string = event.target.value;
        let originalValor = document.getElementById(event.target.id?.toString())?.getAttribute("value");

        if (originalValor !== valor) {

            if (edited.length === 0) {

                edited.push({
                    id: datoCargaId,
                    grupo: '',
                    numPC: '',
                    estructuraId: 0,
                    tipoObjetivo: '',
                    valor: valor
                });

            } else {

                let datoCarga = edited.findIndex(dc => dc.id === datoCargaId);

                if (datoCarga !== -1) {

                    edited.splice(datoCarga, 1);
                }

                edited.push({
                    id: datoCargaId,
                    grupo: '',
                    numPC: '',
                    estructuraId: 0,
                    tipoObjetivo: '',
                    valor: valor
                });
            }
        }

        dispatch(loadDetalleOficina({
            ...state.objetivos.listadoOficinas,
            modal: {
                ...modal,
                editedValues: edited
            },
        }));
    };

    const handleConfirmationModalContinuarOnClick = async (event: any) => {

        setDisplayConfirmationModal(false)

        dispatch(loadListadoOficinas({
            ...state.objetivos.listadoOficinas,
            isLoading: true
        }));

        let response = await updateValor(modal.editedValues);

        dispatch(loadListadoOficinas({
            ...state.objetivos.listadoOficinas,
            isLoading: false
        }));

        if (response !== null) {

            displayResponseAlert(true, "Valores actualizados", true);

        } else {

            displayResponseAlert(false, "Ha ocurrido un error al intentar realizar la acción", true);
        }
    };

    const displayResponseAlert = (isOk: boolean, message: string, isVisible: boolean) => {

        dispatch(setActionResponse("IsOk", isOk));
        dispatch(setActionResponse("ResponseMessage", message));
        dispatch(setActionResponse("IsVisible", isVisible));
    }

    return (
        <>
            <Modal show={modal.display} size="lg" onHide={handleModalHide} backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {<DetalleOficina oficina={modal.oficina} editable={modal.editable} handleValorInputOnChange={handleValorInputOnChange} />}
                    <h3>Observaciones</h3>
                    <hr />
                    <Container>
                        <span dangerouslySetInnerHTML={{ __html: anioObservacionesSelected }} />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        modal.editable ?
                            <Button className='btn btn-soliss' disabled={modal.editedValues.length === 0 || isLoading} onClick={() => setDisplayConfirmationModal(true)}>Guardar</Button> : ''
                    }
                    <Button className='btn btn-soliss' disabled={isLoading} onClick={handleModalHide}>Cancelar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={displayConfirmationModal} onHide={() => setDisplayConfirmationModal(false)} backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>¡Advertencia!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Ha modificado algún valor ¿desea continuar y guardar los cambios?</Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-soliss' onClick={handleConfirmationModalContinuarOnClick}>Continuar</Button>
                    <Button className='btn btn-soliss' onClick={() => setDisplayConfirmationModal(false)}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
