import { AnioEntity } from '../../types/objetivos/anioTypes';
import { FicheroCargaEntity } from '../../types/objetivos/ficheroCargaTypes';

export const INIT_ANIO_GUARDADO = "INIT_ANIO_GUARDADO";
export const SET_ANIO_GUARDADO = "SET_ANIO_GUARDADO";
export const SET_ESTRUCTURA_GUARDADO = "SET_ESTRUCTURA_GUARDADO";
export const SET_TIPO_GUARDADO = "SET_TIPO_GUARDADO";

export type StateAnioGuardado = {
     anio: AnioEntity,
     stateAnioSelect: AnioEntity[],
     datosCargaLoaded: boolean | undefined,
     ficheroEstructura: FicheroCargaEntity,
     ficheroEstructuraGuardar: FicheroCargaEntity,
     ficheroObjetivosOficina: FicheroCargaEntity,
     ficheroObjetivosOficinaGuardar: FicheroCargaEntity,
     loading: boolean,
     defaultActiveTab: string
};