import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { podiumReducer } from "./reducers/ranking/podiumReducer";
import { supervisionReducer } from "./reducers/ranking/supervisionReducer";
import { cargaReducer } from "./reducers/ranking/cargaReducer";
import { actionresponseReducer } from "./reducers/actionresponseReducer";
import { layoutmodeReducer } from './reducers/layoutmodeReducer';
import { datoCargaReducer } from "./reducers/ranking/datoCargaReducer";
import { listadoCargaReducer } from './reducers/ranking/listadoCargaReducer';
import { ficheroCargaReducer } from './reducers/ranking/ficheroCargaReducer';
import { anioGuardadoReducer } from './reducers/objetivos/anioGuardadoReducer';
import { listadoOficinasReducer } from './reducers/objetivos/listadoOficinasReducer';
import { detalleOficinasReducer } from './reducers/objetivos/detalleOficinasReducer';
import { cargaObjOfiReducer } from './reducers/objetivos/cargaReducer';

const rankingReducers = combineReducers({
    podium: podiumReducer,
    carga: cargaReducer,
    datoCarga: datoCargaReducer,
    supervision: supervisionReducer,
    listadoCarga: listadoCargaReducer,
    ficheroCarga: ficheroCargaReducer
});

const objetivosReducers = combineReducers({
     anioguardado: anioGuardadoReducer,
     listadoOficinas: listadoOficinasReducer,
     detalleOficinas: detalleOficinasReducer,
     cargaObjOfi: cargaObjOfiReducer
});


const store = configureStore({
    reducer: {
        ranking: rankingReducers,
        objetivos: objetivosReducers,
        response: actionresponseReducer,
        layoutmode: layoutmodeReducer
    },
});


export type RootState = ReturnType<typeof store.getState>;
export default store;