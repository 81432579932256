import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { RootState } from '../../../store';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { setBases, setBasesInitial, setBasesModal, setInitBases } from '../../../actions/ranking/supervisionActions';
import AltaBasesModal from '../../../components/ranking/supervision/AltaBasesModal';
import { getMaxAnio, getAllAnio } from '../../../services/ranking/anioService';
import { AnioEntity } from '../../../types/ranking/anioTypes';
import LoadingSpinner from '../../../components/common/LoadingSpinner';

const Bases = () => {

    const { typeacc } = useParams();
    const navigate = useNavigate();

    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const { guardabases, guardabasesmodal, basesinitial } = state.ranking.supervision;

    const editorRef: any = useRef();
    const [editorLoaded, setEditorLoaded] = useState<boolean>(false);
    const { CKEditor, ClassicEditor }: any = editorRef.current || {};

    // Methods

    const initForm = (year: number | undefined) => {
        dispatch(setBasesInitial("Loading", true));
        if (typeacc === "Nuevo") {
            getMaxAnio().then((response) => {
                if (response === undefined) {
                    dispatch(setInitBases());
                } else {
                    response.valorAnio += 1;
                    dispatch(setInitBases());
                    dispatch(setBases("ValorAnio", response.valorAnio));
                }
                dispatch(setBasesInitial("DefaultActiveTab", "anual"));
                dispatch(setBasesInitial("DefaultAction", "Create"));
                dispatch(setBasesInitial("Loading", false));
            });
        } else if (typeacc === "Editar") {
            getAllAnio().then((response) => {
                dispatch(setBases("SelectAnios", response));
                yearSelectChange(year || new Date().getFullYear(), response);
                dispatch(setBasesInitial("DefaultAction", "Update"));
                dispatch(setBasesInitial("Loading", false));
            });
        }
    };

    const initBases = (response: AnioEntity) => {
        dispatch(setBasesInitial("BasesAnualesInitial", response.basesAnuales));
        dispatch(setBasesInitial("BasesMensualesInitial", response.basesMensuales));
        dispatch(setBases("BasesAnuales", response.basesAnuales));
        dispatch(setBases("BasesMensuales", response.basesMensuales));
    };

    const setGuardado = () => {
        dispatch(setBasesModal("Mensaje", "¿Desea guardar los datos?"));
        dispatch(setBasesModal("Visible", true));
    };

    const checkUnsavedChanges = (e: string) => {
        dispatch(setBasesInitial("DefaultActiveTab", e));
        if (guardabasesmodal.BasesAnualesChanged || guardabasesmodal.BasesMensualesChanged) {
            dispatch(setBasesModal("Visible", true));
        }
    };

    const yearSelectChange = (value: number, data: AnioEntity[] | undefined) => {
        const basesEnt: AnioEntity | undefined = data === undefined ? guardabases.SelectAnios.find(u => u.valorAnio === value)
            : data.find(u => u.valorAnio === value);
        
        if (basesEnt !== undefined) {
            dispatch(setBases("ValorAnio", value));
            initBases(basesEnt);
        } else {       
            var basesEntLastYear: AnioEntity | undefined = undefined;
            if (data !== undefined && data.length > 0) {
                basesEntLastYear = data[data.length - 1];
            } else if (guardabases.SelectAnios.length > 0) {
                basesEntLastYear = guardabases.SelectAnios[guardabases.SelectAnios.length - 1];
            }
            if (basesEntLastYear !== undefined) {
                dispatch(setBases("ValorAnio", basesEntLastYear.valorAnio));
                initBases(basesEntLastYear);
            }
        }
    };

    // useEffect

    useEffect(() => {
        editorRef.current = {
            CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
            ClassicEditor: require('@ckeditor/ckeditor5-build-classic')
        }
    }, []);

    useEffect(() => {
        if (typeacc !== undefined && typeacc !== "Nuevo" && typeacc !== "Editar") {
            navigate("/error/404");
        }
        initForm(undefined);
        setEditorLoaded(true);
    }, [typeacc]);

    // Render

    return (
        <>{basesinitial.Loading ? <LoadingSpinner /> :
            <div className="container"><br />
                <h3>{typeacc} Ranking</h3><br />
                <div className="form-group row">
                    <label className="col-1 col-form-label">Año</label>
                    <div className="col-2">
                        {typeacc === "Nuevo" ? <input type="number" name="ValorAnio" className="form-control"
                            value={guardabases.ValorAnio} disabled /> :
                            <select className='form-control' value={guardabases.ValorAnio} onChange={(event) => yearSelectChange(Number(event.target.value), undefined)}>
                                {
                                    guardabases.SelectAnios.map((element: AnioEntity) =>
                                        <option key={"opyear_" + element.id} value={element.valorAnio}>{element.valorAnio}</option>
                                    )
                                }
                            </select>
                        }
                    </div>
                </div><br />

                <Tabs activeKey={basesinitial.DefaultActiveTab} id="bases-tab" className="mb-3"
                    onSelect={(e: any) => checkUnsavedChanges(e)}>

                    <Tab eventKey="anual" title="Bases Anuales">
                        {editorLoaded ? <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: { removeItems: ['uploadImage'] }
                            }}
                            data={guardabases.BasesAnuales}
                            onReady={(editor: any) => {

                            }}
                            onChange={(event: any, editor: any) => {
                                const data = editor.getData();
                                dispatch(setBases("BasesAnuales", data));
                            }}
                        /> : <p>Cargando editor...</p>}
                    </Tab>

                    <Tab eventKey="mensual" title="Bases Mensuales">
                        {editorLoaded ? <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: { removeItems: ['uploadImage'] }
                            }}
                            data={guardabases.BasesMensuales}
                            onReady={(editor: any) => {

                            }}
                            onChange={(event: any, editor: any) => {
                                const data = editor.getData();
                                dispatch(setBases("BasesMensuales", data));
                            }}
                        /> : <p>Cargando editor...</p>}
                    </Tab>

                </Tabs><br />

                <div className="col-2">
                    <button className='btn btn-soliss'
                        onClick={() => setGuardado()}>{typeacc === "Nuevo" ? "Guardar" : "Modificar"}</button>
                </div>

                <AltaBasesModal typeacc={typeacc || ""} initForm={initForm} />
            </div>
        }
        </>
    );
};

export default Bases;