import { SET_ACTIVE_LAYOUT, layoutMode } from   "../types/actionResponseTypes";

const layout: layoutMode = {
  LayoutActive: ""
};


export function layoutmodeReducer(state = layout, action: any): layoutMode {

    switch (action.type) {

        case SET_ACTIVE_LAYOUT: {

            var currentStateLayout = { ...state } as layoutMode;
            currentStateLayout["LayoutActive"] = action.payload.prop === "LayoutActive" ? action.payload.datalayout : currentStateLayout.LayoutActive;
            
            return {
                ...state,
                LayoutActive: currentStateLayout["LayoutActive"]
            } as layoutMode;

        }

        default:
            return state;

    }

};