import { Route, Routes } from 'react-router-dom';
import Anio from '../../pages/objetivos/supervision/Anio';
import Listado from '../../pages/objetivos/carga/Listado';
import ErrorComponent from '../../components/common/Error';
import Error from '../../pages/Error';
import ListadoOficinasPage from '../../pages/objetivos/supervision/Listado';
import DetalleOficinasPage from '../../pages/objetivos/visualizacion/Detalle';
import { PrivateRoute } from '../../components/router/PrivateRoute';
import { UserType } from '../../types/users/userTypes';
import { ROL_RANK_SUPERVISOR, ROL_RANK_CARGA, ROL_RANK_VISUALIZADOR } from '../../types/users/rolesTypes';

export default function MenuRoutesObjetivos({ user }: { user: UserType }) {

       return (
           <Routes>
                 
                     <Route path="/objetivos/supervision/:typeaccAnio"
                            element={<PrivateRoute isSignedIn={user.isAuthenticated}
                                   hasrequiredRole={user.roles.includes(ROL_RANK_SUPERVISOR)} component={Anio} />} />

                     <Route path="/objetivos/carga/Listado"
                            element={<PrivateRoute isSignedIn={user.isAuthenticated}
                                   hasrequiredRole={user.roles.includes(ROL_RANK_CARGA)} component={Listado} />} />

                     <Route path="/objetivos/supervision/Listado"
                            element={<PrivateRoute isSignedIn={user.isAuthenticated}
                                   hasrequiredRole={user.roles.includes(ROL_RANK_SUPERVISOR)} component={ListadoOficinasPage} />} />

                       <Route path="/objetivos/visualizacion/Detalle">
                           <Route path=":numPC" element={<PrivateRoute isSignedIn={user.isAuthenticated}
                               hasrequiredRole={user.roles.includes(ROL_RANK_VISUALIZADOR)} component={DetalleOficinasPage} />} />
                       </Route>
                       <Route path="/error/:type" element={<Error />} />
                     <Route path="*" element={<ErrorComponent errorType={404} />} />
              </Routes>
       );
};