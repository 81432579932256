import { AnioEntity } from '../../types/ranking/anioTypes';

export const INIT_BASES = "INIT_BASES";
export const SET_BASES = "SET_BASES";
export const SET_BASES_INITIAL = "SET_BASES_INITIAL";
export const SET_BASES_MODAL = "SET_BASES_MODAL";
export const GUARDA_BASES_RESPONSE = "GUARDA_BASES_RESPONSE";

export type StateGuardaBases = {
    ValorAnio: number,
    SelectAnios: AnioEntity[],
    BasesMensuales: string,
    BasesAnuales: string,
    CreationDate: string,
    Usuario: string
};
export type StateGuardaBasesModal = {
   Visible: boolean,
   Titulo: string,
   Mensaje: string,
   BasesAnualesChanged: boolean,
   BasesMensualesChanged: boolean
};
export type StateBasesInitial = {
    BasesMensualesInitial: string,
    BasesAnualesInitial: string,
    DefaultActiveTab: string,
    DefaultAction: string,
    ActionMessage: string,
    Loading: boolean
};

export type StateSupervision = {
    guardabases: StateGuardaBases,
    guardabasesmodal: StateGuardaBasesModal,
    basesinitial: StateBasesInitial
};