import { LOAD_OFFICES, LOAD_RANKING_OPTIONS, LOAD_RANKING_RESPONSE, SET_CONSULTAR_BASES_MODAL, StateRanking, StateRankingSelect, StateConsultarBasesModal } from "../../types/ranking/rankingTypes";

const stateSelectRanking: StateRankingSelect = {
    ValorAnio: 0,
    SelectAnios: [],
    TipoCarga: 1,
    IdGrupo: 1,
    FechaInicio: "",
    FechaFin: "",
    SelectPeriodo: []
};

const consultaBasesModal : StateConsultarBasesModal={
     Visible: false,
     Titulo: "",
     ContenidoBases: ""
};

export const rankingInitialState: StateRanking = {
    rankingselect: stateSelectRanking,
    officeItemsA: [],
    officeItemsB: [],
    officeItemsC: [],
    isLoading: false,
    errormessage: "",
    consultabasesmodal: consultaBasesModal
};


export function podiumReducer(state = rankingInitialState, action: any): StateRanking {
    switch (action.type) {
        case LOAD_RANKING_OPTIONS: {
            var currentStateBasesModal = { ...state.consultabasesmodal } as StateConsultarBasesModal;
            var currentState = { ...state.rankingselect } as StateRankingSelect;

            currentState["SelectAnios"] = action.payload.prop === "SelectAnios" ? action.payload.datasave : currentState.SelectAnios;
            currentState["SelectPeriodo"] = action.payload.prop === "SelectPeriodo" ? action.payload.datasave : currentState.SelectPeriodo;
            currentState["FechaInicio"] = action.payload.prop === "FechaInicio" ? action.payload.datasave : currentState.FechaInicio;
            currentState["FechaFin"] = action.payload.prop === "FechaFin" ? action.payload.datasave : currentState.FechaFin;

            if (action.payload.prop === "ValorAnio") {

                currentState["ValorAnio"] = action.payload.datasave;

                let findBasesAnio = currentState.SelectAnios.find(u => u.valorAnio === action.payload.datasave);
                let currentBasesAnio = currentState.TipoCarga === 1 ? findBasesAnio?.basesMensuales : findBasesAnio?.basesAnuales;

                currentStateBasesModal["Titulo"] = currentState.TipoCarga === 1 ? "Consultar Bases Mensuales" : "Consultar Bases Anuales";
                currentStateBasesModal["ContenidoBases"] = currentBasesAnio || "";
            }
            if (action.payload.prop === "TipoCarga") {

                currentState["TipoCarga"] = action.payload.datasave;

                let findBasesTipo = currentState.SelectAnios.find(u => u.valorAnio === currentState.ValorAnio);
                let currentBasesTipo = action.payload.datasave === 1 ? findBasesTipo?.basesMensuales : findBasesTipo?.basesAnuales;

                currentStateBasesModal["Titulo"] = action.payload.datasave === 1 ? "Consultar Bases Mensuales" : "Consultar Bases Anuales";
                currentStateBasesModal["ContenidoBases"] = currentBasesTipo || "";
            }

            return {
                ...state,
                rankingselect: currentState,
                consultabasesmodal: currentStateBasesModal,
                isLoading: false
            } as StateRanking;
        }
        case LOAD_OFFICES: {
            var currentStateRoot = { ...state } as StateRanking;

            currentStateRoot["officeItemsA"] = action.payload.prop === "A" ? action.payload.offices : currentStateRoot.officeItemsA;
            currentStateRoot["officeItemsB"] = action.payload.prop === "B" ? action.payload.offices : currentStateRoot.officeItemsB;
            currentStateRoot["officeItemsC"] = action.payload.prop === "C" ? action.payload.offices : currentStateRoot.officeItemsC;

            return {
                ...state,
                officeItemsA: currentStateRoot["officeItemsA"],
                officeItemsB: currentStateRoot["officeItemsB"],
                officeItemsC: currentStateRoot["officeItemsC"]
            } as StateRanking;
        }
        case LOAD_RANKING_RESPONSE: {
            var currentStateResponse = { ...state } as StateRanking;

            currentStateResponse["isLoading"] = action.payload.prop === "isLoading" ? action.payload.datasave : currentStateResponse.isLoading;
            currentStateResponse["errormessage"] = action.payload.prop === "errormessage" ? action.payload.datasave : currentStateResponse.errormessage;

            return {
                ...state,
                isLoading: currentStateResponse["isLoading"],
                errormessage: currentStateResponse["errormessage"]
            } as StateRanking;

        }
        case SET_CONSULTAR_BASES_MODAL: {
            var stateBasesModal = { ...state.consultabasesmodal } as StateConsultarBasesModal;

            stateBasesModal["Visible"] = action.payload.prop === "Visible" ? action.payload.datamodal : stateBasesModal.Visible;
            stateBasesModal["Titulo"] = action.payload.prop === "Titulo" ? action.payload.datamodal : stateBasesModal.Titulo;
            stateBasesModal["ContenidoBases"] = action.payload.prop === "ContenidoBases" ? action.payload.datamodal : stateBasesModal.ContenidoBases;

            return {
                ...state,
                consultabasesmodal: stateBasesModal
            } as StateRanking;

        }
        default:
            return state;
    }
}