import { INIT_BASES, SET_BASES, SET_BASES_INITIAL, SET_BASES_MODAL, StateGuardaBases, StateGuardaBasesModal, StateBasesInitial, StateSupervision } from "../../types/ranking/supervisionTypes";

const stateSave: StateGuardaBases = {
    ValorAnio: new Date().getFullYear(),
    SelectAnios: [],
    BasesAnuales: "",
    BasesMensuales: "",
    CreationDate: new Date().toISOString(),
    Usuario: "UserDev"
};
const stateSaveModal: StateGuardaBasesModal = {
    Visible: false,
    Titulo: "Nuevo Ranking",
    Mensaje: "¿Desea guardar los datos?",
    BasesAnualesChanged: false,
    BasesMensualesChanged: false
};
const statebasesInitial: StateBasesInitial = {
    BasesAnualesInitial: "",
    BasesMensualesInitial: "",
    DefaultActiveTab: "anual",
    DefaultAction: "Create",
    ActionMessage: "",
    Loading: true
};

export const supervisionInitialState: StateSupervision = {
    guardabases: stateSave,
    guardabasesmodal: stateSaveModal,
    basesinitial: statebasesInitial
};

export function supervisionReducer(state = supervisionInitialState, action: any): StateSupervision {   
    switch (action.type) {
        case INIT_BASES: {
            return {
                ...state,
                guardabases: stateSave,
                guardabasesmodal: stateSaveModal,
                basesinitial: statebasesInitial
            } as StateSupervision;
        }
        case SET_BASES: {
            var currentState = { ...state.guardabases } as StateGuardaBases;
            var currentStateInitial = { ...state.basesinitial } as StateBasesInitial;
            var currentStateModal = { ...state.guardabasesmodal } as StateGuardaBasesModal;

            currentState["ValorAnio"] = action.payload.prop === "ValorAnio" ? action.payload.datasave : currentState.ValorAnio;
            currentState["SelectAnios"] = action.payload.prop === "SelectAnios" ? action.payload.datasave : currentState.SelectAnios;
            currentState["CreationDate"] = new Date().toISOString();

            if (action.payload.prop === "BasesAnuales") {
                currentStateModal["BasesAnualesChanged"] = false;
                if (currentStateInitial["BasesAnualesInitial"] !== action.payload.datasave) {
                    currentStateModal["BasesAnualesChanged"] = true;
                    currentStateModal["Mensaje"] = "Se han detectado cambios no guardados en las bases anuales, ¿desea guardarlos?";
                }
                currentState["BasesAnuales"] = action.payload.datasave;
            }

            if (action.payload.prop === "BasesMensuales") {
                currentStateModal["BasesMensualesChanged"] = false;
                if (currentStateInitial["BasesMensualesInitial"] !== action.payload.datasave) {
                    currentStateModal["BasesMensualesChanged"] = true;
                    currentStateModal["Mensaje"] = "Se han detectado cambios no guardados en las bases mensuales, ¿desea guardarlos?";
                }
                currentState["BasesMensuales"] = action.payload.datasave;
            }

            return {
                ...state,
                guardabases: currentState,
                basesinitial: currentStateInitial,
                guardabasesmodal: currentStateModal
            } as StateSupervision;
        }
        case SET_BASES_MODAL: {
            var currentStateModal = { ...state.guardabasesmodal } as StateGuardaBasesModal;
            currentStateModal["Visible"] = action.payload.prop === "Visible" ? action.payload.datamodal : currentStateModal.Visible;
            currentStateModal["Titulo"] = action.payload.prop === "Titulo" ? action.payload.datamodal : currentStateModal.Titulo;
            currentStateModal["Mensaje"] = action.payload.prop === "Mensaje" ? action.payload.datamodal : currentStateModal.Mensaje;

            return {
                ...state,
                guardabasesmodal: currentStateModal
            } as StateSupervision;
        }
        case SET_BASES_INITIAL: {
            var currentState = { ...state.guardabases } as StateGuardaBases;
            var currentStateInitial = { ...state.basesinitial } as StateBasesInitial;

            currentStateInitial["BasesAnualesInitial"] = action.payload.prop === "BasesAnualesInitial" ? action.payload.datainitial : currentStateInitial.BasesAnualesInitial;
            currentStateInitial["BasesMensualesInitial"] = action.payload.prop === "BasesMensualesInitial" ? action.payload.datainitial : currentStateInitial.BasesMensualesInitial;
            currentStateInitial["DefaultActiveTab"] = action.payload.prop === "DefaultActiveTab" ? action.payload.datainitial : currentStateInitial.DefaultActiveTab;
            currentStateInitial["Loading"] = action.payload.prop === "Loading" ? action.payload.datainitial : currentStateInitial.Loading;
            
            if(action.payload.prop === "DefaultAction"){
                currentStateInitial["DefaultAction"] = action.payload.datainitial;

                if(action.payload.datainitial === "Create"){
                    currentStateInitial["ActionMessage"] = "Alta año: " + currentState["ValorAnio"] + " - ";
                }
                else if(action.payload.datainitial === "Update"){
                    currentStateInitial["ActionMessage"] = "Modificar año: " + currentState["ValorAnio"] + " - ";
                }
            }

            return {
                ...state,
                basesinitial: currentStateInitial
            } as StateSupervision;
        }
        default:
            return state;
    }
};