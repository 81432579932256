import { HttpRequestHandler } from '../communications/httpRequestHandler';
import { getApiUrl } from '../../configurations/environmentVariables';
import { AuthHandler } from '../communications/authHandler';
import { OficinaModel } from '../../types/objetivos/oficinaTypes';
import { FileType } from '../../types/fileTypes';

const httpRequestHandler = new HttpRequestHandler();
const authHandler = new AuthHandler();

export const getOficinasByAnioId = async (anioId: number): Promise<OficinaModel[]> => {

	var token = await authHandler.getAPIToken();
	var response = await httpRequestHandler.get(`${getApiUrl()}/Objetivo/Oficina/GetOficinasByAnioId?anioId=${anioId}`, token);

	if (response !== undefined) {
		if (response.totalCount > 0) {

			return response.items;
		}
	}

	return [];
};

export const getOficinasByNumPC = async (numPC: string | undefined, anioId: number, submoduleFrom: number): Promise<OficinaModel | undefined> => {

	var token = await authHandler.getAPIToken();
	var response = await httpRequestHandler.get(`${getApiUrl()}/Objetivo/Oficina/GetOficinasByNumPC?numPC=${numPC}&anioId=${anioId}&submoduleFrom=${submoduleFrom}`, token);

	if (response !== undefined) {
		if (response.totalCount > 0) {

			return response.items[0];
		}
	}

	return undefined;
};

export const getOficinasExcel = async (anioId: number): Promise<FileType | undefined> => {

	var token = await authHandler.getAPIToken();
	var response = await httpRequestHandler.get(`${getApiUrl()}/Objetivo/Oficina/GetOficinasExcelByAnioId?anioId=${anioId}`, token);

	if (response !== undefined) {
		
		return response;
	}

	return undefined;
};