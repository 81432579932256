import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import LayoutBase from './components/layout/LayoutBase';
import LoadingSpinner from './components/common/LoadingSpinner';
import { AuthHandler } from './services/communications/authHandler'
import { DEV } from './configurations/environmentTypes';
import { getUnauthorizedUserUrl } from './configurations/environmentVariables';
import { USUARIO_ACCESO_PRE, USUARIO_ACCESO_PRO } from './types/users/rolesTypes';
import { ROL_RANK_CARGA, ROL_RANK_SUPERVISOR, ROL_RANK_VISUALIZADOR } from './types/users/rolesTypes';
import { UserType } from './types/users/userTypes';
import LoginRedirect from './pages/LoginRedirect';
import { getBaseUrl } from './configurations/environmentVariables';

const App = () => {

    // Properties

    const authHandler = new AuthHandler();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<UserType>({
        name: '',
        email: '',
        numPC: '',
        roles: [],
        isAuthorized: false,
        isAuthenticated: false
    });

    const state = useSelector((state: RootState) => state);
    const { LayoutActive } = state.layoutmode;
    const href = window.location.href;
    // Methods

    const validateLogin = async () => {

        console.log("Soliss | Ranking y Objetivos");
        if (!user.isAuthenticated) {

            let response = await authHandler.getSolissApiUserData();

            if (response !== null) {

                let isAuthorized: boolean = validateAppAuthorization(response.roles);

                if (isAuthorized) {

                    setUserSession(response);

                } else {

                    window.location.replace(getUnauthorizedUserUrl());
                }
            }
        }
    }

    const validateAppAuthorization = (roles: string[]) => {

        switch (process.env.REACT_APP_ENVIRONMENT) {

            case "PRE":
                return (roles.includes(USUARIO_ACCESO_PRE));

            case "PRO":
                return (roles.includes(USUARIO_ACCESO_PRO));

            default:
                return true;
        }
    }

    const setUserSession = (response: any) => {

        let userData: UserType = {
            name: response.nombre,
            email: response.email,
            roles: response.roles,
            numPC: response.numPC,
            isAuthorized: true,
            isAuthenticated: true
        };

        if (process.env.REACT_APP_ENVIRONMENT === DEV) {
            userData.roles.push(ROL_RANK_SUPERVISOR);
            userData.roles.push(ROL_RANK_CARGA);
            userData.roles.push(ROL_RANK_VISUALIZADOR);
        }

        setUser(userData);
        setLoading(false);
    }

    const setLayout = () => {

        if (LayoutActive !== "") {

            return (
                <>
                    <LayoutBase user={user} mode={LayoutActive} />
                    <LoginRedirect user={user} mode={LayoutActive} />
                </>
            );

        } else {
            if (href.includes(`${getBaseUrl()}/ranking/`)) {

                return (<LayoutBase user={user} mode={"rank"} />);

            } else if (href.includes(`${getBaseUrl()}/objetivos/`)) {

                return (<LayoutBase user={user} mode={"obj"} />);

            } else {

                return (<LayoutBase user={user} mode={""} />);
            }
        }
    };

    // useEffect

    useEffect(() => {
        validateLogin();
    }, []);

    // Render

    if (loading) {
        return (<LoadingSpinner />);
    } else {
        return setLayout();
    }
}

export default App;