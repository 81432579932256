import { Route, Routes } from 'react-router-dom';
import Listado from '../../pages/ranking/carga/Listado';
import Bases from '../../pages/ranking/supervision/Bases';
import ListadoSupervision from '../../pages/ranking/supervision/Listado';
import Detalle from '../../pages/ranking/supervision/Detalle';
import Ranking from '../../pages/ranking/visualizacion/Ranking';
import ErrorComponent from '../common/Error';
import { PrivateRoute } from '../router/PrivateRoute';
import { UserType } from '../../types/users/userTypes';
import { ROL_RANK_SUPERVISOR, ROL_RANK_CARGA, ROL_RANK_VISUALIZADOR } from '../../types/users/rolesTypes';

export default function MenuRoutesRanking({ user }: { user: UserType }) {

       return (
           <Routes>
                    
                     <Route path="/ranking/carga/Listado"
                            element={<PrivateRoute isSignedIn={user.isAuthenticated}
                                   hasrequiredRole={user.roles.includes(ROL_RANK_CARGA)} component={Listado} />}/>

                     <Route path="/ranking/supervision/:typeacc"
                            element={<PrivateRoute isSignedIn={user.isAuthenticated}
                                   hasrequiredRole={user.roles.includes(ROL_RANK_SUPERVISOR)} component={Bases} />} />

                     <Route path="/ranking/supervision/Listado"
                            element={<PrivateRoute isSignedIn={user.isAuthenticated}
                                   hasrequiredRole={user.roles.includes(ROL_RANK_SUPERVISOR)} component={ListadoSupervision} />} />

                     <Route path="/ranking/supervision/Detalle"
                            element={<PrivateRoute isSignedIn={user.isAuthenticated}
                                   hasrequiredRole={user.roles.includes(ROL_RANK_SUPERVISOR)} component={Detalle} />} />

                     <Route path="/ranking/visualizacion/Ranking">
                            <Route index element={<PrivateRoute isSignedIn={user.isAuthenticated}
                                   hasrequiredRole={user.roles.includes(ROL_RANK_VISUALIZADOR)} component={Ranking} />} />
                            <Route path=":anio/:tipo/:periodo" element={<PrivateRoute isSignedIn={user.isAuthenticated}
                                   hasrequiredRole={user.roles.includes(ROL_RANK_VISUALIZADOR)} component={Ranking} />} />
                     </Route>

                     <Route path="*" element={<ErrorComponent errorType={404} />} />
              </Routes>
       );
};