import { HttpRequestHandler } from '../communications/httpRequestHandler';
import { getApiUrl } from '../../configurations/environmentVariables';
import { AuthHandler } from '../communications/authHandler';
import { DatoCargaDTO } from '../../types/objetivos/oficinaTypes';

const httpRequestHandler = new HttpRequestHandler();
const authHandler = new AuthHandler();

export const updateValor = async (datoCargaList: DatoCargaDTO[]) : Promise<DatoCargaDTO[] | undefined> => {

	var token = await authHandler.getAPIToken();
	const response = await httpRequestHandler.post(`${getApiUrl()}/Objetivo/DatoCarga/Update`, token, { datoCargas : datoCargaList});

	if (response !== undefined && response.totalCount > 0) {
		return response.items[0];
	}

	return undefined;
};