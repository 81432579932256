export const SET_RESPONSE = "SET_RESPONSE";
export const HIDE_RESPONSE = "HIDE_RESPONSE";
export const SET_ACTIVE_LAYOUT = "SET_ACTIVE_LAYOUT";

export type actionResponseType = {
   IsOk: boolean,
   ResponseMessage: string,
   IsVisible: boolean
};


export type layoutMode = {
   LayoutActive: string
};