import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './../../../../store';
import { useSortBy, useTable, Column } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareUp, faCaretSquareDown } from '@fortawesome/free-solid-svg-icons';
import { DatoCargaEntity } from '../../../../types/ranking/datoCargaTypes';

export default function DatoCargaTable() {

    const state = useSelector((state: RootState) => state);
    const { content } = state.ranking.datoCarga.table;

    // Table

    const columns: Column<DatoCargaEntity>[] = React.useMemo(() =>
        [
            {
                Header: 'idGrupoCarga',
                accessor: 'idGrupoCarga'
            },
            {
                Header: 'Oficina',
                accessor: 'numPC',
                sortType: 'basic'
            },
            {
                Header: 'Ptos Indiv.',
                accessor: 'puntosParticulares',
                sortType: 'basic',
                Cell: (row: any) => <div style={{ textAlign: "center" }}>{row.value}</div>
            },
            {
                Header: 'Ptos Empresa',
                accessor: 'puntosEmpresa',
                sortType: 'basic',
                Cell: (row: any) => <div style={{ textAlign: "center" }}>{row.value}</div>
            },
            {
                Header: 'Ptos totales',
                accessor: 'puntosTotales',
                sortType: 'basic',
                Cell: (row: any) => <div style={{ textAlign: "center" }}>{row.value}</div>
            },
            {
                Header: 'Posición',
                accessor: 'posicionRanking',
                sortType: 'basic',
                Cell: (row: any) => <div style={{ textAlign: "center" }}>{row.value}</div>
            }
        ], []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows
    } = useTable({
        columns,
        data: content,
        autoResetPage: false,
        autoResetSortBy: false,
        initialState: { pageIndex: 0, hiddenColumns: ["idGrupoCarga"] },
    }, useSortBy );

    return (
        <table {...getTableProps()} className="table table-striped">
            <thead className="thead-light">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        {column.render('Header')}
                                        {
                                            <span style={{ float: "right" }}>
                                                {
                                                    column.isSorted ? (column.isSortedDesc ?
                                                        <FontAwesomeIcon icon={faCaretSquareDown} /> :
                                                        <FontAwesomeIcon icon={faCaretSquareUp} />)
                                                        : ''
                                                }
                                            </span>
                                        }
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    );
};
