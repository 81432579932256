import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './../../../../store';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faGear, faLink, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { loadDatoCargaDetalle, loadFicheroCargaListado, setFicheroCargaModalContent } from '../../../../actions/ranking/cargaActions';
import { getFicheroCargaExcel } from "../../../../services/ranking/cargaService";
import { setActionResponse } from "../../../../actions/actionResponseActions";

export default function FicheroCargaActions(rowContent: any) {

    const dispatch = useDispatch();
    const router = useNavigate();
    const state = useSelector((state: RootState) => state);

    // Methods

    const handleDetalleOnClick = (event: any) => {

        dispatch(loadDatoCargaDetalle({
            ...state.ranking.datoCarga,
            valorAnio: rowContent.rowContent.valorAnio,
            fechaInicio: rowContent.rowContent.fechaInicio,
            fechaFin: rowContent.rowContent.fechaFin,
            idFicheroCarga: rowContent.rowContent.id,
            isActionable: true
        }));

        router('/ranking/supervision/Detalle');
    };

    const handleProcesarOnClick = (event: any) => {

        dispatch(setFicheroCargaModalContent({
            id: rowContent.rowContent.id,
            fechaSubida: rowContent.rowContent.fechaSubida,
            anio: rowContent.rowContent.anio,
            mes: rowContent.rowContent.mes,
            estado: rowContent.rowContent.estado,
            valorAnio: rowContent.rowContent.valorAnio,
            fechaInicio: rowContent.rowContent.fechaInicio,
            fechaFin: rowContent.rowContent.fechaFin,
            isPublicable: rowContent.rowContent.isPublicable,
            isRechazado: rowContent.rowContent.isRechazado
        }));

        dispatch(loadFicheroCargaListado({
            ...state.ranking.ficheroCarga,
            displayProcesarModal: true
        }));
    };

    const handleEnlaceOnClick = (event: any) => {

        dispatch(setFicheroCargaModalContent({
            id: rowContent.rowContent.id,
            fechaSubida: rowContent.rowContent.fechaSubida,
            anio: rowContent.rowContent.anio,
            mes: rowContent.rowContent.mes,
            estado: rowContent.rowContent.estado,
            valorAnio: rowContent.rowContent.valorAnio,
            fechaInicio: rowContent.rowContent.fechaInicio,
            fechaFin: rowContent.rowContent.fechaFin,
            isPublicable: rowContent.rowContent.isPublicable,
            isRechazado: rowContent.rowContent.isRechazado
        }));

        dispatch(loadFicheroCargaListado({
            ...state.ranking.ficheroCarga,
            displayEnlaceModal: true,
        }));
    };

    const handleDescargarOnClick = async (event: any) => {

        var file = await getFicheroCargaExcel(rowContent.rowContent.id);

        if (file !== undefined) {

            const a = document.createElement('a');
            a.download = file !== undefined ? file.fileName : "";
            a.href = `data:application/xlsx;base64,${file !== undefined ? file.filecontent : ""}`;
            a.click();

        } else {

            displayResponseAlert(false, "Ha ocurrido un error al intentar descargar el fichero", true);
        }
    };

    const displayResponseAlert = (isOk: boolean, message: string, isVisible: boolean) => {

        dispatch(setActionResponse("IsOk", isOk));
        dispatch(setActionResponse("ResponseMessage", message));
        dispatch(setActionResponse("IsVisible", isVisible));
    }

    // Render

    return (
        <div style={{ textAlign: "center" }}>
            <Button title="Detalle" name="detalleButton" id="detalleButton" className="btn-soliss col-2" size="sm" style={{ marginRight: "3px" }} onClick={handleDetalleOnClick}><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
            <Button title="Descargar" name="descargarButton" id="descargarButton" className="btn-soliss col-2" size="sm" style={{ marginRight: "3px" }} onClick={handleDescargarOnClick}><FontAwesomeIcon icon={faArrowDown} /></Button>
            {
                rowContent.rowContent.isPublicable  || rowContent.rowContent.isRechazado ?
                <Button title="Procesar" name="procesarButton" id="procesarButton" className="btn-soliss col-2" size="sm" style={{ marginRight: "3px" }} onClick={handleProcesarOnClick}><FontAwesomeIcon icon={faGear} /></Button>
                : ''
            }
            {
                rowContent.rowContent.estado === 'Publicado' ?
                    <Button title="Enlace" name="enlaceButton" id="enlaceButton" className="btn-soliss col-2" size="sm" onClick={handleEnlaceOnClick}><FontAwesomeIcon icon={faLink} /></Button>
                    : ''
            }
        </div>
    );
};
