import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './../../../../store';
import { Col, Row, Card, Button, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faHourglass } from '@fortawesome/free-solid-svg-icons';
import { loadDatoCargaDetalle } from '../../../../actions/ranking/cargaActions';

export default function DatoCargaActions() {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state);
    const { grupoCargaList, isActionable } = state.ranking.datoCarga;

    // Methods

    const handleCorrectoButtonsOnClick = (event: any) => {

        dispatch(loadDatoCargaDetalle({
            ...state.ranking.datoCarga,
            evaluacion: true,
            displayModal: true
        }));
    };

    const handleIncorrectoButtonsOnClick = (event: any) => {

        dispatch(loadDatoCargaDetalle({
            ...state.ranking.datoCarga,
            evaluacion: false,
            displayModal: true
        }));
    };

    return (
        <>
            <Row className="justify-content-md-center">
                <Col md={12}>
                    <Card style={{ height: "100%" }}>
                        <Card.Body>
                            <Card.Title><h4>Acciones</h4></Card.Title>
                            <hr />
                            {
                                isActionable ?
                                    <Row className="justify-content-md-center">
                                        <Col className='text-center mb-3'>
                                            <Button id="correctoButton" title="Correcto" className='btn-sm btn-soliss' style={{ margin: "5px" }} onClick={handleCorrectoButtonsOnClick}>
                                                Correcto <FontAwesomeIcon icon={faCheck} />
                                            </Button>
                                            <Button id="incorrectoButton" title="Incorrecto" className='btn-sm btn-soliss' onClick={handleIncorrectoButtonsOnClick}>
                                                Incorrecto <FontAwesomeIcon icon={faXmark} />
                                            </Button>
                                        </Col>
                                    </Row>
                                    : ''
                            }
                            <Row className="justify-content-md-center">
                                <Col md={12}>
                                    {
                                        grupoCargaList.map((grupoCarga) => {

                                            let grupoName: string = "";
                                            switch (grupoCarga.idGrupo) {
                                                case 1:
                                                    grupoName = "A";
                                                    break;
                                                case 2:
                                                    grupoName = "B";
                                                    break;
                                                case 3:
                                                    grupoName = "C";
                                                    break;
                                            }

                                            if (grupoCarga.correcto) {

                                                return (<div key={`grupoCarga_${grupoCarga.id}`} className="alert alert-success">
                                                    <FontAwesomeIcon key={`grupoCargaIcon_${grupoCarga.id}`} icon={faCheck} /> Grupo {grupoName} Correcto
                                                </div>)

                                            } else if (grupoCarga.correcto === false) {

                                                return (<div key={`grupoCarga_${grupoCarga.id}`} className="alert alert-danger">
                                                    <FontAwesomeIcon key={`grupoCargaIcon_${grupoCarga.id}`} icon={faXmark} /> Grupo {grupoName} Incorrecto
                                                </div>)

                                            } else {

                                                return (<div key={`grupoCarga_${grupoCarga.id}`} className="alert alert-warning">
                                                    <FontAwesomeIcon key={`grupoCargaIcon_${grupoCarga.id}`} icon={faHourglass} /> Grupo {grupoName} Pendiente
                                                </div>)
                                            }
                                        })
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
