import { GET, POST } from "./httpMethodsTypes";
import { getBaseUrl } from "../../configurations/environmentVariables";

export class HttpRequestHandler {

    // Fetch Methods

    private async fetch(method: string, token: string, url: string, data: any) {

        try {

            const options: any = {
                method: method,
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                }
            };

            if (method === POST) {
                
                options["body"] = JSON.stringify(data)
            }

            return fetch(url, options);

        } catch (error) {

            throw error;
        }
    }

    // Http Methods

    async get(url: string, token: any) {

        try {

            var apiCall = await this.fetch(GET, token, url, null);

            if (apiCall !== null) {

                if (apiCall.ok) {

                    if (apiCall.status === 200) {

                        return await apiCall.json();

                    } else {

                        return undefined;
                    }
                } else {

                    throw new Error(`Error al realizar GET. Status Code: ${apiCall.status}.`);
                }
            }

        } catch (error: any) {

            console.log(error.message);
            
            if (error.message.includes("Status Code: 500")) {

                window.location.replace(`${getBaseUrl()}/error/500`);
            }

            if (error.message.includes("Failed to fetch")) {

                window.location.replace(`${getBaseUrl()}/error/3`);
            }
        }
    }

    async post(url: string, token: any, data: any) {

        try {

            var apiCall = await this.fetch(POST, token, url, data);

            if (apiCall !== null) {

                if (apiCall.ok) {

                    const response = await apiCall.json();
                    return response;

                } else {

                    const responseError = await apiCall.json();
                    throw new Error(`Error al realizar POST. Status Code: ${apiCall.status}. ErrorResponse: ${responseError.Message}`);
                }
            }

        } catch (error: any) {

            console.log(error.message);

            if (error.message.includes("Status Code: 500")) {

                window.location.replace(`${getBaseUrl()}/error/500`);
            }

            if (error.message.includes("Failed to fetch")) {

                window.location.replace(`${getBaseUrl()}/error/3`);
            }
        }
    }
}