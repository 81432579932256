import { FicheroCargaEntity } from '../../types/objetivos/ficheroCargaTypes';

export type EstructuraEntity = {
    id: number,
    ficheroCarga: FicheroCargaEntity,
    tipo: string,
    numero: number,
    descripcion: string,
    messageValidation: string | undefined,
    displayModal: boolean
};

export const requiredHeadersEstructura: Array<string> = ['Tipo_Estructura', 'Numero_Estructura', 'Descripción_Estructura'];