import { INIT_ANIO_GUARDADO, SET_ANIO_GUARDADO, SET_ESTRUCTURA_GUARDADO, SET_TIPO_GUARDADO, StateAnioGuardado } from "../../types/objetivos/anioGuardadoTypes";
import { AnioEntity } from '../../types/objetivos/anioTypes';
import { FicheroCargaEntity } from '../../types/objetivos/ficheroCargaTypes';

const stateSaveAnio: AnioEntity = {
    id: 0,
    valorAnio: new Date().getFullYear(),
    fechaCreacion: new Date().toISOString(),
    observaciones: "",
    usuarioCreacion: "",
    messageValidation: undefined,
    displayModal: false
};

const stateSaveFicheroCarga: FicheroCargaEntity = {
    id: 0,
    anioId: 0,
    nombreFichero: "",
    tipoFichero: "",
    contenidoFichero: "",
    fechaCreacion: new Date().toISOString(),
    usuarioCreacion: "",
    messageValidation: undefined,
    displayModal: false
};

export const anioGuardadoInitialState: StateAnioGuardado = {
    anio: stateSaveAnio,
    stateAnioSelect: [],
    datosCargaLoaded: false,
    ficheroEstructura: stateSaveFicheroCarga,
    ficheroEstructuraGuardar: stateSaveFicheroCarga,
    ficheroObjetivosOficina: stateSaveFicheroCarga,
    ficheroObjetivosOficinaGuardar: stateSaveFicheroCarga,
    loading: false,
    defaultActiveTab: "obstab"
};


export function anioGuardadoReducer(state = anioGuardadoInitialState, action: any): StateAnioGuardado {
    switch (action.type) {
        case INIT_ANIO_GUARDADO: {

            return {
                ...state,
                anio: stateSaveAnio,
                ficheroEstructura: stateSaveFicheroCarga,
                ficheroObjetivosOficina: stateSaveFicheroCarga,
                datosCargaLoaded: false,
            } as StateAnioGuardado;
        }

        case SET_ANIO_GUARDADO: {

            var currentStateInitial = { ...state } as StateAnioGuardado;
            var currentStateAnio = { ...state.anio } as AnioEntity;

            currentStateAnio["id"] = action.payload.prop === "idAnio" ? action.payload.datasave : currentStateAnio.id;
            currentStateAnio["valorAnio"] = action.payload.prop === "valorAnio" ? action.payload.datasave : currentStateAnio.valorAnio;
            currentStateAnio["observaciones"] = action.payload.prop === "observaciones" ? action.payload.datasave : currentStateAnio.observaciones;   
            currentStateAnio["fechaCreacion"] = new Date().toISOString();
            currentStateAnio["messageValidation"] = action.payload.prop === "messageValidation" ? action.payload.datasave : currentStateAnio.messageValidation;
            currentStateAnio["displayModal"] = action.payload.prop === "displayModal" ? action.payload.datasave : currentStateAnio.displayModal;

            currentStateInitial["datosCargaLoaded"] = action.payload.prop === "datosCargaLoaded" ? action.payload.datasave : currentStateInitial.datosCargaLoaded;
            currentStateInitial["stateAnioSelect"] = action.payload.prop === "stateAnioSelect" ? action.payload.datasave : currentStateInitial.stateAnioSelect;
            currentStateInitial["loading"] = action.payload.prop === "loading" ? action.payload.datasave : currentStateInitial.loading;
            currentStateInitial["defaultActiveTab"] = action.payload.prop === "defaultActiveTab" ? action.payload.datasave : currentStateInitial.defaultActiveTab;

            return {
                ...state,
                anio: currentStateAnio,
                datosCargaLoaded: currentStateInitial.datosCargaLoaded,
                stateAnioSelect: currentStateInitial.stateAnioSelect,
                loading: currentStateInitial.loading,
                defaultActiveTab: currentStateInitial.defaultActiveTab
            } as StateAnioGuardado;
        }

        case SET_ESTRUCTURA_GUARDADO: {

            var currentStateEstrucFicheroCarga = { ...state.ficheroEstructura } as FicheroCargaEntity;
            var currentStateEstrucFicheroCargaGuardar = { ...state.ficheroEstructuraGuardar } as FicheroCargaEntity;

            currentStateEstrucFicheroCarga["nombreFichero"] = action.payload.prop === "nombreFichero" ? action.payload.datasave : currentStateEstrucFicheroCarga.nombreFichero;
            currentStateEstrucFicheroCarga["contenidoFichero"] = action.payload.prop === "contenidoFichero" ? action.payload.datasave : currentStateEstrucFicheroCarga.contenidoFichero;

            currentStateEstrucFicheroCargaGuardar["id"] = action.payload.prop === "idFicheroGuardar" ? action.payload.datasave : currentStateEstrucFicheroCargaGuardar.id;
            currentStateEstrucFicheroCargaGuardar["anioId"] = action.payload.prop === "anioIdGuardar" ? action.payload.datasave : currentStateEstrucFicheroCargaGuardar.anioId;
            currentStateEstrucFicheroCargaGuardar["nombreFichero"] = action.payload.prop === "nombreFicheroGuardar" ? action.payload.datasave : currentStateEstrucFicheroCargaGuardar.nombreFichero;
            currentStateEstrucFicheroCargaGuardar["tipoFichero"]= action.payload.prop === "tipoFicheroGuardar" ? action.payload.datasave : currentStateEstrucFicheroCargaGuardar.tipoFichero;
            currentStateEstrucFicheroCargaGuardar["contenidoFichero"] = action.payload.prop === "contenidoFicheroGuardar" ? action.payload.datasave : currentStateEstrucFicheroCargaGuardar.contenidoFichero;
            currentStateEstrucFicheroCargaGuardar["messageValidation"] = action.payload.prop === "messageValidation" ? action.payload.datasave : currentStateEstrucFicheroCargaGuardar.messageValidation;
            currentStateEstrucFicheroCargaGuardar["displayModal"] = action.payload.prop === "displayModal" ? action.payload.datasave : currentStateEstrucFicheroCargaGuardar.displayModal;


            return {
                ...state,
                ficheroEstructura: currentStateEstrucFicheroCarga,
                ficheroEstructuraGuardar: currentStateEstrucFicheroCargaGuardar,
            } as StateAnioGuardado;

        }

        case SET_TIPO_GUARDADO: {
            
            var currentStateTipoFicheroCarga = { ...state.ficheroObjetivosOficina } as FicheroCargaEntity;
            var currentStateTipoFicheroCargaGuardar = { ...state.ficheroObjetivosOficinaGuardar } as FicheroCargaEntity;

            currentStateTipoFicheroCarga["nombreFichero"] = action.payload.prop === "nombreFichero" ? action.payload.datasave : currentStateTipoFicheroCarga.nombreFichero;
            currentStateTipoFicheroCarga["contenidoFichero"] = action.payload.prop === "contenidoFichero" ? action.payload.datasave : currentStateTipoFicheroCarga.contenidoFichero;

            currentStateTipoFicheroCargaGuardar["id"] = action.payload.prop === "idFicheroGuardar" ? action.payload.datasave : currentStateTipoFicheroCargaGuardar.id;
            currentStateTipoFicheroCargaGuardar["anioId"] = action.payload.prop === "anioIdGuardar" ? action.payload.datasave : currentStateTipoFicheroCargaGuardar.anioId;
            currentStateTipoFicheroCargaGuardar["nombreFichero"] = action.payload.prop === "nombreFicheroGuardar" ? action.payload.datasave : currentStateTipoFicheroCargaGuardar.nombreFichero;
            currentStateTipoFicheroCargaGuardar["tipoFichero"]= action.payload.prop === "tipoFicheroGuardar" ? action.payload.datasave : currentStateTipoFicheroCargaGuardar.tipoFichero;
            currentStateTipoFicheroCargaGuardar["contenidoFichero"] = action.payload.prop === "contenidoFicheroGuardar" ? action.payload.datasave : currentStateTipoFicheroCargaGuardar.contenidoFichero;
            currentStateTipoFicheroCargaGuardar["messageValidation"] = action.payload.prop === "messageValidation" ? action.payload.datasave : currentStateTipoFicheroCargaGuardar.messageValidation;
            currentStateTipoFicheroCargaGuardar["displayModal"] = action.payload.prop === "displayModal" ? action.payload.datasave : currentStateTipoFicheroCargaGuardar.displayModal;


            return {
                ...state,
                ficheroObjetivosOficina: currentStateTipoFicheroCarga,
                ficheroObjetivosOficinaGuardar: currentStateTipoFicheroCargaGuardar,
            } as StateAnioGuardado;

        }

        default:
            return state;
    }
};