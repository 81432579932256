import { LOAD_DETALLE_OFICINA, StateDetalleOficina } from "../../types/objetivos/oficinaTypes";

export const detalleOficinasInitialState: StateDetalleOficina = {
    anioValues: [],
    anioIdSelected: 0,
    anioObservacionesSelected: '',
    oficina : undefined,
    isLoading: false
};

export function detalleOficinasReducer(state = detalleOficinasInitialState, action: any): StateDetalleOficina {
    switch (action.type) {

        case LOAD_DETALLE_OFICINA: {

            return {
                ...state,
                anioValues: action.payload.anioValues,
                anioIdSelected: action.payload.anioIdSelected,
                anioObservacionesSelected: action.payload.anioObservacionesSelected,
                oficina: action.payload.oficina,
                isLoading: action.payload.isLoading
            } as StateDetalleOficina;
        }

        default:
            return state;
    }
}