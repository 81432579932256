
import { StateNewCarga, INIT_NEW_CARGA, SET_NEW_CARGA } from '../../types/objetivos/cargaTypes';
import { AnioEntity } from '../../types/objetivos/anioTypes';
import { FicheroCargaEntity } from '../../types/objetivos/ficheroCargaTypes';

const stateSaveAnio: AnioEntity = {
    id: 0,
    valorAnio: new Date().getFullYear(),
    fechaCreacion: new Date().toISOString(),
    observaciones: "",
    usuarioCreacion: "",
    messageValidation: undefined,
    displayModal: false
};

const stateSaveFicheroCarga: FicheroCargaEntity = {
    id: 0,
    anioId: 0,
    nombreFichero: "",
    tipoFichero: "ObjetivosOficina",
    contenidoFichero: "",
    fechaCreacion: new Date().toISOString(),
    usuarioCreacion: "",
    messageValidation: undefined,
    displayModal: false
};

const stateCarga: StateNewCarga = {
    anioSelected : stateSaveAnio,
    anioValues: [],
    ficheroObjetivosOficina: stateSaveFicheroCarga,
    displayModal: false,
    sended: false,
    isLoading: false
};


export function cargaObjOfiReducer(state = stateCarga, action: any): StateNewCarga {

    switch (action.type) {

        case INIT_NEW_CARGA: {
            return {
                ...state,
               anioSelected: state.anioSelected,
               anioValues: state.anioValues,
               ficheroObjetivosOficina: state.ficheroObjetivosOficina,
               displayModal: state.displayModal,
               sended: state.sended,
               isLoading: state.isLoading
            } as StateNewCarga;
        }


        case SET_NEW_CARGA: {

            var currentStateInitial = { ...state } as StateNewCarga;
            var currentStateFichero = { ...state.ficheroObjetivosOficina } as FicheroCargaEntity;

            currentStateInitial["anioSelected"] = action.payload.prop === "anioSelected" ? action.payload.datasave : currentStateInitial.anioSelected;
            currentStateInitial["anioValues"] = action.payload.prop === "anioValues" ? action.payload.datasave : currentStateInitial.anioValues;
            currentStateInitial["displayModal"] = action.payload.prop === "displayModal" ? action.payload.datasave : currentStateInitial.displayModal;
            currentStateInitial["sended"] = action.payload.prop === "sended" ? action.payload.datasave : currentStateInitial.sended;
            currentStateInitial["isLoading"] = action.payload.prop === "isLoading" ? action.payload.datasave : currentStateInitial.isLoading;

            currentStateFichero["id"] = action.payload.prop === "idFicheroGuardar" ? action.payload.datasave : currentStateFichero.id;
            currentStateFichero["anioId"] = action.payload.prop === "anioIdGuardar" ? action.payload.datasave : currentStateFichero.anioId;
            currentStateFichero["nombreFichero"] = action.payload.prop === "nombreFicheroGuardar" ? action.payload.datasave : currentStateFichero.nombreFichero;
            currentStateFichero["contenidoFichero"] = action.payload.prop === "contenidoFicheroGuardar" ? action.payload.datasave : currentStateFichero.contenidoFichero;
            currentStateFichero["messageValidation"] = action.payload.prop === "messageValidation" ? action.payload.datasave : currentStateFichero.messageValidation;
            currentStateFichero["displayModal"] = action.payload.prop === "displayModal" ? action.payload.datasave : currentStateFichero.displayModal;


            return {
                ...state,
               anioSelected: currentStateInitial.anioSelected,
               anioValues: currentStateInitial.anioValues,
               ficheroObjetivosOficina: currentStateFichero,
               displayModal: currentStateInitial.displayModal,
               sended: currentStateInitial.sended,
               isLoading: currentStateInitial.isLoading
            } as StateNewCarga;

        }

        default:
            return state;

    }

};