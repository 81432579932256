import { Nav, Navbar, Button } from 'react-bootstrap';
import { UserType } from '../../types/users/userTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import ActionResponseToast from '../common/ActionResponseToast';
import MenuRoutesBase from './MenuRoutesBase';
import MenuRoutesObjetivos from './MenuRoutesObjetivos';
import MenuRoutesRanking from './MenuRoutesRanking';
import { AuthHandler } from '../../services/communications/authHandler';
import NavBarObjetivos from '../layout/NavBarObjetivos';
import NavBarRanking from '../layout/NavBarRanking';

export default function LayoutBase({ user, mode }: { user: UserType, mode: string }) {

    const authHandler = new AuthHandler();

    // Methods

    const handleLogOutOnClick = (event: any) => {

        authHandler.logOut();
    };

    // Render

    return (
        <>
            <Navbar bg="light" expand="lg">
                <div className='container-fluid'>
                    <a href="/" className="navbar-brand">
                        <img src={process.env.PUBLIC_URL + '/soliss/navbar-logo-corto.png'} alt="Logo" height={30} />
                    </a>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {
                            user.isAuthenticated && user.isAuthorized ?
                                <>
                                    {mode === "rank" ? <NavBarRanking user={user} /> : <></>}

                                    {mode === "obj" ? <NavBarObjetivos user={user} /> : <></>}

                                    {mode === "" ? <Nav className="me-auto">
                                        <a className="navbar-brand" href="/">Ranking y Objetivos</a>
                                    </Nav> : <></>}

                                    <Nav>
                                        <Nav.Link><FontAwesomeIcon icon={faUser} /> Hola, <strong>{user.name}</strong></Nav.Link>
                                        <Button title="Salir" variant="light" name="logOutButton" size="sm" onClick={handleLogOutOnClick}>
                                            <FontAwesomeIcon icon={faSignOut} />
                                        </Button>
                                    </Nav>
                                </>
                                : ''
                        }
                    </Navbar.Collapse>
                </div>
            </Navbar>
            {mode === "rank" ? <MenuRoutesRanking user={user} /> : <></>}
            {mode === "obj" ? <MenuRoutesObjetivos user={user} /> : <></>}
            {mode === "" ? <MenuRoutesBase user={user} /> : <></>}
            <ActionResponseToast />
        </>
    );
};