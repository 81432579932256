import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import './App.css';
import './solissnetstyle.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import authentication from 'react-azure-b2c';
import { getB2CConfiguration } from './configurations/environmentVariables';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const b2cConfig = getB2CConfiguration();

authentication.initialize({
    instance: b2cConfig.instance,
    tenant: b2cConfig.tenant,
    signInPolicy: b2cConfig.signInPolicy,
    clientId: b2cConfig.clientId,
    cacheLocation: b2cConfig.cacheLocation,
    scopes: [b2cConfig.scopes],
    redirectUri: b2cConfig.redirectUri,
    postLogoutRedirectUri: window.location.origin
});

authentication.run(() => {
    root.render(

            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>

    );
});