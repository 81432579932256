import { StateGuardaBases } from '../../types/ranking/supervisionTypes';
import { HttpRequestHandler } from '../communications/httpRequestHandler';
import { AnioEntity } from '../../types/ranking/anioTypes';
import { getApiUrl } from '../../configurations/environmentVariables';
import { AuthHandler } from '../communications/authHandler';
import { maxElemFromList } from '../communications/commonFuncs';

const httpRequestHandler = new HttpRequestHandler();
const authHandler = new AuthHandler();

export const getAllAnio = async (): Promise<AnioEntity[]> => {

	var token = await authHandler.getAPIToken();
	var response = await httpRequestHandler.get(`${getApiUrl()}/Ranking/Anio/GetAll`, token);

	if (response !== undefined) {
		if (response.totalCount > 0) {

			return response.items;
		}
	}

	return [];
};

export const createUpdateAnio = async (dataSave: StateGuardaBases, dataAction: string) : Promise<AnioEntity | undefined> => {

	var token = await authHandler.getAPIToken();
	const response = await httpRequestHandler.post(`${getApiUrl()}/Ranking/Anio/${dataAction}`, token, dataSave);

	if (response !== undefined && response.totalCount > 0) {
		return response.items[0];
	}

	return undefined;
};


export const getMaxAnio = async (): Promise<AnioEntity | undefined> => {

	var token = await authHandler.getAPIToken();
	var response = await httpRequestHandler.get(`${getApiUrl()}/Ranking/Anio/GetAll`, token);

	if (response !== undefined) {
		if (response.totalCount > 0) {

			var res: AnioEntity[] = response.items;
			var maxRes = (maxElemFromList(res.map(x => x.valorAnio)));
            
			return res.find(u => u.valorAnio === maxRes);

		} else {
			return undefined;
		}
	} else {
		return undefined;
	}
};

