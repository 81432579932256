import { useEffect } from 'react';
import * as XLSX from 'xlsx';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './../../../store';
import { getAllAnio } from './../../../services/ranking/anioService'
import { Col, Row, Form, Button, Modal } from 'react-bootstrap';
import LoadingSpinner from '../../common/LoadingSpinner';
import { newCargaInitialState } from '../../../reducers/ranking/cargaReducer';
import { createCarga, getPeriodosFicheroCargaEstado } from '../../../services/ranking/cargaService';
import { setActionResponse } from '../../../actions/actionResponseActions';
import { validateFileExtension } from './../../../services/communications/commonFuncs';

export default function NewCargaForm(data: { loadNewCarga: any }) {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state);
    const { isLoading, tipoCargaSelected, tipoCargaValues, anioSelected, anioValues, fechaInicio, fechaFin, periodoSelected, periodoValues, fichero, displayModal } = state.ranking.carga;

    // Methods

    const handleTipoCargaSelectOnChange = async (event: any) => {

        let periodoList: string[] = [];
        let meses = ["12", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"];
        let newTipoCargaselected = event.target.value;
        let tipoCarga = newTipoCargaselected === "Anual" ? 2 : 1;

        getPeriodosFicheroCargaEstado(anioSelected, tipoCarga).then((response) => {

            const mesesOcupados = new Set(response.map(obj => new Date(tipoCarga === 1 ? obj.fechaInicio : obj.fechaFin).getMonth() + 1).map(String).map(mes => mes.padStart(2, '0')));
            const mesesFiltrados = meses.filter(mes => !mesesOcupados.has(mes));

            for (let index = 0; index < mesesFiltrados.length; index++) {

                const periodos: string = `${mesesFiltrados[index] === '12' ? anioSelected - 1 : anioSelected}/${mesesFiltrados[index]}`;
                periodoList.push(periodos);
            }

            dispatch(data.loadNewCarga({
                ...state.ranking.carga,
                tipoCargaSelected: newTipoCargaselected,
                fechaInicio: newTipoCargaselected === "Anual" ? `${anioSelected - 1}-12-01` : "",
                periodoValues: periodoList
            }));

        });
    };

    const handleAnioCargaSelectOnChange = (event: any) => {

        const anioValue = Number.isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value);

        dispatch(data.loadNewCarga({
            ...state.ranking.carga,
            anioSelected: anioValue,
            periodoSelected: "",
            tipoCargaSelected: ""
        }));
    };

    const handlePeriodoCargaSelectOnChange = async (event: any) => {

        let date = event.target.value.split("/");

        dispatch(data.loadNewCarga({
            ...state.ranking.carga,
            fechaInicio: tipoCargaSelected === "Anual" ? state.ranking.carga.fechaInicio : `${date[0]}-${date[1]}-01`,
            fechaFin: tipoCargaSelected === "Anual" ? `${date[0]}-${date[1]}-01` : "",
            periodoSelected: event.target.value
        }));
    };

    const handleFileUploadOnChange = (event: any) => {

        let uploadedFile = event.target.files[0];
        if (uploadedFile) {

            if (validateFileExtension(uploadedFile)) {

                processFile(uploadedFile);

            } else {

                dispatch(data.loadNewCarga({
                    ...state.ranking.carga,
                    fichero: {
                        ficheroNombre: "",
                        ficheroContenido: ""
                    }
                }));
                displayResponseAlert(false, "No se puede cargar este archivo, solo se permiten archivos CSV.", true);
            }
        } else {

            dispatch(data.loadNewCarga({
                ...state.ranking.carga,
                fichero: {
                    ficheroNombre: "",
                    ficheroContenido: ""
                }
            }));
        }
    };

    const processFile = (uploadedFile: any) => {

        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(uploadedFile);
        fileReader.onload = (readerData: any) => {

            let workbook = XLSX.read(readerData.target.result, { type: 'buffer' });
            let worksheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[worksheetName];
            let csvData = XLSX.utils.sheet_to_json(worksheet, { raw: false });

            try {

                csvData.forEach((element: any, index: number) => {

                    element.OFICINA = element.OFICINA.toString().padStart(2, '0');

                    if (Number(element.POSICION.toString()) <= 0) {

                        throw new Error(`Error en la fila número: ${index + 2}`);
                    }
                });

                let processedCsvData = JSON.stringify(csvData);

                dispatch(data.loadNewCarga({
                    ...state.ranking.carga,
                    fichero: {
                        ficheroNombre: uploadedFile.name,
                        ficheroContenido: processedCsvData,
                    }
                }));

            } catch (error: any) {

                dispatch(data.loadNewCarga({
                    ...state.ranking.carga,
                    fichero: {
                        ficheroNombre: "",
                        ficheroContenido: ""
                    }
                }));

                let errorMessage = error.message.includes("Error en la fila número") ? `No se puede cargar este archivo. ${error.message}.` : `No se puede cargar este archivo, no esta formado correctamente`
                displayResponseAlert(false, errorMessage, true);
            }
        }
    }

    const handleCargarDatosOnClick = async (event: any) => {

        dispatch(data.loadNewCarga({
            ...state.ranking.carga,
            displayModal: false,
            isLoading: true
        }));

        let request: any = {
            anio: anioSelected,
            fechaInicio: new Date(fechaInicio),
            ficheroNombre: fichero.ficheroNombre,
            ficheroContenido: fichero.ficheroContenido
        }

        if (fechaFin !== "") {

            request["fechaFin"] = new Date(fechaFin);
        }

        let response = await createCarga(request);

        if (response === true) {

            dispatch(data.loadNewCarga({ newCargaInitialState, sended: true }));
            displayResponseAlert(true, "Carga guardada correctamente", true);

        } else {

            dispatch(data.loadNewCarga({
                ...state.ranking.carga,
                displayModal: false,
                isLoading: false,
                sended: true
            }));

            displayResponseAlert(false, "Ha ocurrido un error al intentar guardar la carga", true);
        }
    };

    const displayResponseAlert = (isOk: boolean, message: string, isVisible: boolean) => {

        dispatch(setActionResponse("IsOk", isOk));
        dispatch(setActionResponse("ResponseMessage", message));
        dispatch(setActionResponse("IsVisible", isVisible));
    }

    const fetchAnios = async () => {

        let response = await getAllAnio();

        let aniosList: number[] = [];

        response.forEach(element => {
            aniosList.push(element.valorAnio)
        });

        aniosList.sort((a, b) => (b - a));

        dispatch(data.loadNewCarga({
            ...state.ranking.carga,
            anioValues: aniosList
        }));
    }

    const handleModalHide = () => {

        dispatch(data.loadNewCarga({
            ...state.ranking.carga,
            displayModal: false
        }));
    };

    // useEffect

    useEffect(() => {
        if (displayModal) {
            fetchAnios();
        }

    }, [displayModal]);

    return (
        <>
            {
                isLoading ? <LoadingSpinner /> : ''
            }
            <Modal className="justify-content-md-center" show={displayModal} onHide={() => handleModalHide()} centered>
                <Modal.Header>
                    <Modal.Title>Nueva Carga</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Año</Form.Label>
                                    <Form.Select id="anioCargaSelect" onChange={handleAnioCargaSelectOnChange} value={anioSelected}>
                                        <option key="anioCarga_null"></option>
                                        {anioValues !== undefined && anioValues.map((elem, i) => {
                                            return <option key={"anioCarga_" + elem + i}>{elem}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Tipo</Form.Label>
                                    <Form.Select id="tipoCargaSelect" disabled={anioSelected === 0} onChange={handleTipoCargaSelectOnChange} value={tipoCargaSelected}>
                                        <option key="tipoCarga_null"></option>
                                        {
                                            tipoCargaValues.map((elem, i) => {
                                                return <option key={"tipoCarga_" + elem + i} value={elem}>{elem}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Período</Form.Label>
                                    <Form.Select id="periodoCargaSelect" disabled={tipoCargaSelected === ""} onChange={handlePeriodoCargaSelectOnChange} value={periodoSelected}>
                                        <option key="periodoCarga_null"></option>
                                        {periodoValues !== undefined && periodoValues.map((elem, i) => {
                                            return <option key={"periodoCarga_" + elem + i}>{elem}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Datos</Form.Label>
                                    <Form.Control type="file" accept=".csv" id="ficheroCargaSelect" disabled={periodoSelected === ""} onChange={handleFileUploadOnChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-soliss' onClick={handleModalHide}>Cancelar</Button>
                    <Button className='btn btn-soliss' disabled={fichero?.ficheroNombre === ""} onClick={handleCargarDatosOnClick}>Cargar Datos</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};