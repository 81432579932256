import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { RootState } from '../../../store';
import { setAnioGuardado, setEstructuraGuardado } from '../../../actions/objetivos/anioGuardadoActions';
import { Modal, Button } from 'react-bootstrap';
import { createUpdateAnio } from '../../../services/objetivos/anioService';
import { setActionResponse } from '../../../actions/actionResponseActions';

export default function ObservacionesFormTab() {

    const { typeaccAnio } = useParams();

    const [editorLoaded, setEditorLoaded] = useState<boolean>(false);
    const editor: any = ClassicEditor;

    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const { anio, loading, stateAnioSelect } = state.objetivos.anioguardado;

    useEffect(() => {
        setEditorLoaded(true);
    }, []);

    const displayResponseAlert = (isOk: boolean, message: string, isVisible: boolean) => {
        dispatch(setActionResponse("IsOk", isOk));
        dispatch(setActionResponse("ResponseMessage", message));
        dispatch(setActionResponse("IsVisible", isVisible));
    }

    const setGuardadoObservaciones = () => {
        if (anio.observaciones !== undefined && anio.observaciones.length > 0) {
            dispatch(setAnioGuardado("messageValidation", undefined));
            dispatch(setAnioGuardado("displayModal", true));
        } else {
            dispatch(setAnioGuardado("messageValidation", "Observaciones requeridas"));
        }
    };

    const handleModalHide = () => {
        dispatch(setAnioGuardado("displayModal", false));
    };

    const handleGuardarAnioOnClick = async () => {
        try {
            handleModalHide();
            dispatch(setAnioGuardado("loading", true));
            let response = await createUpdateAnio(anio);
    
                let baseMess = anio.id > 0 ? "Modificar" : "Guardar";
                if (response !== undefined) {
                    dispatch(setAnioGuardado("loading", false));
                    dispatch(setEstructuraGuardado("anioIdGuardar", response.id));
                    displayResponseAlert(true, baseMess + " año " + anio.valorAnio + " realizado con éxito.", true);
                } else {
                    dispatch(setAnioGuardado("loading", false));
                    displayResponseAlert(false, baseMess + " año " + anio.valorAnio + " se ha producido un error.", true);
                } 
        } catch (error: any) {
            dispatch(setAnioGuardado("loading", false));
            let errorMessage = error.message;
            displayResponseAlert(false, errorMessage, true);
        }
    };

    return (
        <>
            <Modal show={anio.displayModal} onHide={() => handleModalHide} centered>
                <Modal.Header>
                    <Modal.Title>Guardar año</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>¿Está seguro que quiere guardar el año {anio.valorAnio}?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-soliss' onClick={handleGuardarAnioOnClick}>Aceptar</Button>
                    <Button className='btn btn-soliss' onClick={handleModalHide}>Cancelar</Button>
                </Modal.Footer>
            </Modal>

            <div className="form-group row">
                <div className="col-12">
                    {editorLoaded ? <CKEditor
                        editor={editor}
                        config={{
                            toolbar: { removeItems: ['uploadImage'] }
                        }}
                        onReady={(editor: any) => {
                        }}
                        data={anio.observaciones}
                        onChange={(event: any, editor: any) => {
                            const data = editor.getData();
                            dispatch(setAnioGuardado("observaciones", data));
                        }}
                    /> : <p>Cargando editor...</p>}
                </div>
                <div className="col-12">
                    {anio.messageValidation !== undefined ? <p className='text-danger'>{anio.messageValidation}</p> : <></>}
                </div>
            </div><br />

            <div className="form-group row">
                <div className="col-3">
                    <button className='btn btn-soliss' onClick={() => setGuardadoObservaciones()} disabled={stateAnioSelect.length === 0 && typeaccAnio !== "Nuevo"}>
                        {typeaccAnio == "Nuevo" ? "Guardar" : "Modificar"} Observaciones
                    </button>
                </div>
            </div>
        </>
    );
};